import Vue from 'vue'
import VueRouter from 'vue-router'
import firstPage from "../components/firstPage/firstPage.vue"
import Login from "../components/BinexLog.vue"
import BinexSub from "../components/BinexSub.vue"
import MainProfile from "../components/Profile/MainProfile.vue"
import About from "../components/About.vue"
import Fees from "../components/Fees.vue"
import Contact from "../components/Contact.vue"
import Faq from "../components/FAQ.vue"
import Authentication from "../components/Profile/Authentication.vue"
import AuthenticationMob from "../components/Profile/AuthenticationMob.vue"
import Terms from "../components/Terms.vue"
import BankInfo from "../components/Profile/BankInfo.vue"
import AuthenticationImage from "../components/Profile/AuthenticationImage.vue"
import SelfieImage from "../components/Profile/SelfieImage.vue"
import Shaba from "../components/Profile/Shaba.vue"
import Wallet from "../components/Profile/Wallet.vue"
import History from "../components/Profile/History.vue"
import Support from "../components/Profile/Support.vue"
import InviteFriends from "../components/Profile/InviteFriends.vue"
import Security from "../components/Profile/Security.vue"
import Account from "../components/Profile/Account.vue"
import Chart from "../components/Profile/Chart.vue"
import Notification from "../components/Profile/Notification.vue"
// import Orders from "../components/Profile/Orders.vue"
// import Portfolio from "../components/Profile/Portfolio.vue"
import DepositRial from "../components/Profile/DepositRial.vue"
import DepositBit from "../components/Profile/DepositBit.vue"
import WithdrawalBit from "../components/Profile/WithdrawalBit.vue"
import WithdrawalRial from "../components/Profile/WithdrawalRial.vue"
// import Trade from "../components/Profile/Trade.vue"
Vue.use(VueRouter)
// const that = Vue.prototype
const routes = [
  // {
  //   path: '/',
  //   name: 'nav',
  //   component: nav
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
    {
        path: '/',
        component: firstPage,
        name:'Home'
    },
    {
        path: '/about',
        component: About,
        name:'about'
    },
    {
        path: '/fees',
        component: Fees,
        name:'fees'
    },
    {
        path: '/contact',
        component: Contact,
        name:'contact'
    },
    {
        path: '/faq',
        component: Faq,
        name:'faq'
    },
    {
        path: '/login',
        component: Login,
        name:'login'
    },
    {
        path: '/login-email',
        component: Login,
        name:'login-email'
    },
    {
        path: '/subscribe',
        component: BinexSub,
        name:'subscribe'
     },
    {
        path: '/forget-email',
        component: Login ,
        name:'forget-email'
    },
    {
        path: '/forget-confirm',
        component: Login,
        name:'forget-confirm'
    },
    {
        path: '/change-password', 
        component: Login ,
        name:'change-password'
    },
    {
        path: '/profile',
        component: MainProfile,
        name:'profile'
    },
    {
        path: '/authentication',
        component: Authentication,
        name:'authentication'
    },
    {
        path: '/mobile-confirm',
        component: AuthenticationMob,
        name:'mobile-confirm'
    },
    {
        path: '/terms',
        component: Terms,
        name:'terms'
    },
    {
        path: '/bank-info',
        component: BankInfo,
        name:'bank-info'
    },
    {
        path: '/shaba',
        component: Shaba,
        name:'shaba'
    },
    {
        path: '/upload-image',
        component: AuthenticationImage,
        name:'upload-image'
    },
    {
        path: '/selfie',
        component: SelfieImage,
        name:'selfie'
    },
    {
        path: '/wallet',
        component: Wallet,
        name:'wallet'
    },
    {
        path: '/chart',
        component: Chart,
        name:'chart'
    },
    {
        path: '/history',
        component: History,
        name:'history'
    },
    {
        path: '/support',
        component: Support,
        name:'support'
    },
    {
        path: '/invite-friends',
        component: InviteFriends,
        name:'invite-friends'
    },
    {
        path: '/security',
        component: Security,
        name:'security'
    },
    {
        path: '/account',
        component: Account,
        name:'account'
    },
    {
        path: '/notification',
        component: Notification,
        name:'notification'
    },
    {
        path: '/orders',
        component: MainProfile,
        name:'orders'
    },
    {
        path: '/portfolio',
        component: MainProfile,
        name:'portfolio'
    },
    {
        path: '/deposit/rial',
        component: DepositRial,
        name:'deposit/rial'
    },
    {
        path: '/deposit/bit',
        component: DepositBit,
        name:'deposit/bit'
    },
    {
        path: '/withdrawal/bit',
        component: WithdrawalBit,
        name:'withdrawal/bit'
    },
    {
        path: '/withdrawal/Rial',
        component: WithdrawalRial,
        name:'withdrawal/Rial'
    },
    {
        path: '/trade',
        component: MainProfile,
        name:'trade'

    },
    {
        path: '/history/trade',
        component: history,
        name:'history/trade'
    },
    {
        path: '/history/rial',
        component: history,
        name:'history/rial'
    },
    {
        path: '/history/currency',
        component: history,
        name:'history/currency'
    },
    {
        path: '/history/login',
        component: history,
        name:'history/login'
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
// const forLogin = [
//     'History',
//     'profile',
//     // 'Wallet',
//     // 'Setting',
//     // 'Messages',
//     // 'Profile',
//     'Orders',
//     // 'Trade',
// ]
// const unAuthorUser = [
//     // 'History',
//     // 'Dashboard',
//     // 'Wallet',
//     // 'Orders',
// ]
// const otp = [
//     // 'TwoStep',
//     // 'ResetPass',
// ]
// const auth = [
//     // 'Login',
//     // 'Register',
//     // 'ForgetPass',
// ]

// router.beforeEach(async (to, from, next) => {

//     that.state.loading = true
//     if (that.state.token) {

//         if (!that.state.gotten) {
//             await that.$getuserInfo()
//             await that.$getFees()
//         }

//         that.state.loading = true

//         if (from.name === 'Trade' || from.name === 'Blog') {
//             await window.open(to.fullPath, '_self')
//         } else if (otp.includes(to.name) || auth.includes(to.name)) {
//             next({name: 'Home'})
//         } else if (to.name === 'Trade') {
//             try {
//                 that.state.loading = true
//                 await that.$axios(`/markets/${to.params.tradeFrom}_${to.params.tradeTo}`)
//                 next()
//             } catch (e) {
//                 if (e.response.data.message === "MARKET_IS_NOT_ACTIVE") {
//                     if (otp.includes(from.name)) {
//                         next({name: 'Home'})
//                     } else {
//                         await that.$error('بازار غیرفعال است')
//                         next(from)
//                     }
//                 }
//             }
//         } else {
//             if (that.state.userInfo.authorized) {
//                 next()
//             } else {
//                 if (otp.includes(from.name) && to.name !== 'userAccount') {
//                     next({name : 'userAccount'})
//                 } else if (unAuthorUser.includes(to.name)) {
//                     that.state.loading = false
//                     const res = await that.$error('عدم دسترسی', 'برای دسترسی به این بخش نیاز است ابتدا حساب شما تکمیل و تایید شود', 'error', 'حساب کاربری')
//                     if (res.isConfirmed) {
//                         next('/useraccount')
//                     } else {
//                         next(from)
//                     }
//                 } else next()
//             }
//         }
//     } else {
//         if ((otp.includes(to.name) && !Vue.$cookies.isKey('username')) || forLogin.includes(to.name))
//             next({name: 'Login'})
//         else {
//             next()
//         }
//     }
//     that.state.loading = false
// })


// router.afterEach((to, from) => {
//     if (from.name === 'Trade') {
//         location.reload()
//     }
// })

export default router
