<template>
<div >
                <div class="filter-history-row">
                    <div class="history-sell-buy">
                        <button class="history-buys " v-on:click="beActiveBuys" :class="{'deActive-buys':deActiveBuys , 'active-buys-history':activeBuys}" >
                            خرید
                        </button>
                        <button class="history-sells " v-on:click="beActiveSells" :class="{'deActive-sells':activeBuys , 'active-sells-history':deActiveBuys}"  >
                            فروش
                        </button>
                    </div>
                    <button class="history-markets" v-on:click.stop="dropdown=!dropdown" >
                          بازار ها  <img src="@/assets/selector-black.png"/>
                    </button>
                </div>
                <transition name="fade">
                    
                <div v-if="dropdown"  class="markets">
                    
                    <!-- v-bind:class="{showMarkets: !hideMarkets}" -->
                    <div class="title-markets">
                        <div :class="{'active-title' : tomanTitle}" @click="activeToman">
                            تومان
                        </div>
                        <div :class="{'active-title' : teterTitle}" @click="activeTether">
                            تتر
                        </div>
                    </div>
                    <perfect-scrollbar>
                    <div v-for="(coin , index) in coins" :key="index" class="markets-container">
                        <img :src=coin.image alt="" class="markets-image">
                        <p>
                            {{coin.name}}
                            -
                            <span v-if="tomanTitle">تومان</span>
                            <span v-if="teterTitle">تتر</span>
                        </p>
                    </div>
                    </perfect-scrollbar>
                </div>
                
                </transition>
                <div class="history-tables-container">
                    <table :class="{'deActive-buys-table':deActiveBuys , 'historyTrades-table':activeBuys}">
                            <tr >
                                <td>بازار</td>
                                <td>مقدار</td>
                                <td class="price-td">قیمت واحد </td>
                                <td class="totalPrice-td">قیمت کل</td>
                                <td class="commition-td">کارمزد</td>
                                <td>خروجی شما</td>
                                <td class="date-td">تاریخ معامله</td>
                                <td>وضعیت</td>
                            </tr>
                            <tr v-for="buy , index in buys" :key="index">
                                <td>{{buy.market}}</td>
                                <td>{{buy.amount.toLocaleString()}}</td>
                                <td class="td-buy price-td">{{buy.price.toLocaleString()}}<span>تومان</span></td>
                                <td class="totalPrice-td">{{buy.totalPrice.toLocaleString()}}<span>تومان</span></td>
                                <td class="commition-td">{{buy.commition}}</td>
                                <td>{{buy.outPut.toLocaleString()}}</td>
                                <td class="date-td">{{buy.date}}</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="" v-if="buy.statuse===10">
                                </td>
                            </tr>
                    </table>
                    <table :class="{'deActive-buys-table':activeBuys , 'historyTrades-table':deActiveBuys}" >
                            <tr>
                                <td>بازار</td>
                                <td>مقدار</td>
                                <td class="price-td">قیمت واحد </td>
                                <td class="totalPrice-td">قیمت کل</td>
                                <td class="commition-td">کارمزد</td>
                                <td>دریافتی شما</td>
                                <td class="date-td">تاریخ معامله</td>
                                <td>وضعیت</td>
                            </tr>
                            <tr v-for="sell , index in sells" :key="index">
                                <td>{{sell.market}}</td>
                                <td>{{sell.amount.toLocaleString()}}</td>
                                <td class="td-sell price-td">{{sell.price.toLocaleString()}}<span>تومان</span></td>
                                <td class="totalPrice-td">{{sell.totalPrice.toLocaleString()}}<span>تومان</span></td>
                                <td class="commition-td">{{sell.commition}}</td>
                                <td>{{sell.input.toLocaleString()}}</td>
                                <td class="date-td">{{sell.date}}</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="" v-if="sell.statuse===10">
                                </td>
                            </tr>
                            
                        <!-- </tbody> -->
                    </table>
                </div>
               
</div>
</template>
<script>
// import Authentication from './Authentication.vue'
// import ProfileNavbar from "./ProfileNavbar.vue"
// import Sidebar from './Sidebar.vue'
import Market from '../../library/Market.js'
export default {
  components: {},
  name: 'MainProfile',
  data:function() {
      return {
          coins:Market,
          deActiveBuys:false,
          activeBuys:true,
          hideMarkets:false,
          activeTradeTabel:true,
          dropdown:false,
          tomanTitle:true,
          teterTitle:false,
          sells :[
              {
                  market:'اتریوم',
                  amount:7546777654,
                  price:2351561,
                  totalPrice:2000004456,
                  commition:0.26,
                  input:254652,
                  date:'xxxx/xx/xx',
                  statuse:10
              },
              {
                  market:'اتریوم',
                  amount:7546777654,
                  price:2351561,
                  totalPrice:2000004456,
                  commition:0.26,
                  input:254652,
                  date:'xxxx/xx/xx',
                  statuse:10
              },
              {
                  market:'اتریوم',
                  amount:7546777654,
                  price:2351561,
                  totalPrice:2000004456,
                  commition:0.26,
                  input:254652,
                  date:'xxxx/xx/xx',
                  statuse:10
              },
              {
                  market:'اتریوم',
                  amount:7546777654,
                  price:2351561,
                  totalPrice:2000004456,
                  commition:0.26,
                  input:254652,
                  date:'xxxx/xx/xx',
                  statuse:10
              },
              {
                  market:'اتریوم',
                  amount:7546777654,
                  price:2351561,
                  totalPrice:2000004456,
                  commition:0.26,
                  input:254652,
                  date:'xxxx/xx/xx',
                  statuse:10
              }
          ],
          buys : [
              {
                  market:'اتریوم',
                  amount:7546777654,
                  price:2351561,
                  totalPrice:2000004456,
                  commition:0.26,
                  outPut:254652,
                  date:'xxxx/xx/xx',
                  statuse:10
              },
              {
                  market:'اتریوم',
                  amount:7546777654,
                  price:2351561,
                  totalPrice:2000004456,
                  commition:0.26,
                  outPut:254652,
                  date:'xxxx/xx/xx',
                  statuse:10
              },
              {
                  market:'اتریوم',
                  amount:7546777654,
                  price:2351561,
                  totalPrice:2000004456,
                  commition:0.26,
                  outPut:254652,
                  date:'xxxx/xx/xx',
                  statuse:10
              },
              {
                  market:'اتریوم',
                  amount:7546777654,
                  price:2351561,
                  totalPrice:2000004456,
                  commition:0.26,
                  outPut:254652,
                  date:'xxxx/xx/xx',
                  statuse:10
              }

          ], 
        //   sells : [
        //       {
        //           smarket:'اتریوم',
        //           samount:'ET 7546777654',
        //           sprice:2351561,
        //           stotalPrice:2000004456,
        //           scommition:0.26,
        //           sinput:'ET 254652',
        //           sdate:'xxxx/xx/xx',
        //           statuse:10
        //       }

        //   ]
      }
  },
  methods : {
      showMarkets : function() {
          this.hideMarkets=!this.hideMarkets
      },
      beActiveBuys() {
          if (this.deActiveBuys) {
              this.deActiveBuys=false
              this.activeBuys=true
          }
          else 
              return true
      },
      beActiveSells () {
          if (this.activeBuys) {
              this.deActiveBuys=true
              this.activeBuys=false
          }
          else 
              return true
      },
      onclickOutSide() {
          this.hideMarkets=false
      },
      activeToman() {
          if(!this.tomanTitle) {
              this.tomanTitle=true
              this.teterTitle=false
          }
          else return true
      },
      activeTether() {
          if(!this.teterTitle) {
              this.teterTitle=true
              this.tomanTitle=false
          }
          else return true
      }
  },
  created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest( '.markets , .history-markets')) {
              this.dropdown=false
            }
            // else if(event.target.closest('.modal , .add-btn')) {
            // }
            else return true
          } )
  },
  mounted() {
      console.log('coins',this.coins)
  }
}
</script>

<style >
.no-show {
    display: none;
}
.markets {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 5px;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    width: 214px;
    height: 200px;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
.active-title {
    border-bottom: 3px solid black;
}
.markets::-webkit-scrollbar {
    display: none;
}
.markets  .title-markets  , .markets-container{
    display: flex;
    align-items: center;
    column-gap: 10px;
    padding: 5px 10px;
    border-bottom: 1px solid gainsboro;
    font-weight: bold;
font-size: 14px;
}
.showMarkets {
    display: none;
}
.title-markets {
    justify-content: space-around;
    padding-bottom: 0 !important;
}
.title-markets div {
    height: 100%;
    width: 50%;
    text-align: center;
}
.markets-image {
    width: 19px;
    height: 19px;
}
/* .markets > div > p {
    margin: 0;
} */
.markets-container {
    cursor: pointer;
}
.markets-container p {
    margin: 5px;
}
.historyTrades-table {
    width: 100%;
    border-collapse: collapse;
    font-weight: bold;
font-size: 14px;
/* min-width: 900px; */
}
.historyTrades-table > tr {
    border-bottom: 1px solid #a5a5a5;
    height: 50px;
}
.describe-row-list {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.history-items {
    display: flex;
    flex-direction: row;
   
}
/* .active-buys ,.active-sells {
    background-color: #058C2B;
    color: white;
} */
 
.history-item {
    /* padding: 0 15px; */
    background-color: rgba(243, 243, 243 , 0.1);
    /* background: rgba(15, 15, 15, 0.1); */
     /* height: 35px; */
     width: 110px;
     display: flex;
    justify-content: center;
    align-items: center;
}
.active {
    background: white;
    /* box-shadow: 0px 0px 5px rgb(0 0 0 / 35%); */
}
.describe-history-items {
    display: flex;
    flex-grow: 1;
    height: 600px;
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); */
    border-radius: 2px;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
    background: white;
}
.history-items-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    row-gap: 30px;
}
.history-markets {
    width: 120px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); */
    border-radius: 25px;
    background: transparent;
}
.filter-history-row {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    margin-top: 20px;
}
.history-sell-buy {
    width: 200px;
    height: 39px;
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); */
    border-radius: 25px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.history-buys {
    width: 100px;
    height: 35px;
    /* color: white; */
    border-radius: 25px;
    /* background-color: #E0342B; */
    /* background: transparent; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.history-sells {
    width: 100px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: transparent; */
}
.active-buys-history {
    background: #058C2B;
    color: white;
}
.active-sells-history {
    background: red;
    border-radius: 25px;
    color: white;
}
.deActive-buys , .deActive-sells {
    background-color: transparent;
    border-radius: 25px;
}
.deActive-buys-table {
    display: none;
}
.describe-row-header {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
}
.td-sell {
    color: red;
}
.td-buy {
    color: #058C2B;
}
    .wallet-header {
        font-weight: bold;
        font-size: 20px;
    }
    .wallet-title-p , .amount-p {
        font-weight: bold;
        font-size: 16px;
    }
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;

    }
    .history-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        align-self: baseline;
        overflow: scroll;
        -ms-overflow-style: none;
  scrollbar-width: none;
    }
    .history-container::-webkit-scrollbar {
  display: none;
}
    .wallet-row {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); */
        border-radius: 2px;
        flex-wrap: wrap;
    }
    .wallet-title {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-grow: 1;
    }
    .wallet-amount {
        flex-grow: 1;
        justify-content: center;
        display: flex;
        min-width: 100px;
    }
    .wallet-options {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        gap: 5px;
    }
    .wallet-blue-button , .wallet-red-button , .wallet-green-button {
        width: 95px;
        height: 30px;
        color: white;
        border: 0;
        border-radius: 2px;
    }
    .wallet-blue-button {
        background-color: #00ACC1;
    }
    .wallet-red-button {
        background-color: #E0342B;
    }
    .wallet-green-button {
        background-color: #058C2B;
    }
    .history-tables-container {
        overflow: scroll;
        -ms-overflow-style: none; 
        scrollbar-width: none;
    }
    .history-tables-container::-webkit-scrollbar {
        display: none;
    }
    /* .sidebar {
            display: none;
        }
    @media only screen and (max-width: 900px) {
        .sidebar {
            display: none;
        }
    } */
</style>
<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: height 0.7s ease-in;
  background: white;
  height: 200px;
}
  .fade-enter, .fade-leave-to {
  height: 0;
}
table {
    text-align: center;
}
@media only screen and (max-width: 900px) {
    .price-td , .totalPrice-td , .commition-td , .date-td {
        display: none;
    }
}
@media only screen and (max-width: 600px) {
    .historyTrades-table {
        font-size: 12px;
    }
}
</style>
