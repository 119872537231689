<template>
    <div>
        <Navbar/>
        <!-- <MobileNavbar/> -->
    <div id="container">
        <p id="fee-title">کارمزد های باینکس</p>
        <div id="deal-fees">
            <div id="deal-fees-right">
                <div id="deal-fees-text">
                    <p id="fee-subtitle">کارمزد های باینکس</p>
                    <p id="fee-text"><img src="@/assets/dote.png"/> کارمزد معاملات در سامانه ما بصورت  درصدی از مبلغ کل معامله است و محاسبه‌ی آن بر اساس ملاحظات زیر صورت می‌گیرد. لازم به توضیح است که کسر کارمزد از معاملات باعث جلوگیری از ثبت معاملات صوری و مکرر خواهد شد و شرایط مطلوب‌تری را در بازار برای تمامی کاربران ایجاد می‌کن</p>
                    <p id="fee-text"><img src="@/assets/dote.png"/> کارمزد خریدار ارزدیجیتال به صورت  ارزدیجیتال کسر و کارمزد فروشنده ارزدیجیتال از مبلغ دریافتی به تومان کسر می شود. */</p>
                    <p id="fee-text"><img src="@/assets/dote.png"/> کارمزد از هر دو سمت معامله کسر میشود</p>
                    <p id="fee-text"><img src="@/assets/dote.png"/> در هنگام ثبت معاملات، میزان کارمزد به شما نمایش داده می شود</p>
                    <p id="fee-text">لازم به ذکر است کارمزد معاملات جفت ارز  ب) ه طور مثال بیت کوین - تتر( به صورت ثابت ۰.۲% در نظر گرفته می شو</p>
                </div>
            </div>
            <div id="deal-fees-left">
                <div id="deals-table">
                    <div id="row1">
                        <p id="row-title">حجم معامله در سه ماه اخیر</p>
                        <p id="row-title">میزان کارمزد</p>
                    </div>
                    <div id="row">
                        <p id="row-info">کمتر از 20 میلیون تومان</p>
                        <p id="row-info">0.4%</p>
                    </div>
                    <div id="row">
                        <p id="row-info">20 - 50 ملیون تومان </p>
                        <p id="row-info">0.35%</p>
                    </div>
                    <div id="row">
                        <p id="row-info">50 - 100 میلیون تومان</p>
                        <p id="row-info">0.3%</p>
                    </div>
                    <div id="row">
                        <p id="row-info">100 - 200 میلیون تومان </p>
                        <p id="row-info">0.25%</p>
                    </div>
                    <div id="row">
                        <p id="row-info">بیشتر از 200 میلیون تومان </p>
                        <p id="row-info">0.2%</p>
                    </div>
                </div>
            </div>
        </div>
        <div id="settle-fees">
            <div id="deal-fees-right">
                <div id="deal-fees-text">
                    <p id="fee-subtitle">کارمزد واریز و برداشت ارز دیجیتال</p>
                    <p id="fee-text"> <img src="@/assets/dote.png"/> کارمزد واریز تمامی دارایی‌های دیجیتال )از  جمله بیت‌کوین، اتریوم، لایت‌کوین، ریپل، بیت‌کوین کش، بایننس کوین، تتر و ... ( صفر است</p>
                    <p id="fee-text"> <img src="@/assets/dote.png"/> کارمزد برداشت دارایی‌های دیجیتال به نوع ارز بستگی دارد. این هزینه در واقع به دلیل کارمزدهای تراکنش شبکه کسر می‌شود و ما در آن نقشی نداریم. */</p>
                </div>
            </div>
            <div id="deal-fees-left">
                <div id="deals-table">
                    <div id="row1">
                        <p id="row-title">حجم معامله در سه ماه اخیر</p>
                        <p id="row-title">میزان کارمزد</p>
                    </div>
                    <div id="row">
                        <p id="row-info">کمتر از 20 میلیون تومان</p>
                        <p id="row-info">0.4%</p>
                    </div>
                    <div id="row">
                        <p id="row-info">20 - 50 ملیون تومان </p>
                        <p id="row-info">0.35%</p>
                    </div>
                    <div id="row">
                        <p id="row-info">50 - 100 میلیون تومان</p>
                        <p id="row-info">0.3%</p>
                    </div>
                    <div id="row">
                        <p id="row-info">100 - 200 میلیون تومان </p>
                        <p id="row-info">0.25%</p>
                    </div>
                    <div id="row">
                        <p id="row-info">بیشتر از 200 میلیون تومان </p>
                        <p id="row-info">0.2%</p>
                    </div>
                </div>
            </div>
        </div>
        <div id="rial-fees">
            <div id="fee-text-div">
            <p id="fee-subtitle">کارمزد واریز و برداشت ریال</p>
            <p id="fee-text"><img src="@/assets/dote.png"/> برداشت‌های ریالی برای تمامی حساب‌ها، در  قالب انتقال پایا و یک بار در روز خواهد بود. بدیهی است مدت زمان انجام تسویه ریالی، بسته به زمان درخواست برداشت، در روزهای غیر تعطیل می‌تواند حداکثر تا ۲۴ ساعت به طول انجام</p>
            </div>
        </div>
    </div>
    <Footer/>
    </div>
</template>
<script>
import Footer from './firstPage/Footer.vue'
import Navbar from '../components/Navbar.vue'
// import MobileNavbar from './MobileNavbar.vue'
export default {
  components: {Footer, Navbar  },
  nam: 'App',
}
</script>

<style scoped>
    #container {
        direction: rtl;
        width: 95%;
        margin: auto;
    }
    #fee-title {
        font-weight: bold;
        font-size: 20px;
    }
    #deal-fees , #settle-fees , #rial-fees {
        background: #FFFFFF;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        width: 100%;
        margin-bottom: 10px;
    }
    #deal-fees {
        height: 370px;
        display: flex;
    }
    #deal-fees-right , #deal-fees-left {
        display: inline-block;
        box-sizing: border-box;
        height: 100%;
        padding: 20px;
    }
    #deal-fees-right {
        width: 70%;
    }
    #deal-fees-left {
        width: 30%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }
    #deals-table {
        width: 370px;
        height: 268px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 25px;
        display: grid;
    }
    #settle-fees {
        height: 325px;
        display: flex;
    }
    #row1 , #row {
        display: flex;
        justify-content: space-around;
        
    }
    #row1 {
        border-bottom: 1px solid black;
    }
    #rial-fees {
        height: 163px;
    }
    #fee-subtitle {
        font-weight: bold;
        font-size: 18px;
    }
    #row-info {
        font-weight: 500;
        font-size: 14px;
        margin: 0;
        display: flex;
        align-items: center;
    }
    #fee-text {
        font-weight: 500;
        font-size: 14px;
    }
    #fee-text-div {
        padding: 20px;
    }
    #row-title {
        font-weight: bold;
        font-size: 14px;
        
    }
    @media only screen and (max-width: 1000px) {
        #deal-fees , #settle-fees{
            display: grid;
        }
        #deal-fees-right , #deal-fees-left , #deals-table {
            width: 100%;
        }
        #rial-fees , #settle-fees , #deal-fees , #deal-fees-right , #deal-fees-left {
            height: auto;
        }
        #fee-title {
            text-align: center;
        }
    }
</style>
