<template>
    <div class="loginform">
        <p class="form-head">فراموشی رمز عبور</p>
        <hr>
        <form  action=""  id="loginform">
        <!-- <label class="input">
        <input  v-bind:class="{ 'input__field': activeEmain , 'input__error': errorEmail}" v-on:input="toActive" type="text" v-model="email"/>
        <span class="input__label">  آدرس ایمیل</span>
        <p id="error" v-if="emailError.length" >{{emailError[0]}}</p>
        </label> -->
        <label class="input">
        <input  v-bind:class="{ 'input__field': activeEmain , 'input__error': errorEmail}" v-on:input="toActive" type="text" v-model="code"/>
        <span class="input__label">کد بازیابی</span>
        <p id="error" v-if="codeError.length" >{{codeError[0]}}</p>
        </label>
        
        <!-- <router-link to="/profile"><Login id="login-form" v-on:click="checkForm"/></router-link> -->
        <button class="login" type="submit" v-on:click="checkForm" >تایید کد</button>
        </form>
    </div>
    
</template>

<script>
import router from "../router/index.js"

export default {
  components: {  },
  name: 'LoginForm',
  data:function () {
      return{
                email:null,
                emailError:[],
                code:null,
                codeError:[],
                    activeEmain: true,
                    errorEmail: false,
                    activePass:true,
                    errorPass:false,
                    type: 'password',
                    btnText: 'Show Password'
      }
  },
   methods:{
    checkForm (e) {
      if(this.email && this.code) {
          router.push("/change-password")
      }
       
      if(!this.email){
          this.emailError.push("ایمیل  وارد نشده است");
          this.activeEmain=false;
          this.errorEmail=true;
      } 
      if(!this.code){
          this.codeError.push("کد  وارد نشده است");
          this.errorEmain=false;
          this.errorEmail=true;
      } 
      e.preventDefault();
    // console.log("bb")
    },
    toActive() {
        this.activeEmain=true;
          this.errorEmail=false;
          this.emailError=[];
    },
  }
}
</script>

<style scoped>
p {
    margin: 0;
}
    .loginform {
        display: grid;
        row-gap: 10px;
        margin-top: 20px;
    }
    .form-head {
        font-weight: 500;
        font-size: 24px;
        margin: 0;
        /* padding-bottom: 20px; */
        /* border-bottom: 1px solid #00ACC1; */
    }
    hr {
        background-image: linear-gradient(90deg, white, #00ACC1, white);
        border: 0;
        height: 1.5px;
    }
    #loginform {
        text-align: right;
        display: grid;
        flex-direction: column;
        row-gap: 10px;
        width: 90%;
        margin: auto
    }
    #numberform {
        display: inline-block;
        vertical-align: bottom;
    }
    #putnumber {
        display: inline-block;
    }
    #login-form {
        margin: auto;
    }
    #google {
        background-color: white;
        border: 1px solid #00ACC1;
        box-sizing: border-box;
        border-radius: 25px;
        font-weight: bold;
        height:45px
    }
    #goole-p {
        display: inline-block;
    }
    #or {
        font-weight: bold;
        /* margin: 10px; */
        text-align: center;
    }
    #google-image {
        vertical-align: middle;
        margin-left: 10px;
    }
    #sub-p {
        text-align: right;
    }
    a{
        text-decoration: none;
        color:#00ACC1;
    }
    #forget-p {
        text-align: left;
        margin: 0;
        position: absolute;
        bottom: -10px;
        left: 0;
        font-size: 12px;
        /* transform: translateY(30px); */
    }
    .input__label__image {
        position: absolute;
        padding: 0 5px 0 10px;
        white-space: nowrap;
        transform-origin: 0 0;
        background-color:white;
        transition: transform 120ms ease-in;
        font-weight: 500;
        font-size: 16px;
        right: 20px;
        top: 0; 
        transform: translateY(-60%);
    }
    .input__field__image {
        height: 45px;
        border: 1px solid #00ACC1;
        box-sizing: border-box;
        border-radius:25px;
        vertical-align: text-top;
        font-size: 16px;
        display: flex;
    flex-grow: 1;
    }
    #code-number {
        /* margin: 20px; */
        direction: rtl;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 400px;
    }
    #google {
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input__field , .input__field__image {
        font-size: 16px;
    }
    .login {
        /* width: 100%; */
        height: 45px;
        background: #00ACC1;
        border-radius: 79px;
        border: 0;
        color: white;
        margin: 0 5px 0 0;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        border-radius: 35px;
        font-weight: 700;
    }
    #error{
        color: red;
        font-weight: 500;
        font-size: 14px;
        opacity: 0.8;
        margin-bottom:5px;
    }
    .input__error {
        font-size: 16px;
        margin: 10px 0 10px 0;
        border: 1px solid red;
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 45px;
        padding: 10px;
        border-radius:25px;
        position: relative;
    }
    .show-pass {
        position: absolute;
        top: 20px;
        left: 0;
        background: transparent;
        border: 0;
    }
</style>