var activeOrders = [
    {
        "date":'xxxx/xx/xx-xx:xx',
        "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "done":80,
        "deal":"buy"
      

    },
    {
        "date":'xxxx/xx/xx-xx:xx',
        "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "done":50,
        "deal":"sell"

    },
    {
        "date":'xxxx/xx/xx-xx:xx',
        "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "done":100,
        "deal":"sell"

    },
    {
        "date":'xxxx/xx/xx-xx:xx',
        "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "done":20,
        "deal":"buy"

    },
    {
        "date":'xxxx/xx/xx-xx:xx',
        "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "done":90,
        "deal":"sell"

    },
    {
        "date":'xxxx/xx/xx-xx:xx',
        "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "done":70,
        "deal":"sell"

    },
    {
        "date":'xxxx/xx/xx-xx:xx',
        "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "done":80,
        "deal":"buy"

    },
    {
        "date":'xxxx/xx/xx-xx:xx',
        "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "done":80,
        "deal":"sell"

    }
]
export default activeOrders