<template>
<div >
    <BuyBit v-if="fromChild" @closeModal="closeModalChild" />
    <SellBit v-if="showSell" @closeModal="closeModalChild"/>
    <div id="profile-navbar">
        <ProfileNavbar />
        <!-- <button class="menue" @click="showSidebar"></button> -->
            <div class="mainProfile-container">
        <Sidebar class="sidebar" @tomanTeter="ckeckToman"/>

        <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
        
        <div class="currency-information">
            <Market v-if="$route.path=='/profile'" @selectCrypto="setCrypto" :unit="tomanTeter"/>
            <PortfolioItems v-if="$route.path=='/portfolio'"  @selectCrypto="setCrypto" :unit="tomanTeter"/>
            <OrdersList v-if="$route.path=='/orders'"/>
            <Trading v-if="$route.path=='/trade'"/>
            <div class="currency-detail">
                <div class="currency-detail-title">
                    <div class="currency-detail-title1">
                        <!-- <div> -->
                            <img :src="crypto[cryptoIndex].image" alt="">
                        <!-- </div> -->
                        <div>
                            <p>{{crypto[cryptoIndex].name}}</p>
                            <p>{{crypto[cryptoIndex].ENname}}<p/>
                        </div>
                    </div>
                    <div class="percent-container">
                        <div class="currency-detail-title2" 
                            :class="{'negative-change' : crypto[cryptoIndex].percentChange < 0 , 'positive-change' : crypto[cryptoIndex].percentChange > 0}">
                            <span>
                                <span></span>
                                % {{Math.abs(crypto[cryptoIndex].percentChange)}} 
                                <span v-if="crypto[cryptoIndex].percentChange > 0"> + </span>
                                <span v-if="crypto[cryptoIndex].percentChange < 0"> - </span>
                            </span>
                        </div>
                    </div>
                    <div class="currency-detail-title3" >
                        <p>1,213,200 تومان</p>
                        <p>حجم کل : 5,987,000</p>
                    </div>
                </div>
                <perfect-scrollbar>
                <div class="currency-detail-prices">
                    <div class="buy-status">
                        <div class="title-row">
                            <div class="status-title-total">
                                <span class="status-title">مجموع</span>
                                <span class="unit">(تومان)</span>
                            </div>
                            <div>
                                <span class="status-title">مقدار</span>
                            </div>
                            <div>
                                <span class="status-title">قیمت واحد</span>
                            </div>
                        </div>
                        <div class="detail-status green1">
                            <span>17,520,698</span>
                            <span>0.025</span>
                            <span>17,520,698</span>
                        </div>
                        <div class="detail-status green2">
                            <span>17,520,698</span>
                            <span>0.025</span>
                            <span>17,520,698</span>
                        </div>
                        <div class="detail-status green3">
                            <span>17,520,698</span>
                            <span>0.025</span>
                            <span>17,520,698</span>
                        </div>
                        <div class="detail-status green4">
                            <span>17,520,698</span>
                            <span>0.025</span>
                            <span>17,520,698</span>
                        </div>
                        <div class="detail-status green5">
                            <span>17,520,698</span>
                            <span>0.025</span>
                            <span>17,520,698</span>
                        </div>
                    </div>
                    <div class="sell-status">
                        <div class="title-row">
                            <div class="status-title-total">
                                <span class="status-title">مجموع</span>
                                <span class="unit">(تومان)</span>
                            </div>
                            <div>
                                <span class="status-title">مقدار</span>
                            </div>
                            <div>
                                <span class="status-title">قیمت واحد</span>
                            </div>
                        </div>
                        <div class="detail-status red1">
                            <span>17,520,698</span>
                            <span></span>
                            <span>17,520,698</span>
                        </div>
                        <div class="detail-status red2">
                            <span>17,520,698</span>
                            <span>0.025</span>
                            <span>17,520,698</span>
                        </div>
                        <div class="detail-status red3">
                            <span>17,520,698</span> 
                            <span>0.025</span>
                            <span>17,520,698</span>
                        </div>
                        <div class="detail-status red4">
                            <span>17,520,698</span>
                            <span>0.025</span>
                            <span>17,520,698</span>
                        </div>
                        <div class="detail-status red5">
                            <span>17,520,698</span>
                            <span>0.025</span>
                            <span>17,520,698</span>
                        </div>
                    </div>
                </div>
                </perfect-scrollbar>
                <div class="limitMarket">
                    <LimitMarket class="limitMarket" v-on:childToParent="onChildClick" v-on:childToParentSell="onChildClickSell"/>
                </div>
            </div>
        </div>
</div>
</div>
    </div>
<!-- </div> -->
</template>
<script>
import BuyBit from '../Modals/BuyBit.vue'
import SellBit from '../Modals/SellBit.vue'
import LimitMarket from './LimitMarket.vue'
// import Authentication from './Authentication.vue'
import ProfileNavbar from "./ProfileNavbar.vue"
import Sidebar from './Sidebar.vue'
import currency from "../../library/currency.js"
import Market from './Market.vue'
import PortfolioItems from "./PortfolioItems.vue"
import OrdersList from "./OrdersList.vue"
import Trading from './Trading.vue'
import Marketlist from "../../library/Market.js"
export default {
  components: { ProfileNavbar, Sidebar, BuyBit, SellBit , LimitMarket, Market , PortfolioItems , OrdersList, Trading},
  name: 'MainProfile',
  data : function() {
      return {
          bit : [
              {
                  name:"بیت کوین-تومان",
                  ENname:"BTC",
                change:0.5
              }
          ],
          tomanTeter:false,
          crypto:Marketlist,
          cryptoIndex:0,
          fromChild:false,
          showSell:false,
          showBuy:false,
          active:true,
          showSide:false,
          childBuy:false,
          sidebarResponsive:false,
          currencyInformation:currency,
          currencyList: [
              {
                  name:"بیت کوین",
                  EnNmae:"BTN",
                  image:require('../../assets/bitcoin.png'),
                  price:2525,
                  percentChange:- 0.5,
                  amount:4654654654654,
                  p:'25%'
              },
              {
                  name:"بیت کوین",
                  EnNmae:"BTN",
                  image:"",
                  price:2525,
                  percentChange:+0.3,
                  amount:4654654654654,
              },
              {
                  name:"بیت کوین",
                  EnNmae:"BTN",
                  image:"",
                  price:2525,
                  percentChange:- 0.5,
                  amount:4654654654654,
              },
              {
                  name:"بیت کوین",
                  EnNmae:"BTN",
                  image:"",
                  price:2525,
                  percentChange:- 0.5,
                  amount:4654654654654,
              },
              {
                  name:"بیت کوین",
                  EnNmae:"BTN",
                  image:"",
                  price:2525,
                  percentChange:+0.3,
                  amount:4654654654654,
              },
              {
                  name:"بیت کوین",
                  EnNmae:"BTN",
                  image:"",
                  price:2525,
                  percentChange:+0.3,
                  amount:4654654654654,
              },
          ]
      }
      
  },
  methods: {
      ckeckToman(value) {
          this.tomanTeter=value
      },
      showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
        //   showLimit () {
        //       this.active=!this.active
        //   }
        onChildClick (value) {
      this.fromChild = value
    },
    onChildClickSell(value) {
        this.showSell = value
    },
     closeModalChild(value) {
          this.fromChild = value
          this.showSell = value
          console.log(this.fromChild , this.showSell)
      },
      setCrypto(value) {
          this.cryptoIndex=value
         console.log(value)
     }
      },
    //   showSide(){
    //       this.showSide=!this.showSide
    //   },
     
    //   childShowBuy: function() {
    //              this.childBuy = true;
    //               },
        
}

</script>

<style scoped>
.limitMarket {
    width: 100%;
    /* height: 52vh;
    overflow: scroll; */
}
.sidebar-responsive {
    position: absolute;
    z-index: 10;
}
.mainProfile-container {
    width: 100%;
    display: flex;
    margin: 0 10px;
    /* column-gap: 10px; */
    height: calc(100vh - 70px);
    max-width: 1440px;

}
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;
        justify-content: center;

    }
    .currency-information {
        display: flex;
        /* align-items: flex-end; */
        justify-content: space-between;
        gap: 10px;
        flex-grow: 1;
         padding: 0 10px;
        /* width: 98%; */
        /* flex-wrap: wrap; */
        width: 100%;
    }
    table  {
        width: 100%;
        border-collapse: collapse;
    }
    .currency-list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: top;
        flex-grow: 4;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        /* min-width: 650px; */
        /* min-width: 55%; */
        width: 45%;
       
        /* overflow-x: scroll; */
    }
    .currency-list-head-row {
        border-bottom: 1px solid black;
        font-weight: bold;
font-size: 14px;
    }
    .currency-list-head-row > td > p {
        opacity: 0.8;
    }
    .currency-list-head-row > td {
        width: 25%;
    }
    .currency-list-head-row > td:first-of-type {
        text-align: right;
    padding-right: 40px;
    }
    .currency-list-container {
        width: 100%;
        /* min-width: 400px; */
    }
    .currency-list-row {
        /* display: flex; */
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: space-between;
        border-bottom: 1px solid gainsboro;
        align-items: center;
        padding: 5px;
        font-weight: bold;
font-size: 14px;
    }
    .currency-list-head-row , .currency-list-row , .currency-list-row > td {
        text-align: center;
    }
    .currency-list-row-image {
        display: flex;
        align-items: center;
    }
    .currency-list-row-image > img {
        width: 35px;
        height: 35px;
    }.currency-list-row-name {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .currency-list-row-name > p {
        margin: 0;
        text-align: right;
        width: max-content;
    }
    .ENname {
        text-align: right;
        font-weight: 500;
font-size: 14px;
    }
    .currency-list-row-title {
        display: flex;
        column-gap: 15px;
            padding: 5px 0;
        /* justify-content: center; */
        margin-right: 15px;
    }
    .currency-detail {
        height: 98%;
    }
    .currency-detail-title2 {
        /* width: 90px; */
        height: 38px;
        border-radius: 44px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        /* font-size: 16px; */
        font-size: clamp(12px , 1vw , 16px);
        width: 8vw;
        max-width: 90px;
        min-width: 70px;
    }
    .positive-change {
        background: #058C2B;
    }
    .negative-change {
        background: #E0342B;
    }
    .currency-list-head-row {
        /* display: flex; */
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(15, 15, 15, 0.8);
    }
    .currency-detail {
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
        align-items: center;
        flex-grow: 3;
        row-gap: 10px;
    }
    .currency-detail > div {
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.35));
        /* background: white; */
    }
    .currency-detail-title , .currency-detail-prices {
        background: white;
    }
    .detail-status {
        /* width: 245px; */
        height: 21px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
        /* display: flex;
        justify-content: space-between; */
        /* flex-grow: 1; */
        /* align-items: center; */
    }
    .title-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
    }
    .sell-status {
        direction: ltr;
    }
    .sell-status > div:first-of-type {
        /* padding: 0 15px; */
        margin-bottom: 10px;
    }
    .buy-status > div:first-of-type {
        /* padding: 0 10px; */
        margin-bottom: 10px;
    }
    .detail-status > span{
        font-weight: bold;
        /* font-size: 14px; */
        /* font-size: 1vw;
         */
         font-size: clamp(10px , 1vw , 14px);
    }
    .detail-status > p:first-of-type {
        padding: 0 10px;
    }
    /* .buy-status , .sell-status {
        min-width: 250px;
    } */
    .status-title {
        font-weight: bold;
        /* font-size: 14px; */
        margin: 0;
        /* font-size: 1vw; */
        font-size: clamp(10px , 1vw , 14px);
    }
    .status-title-total {
        /* padding: 0 10px; */
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .status-title+p {
        margin: 0;
        font-weight: normal;
        /* font-size: 12px; */
        font-size: 0.8vw;
    }
    .currency-detail , .currency-list {
        /* height: 100%; */
        border-radius: 2px;
        
    }
    
    .currency-detail-title  {
        display: flex;
    justify-content: space-between;
    /* flex-grow: 1; */
    align-items: center;
    align-self: normal;
    padding: 0 15px;
    flex-wrap: wrap;
    /* min-height: 70px; */
    row-gap: 27px;
    column-gap: 10px;
    /* height: 11vh; */
    /* overflow: scroll; */
    flex-basis: 70px;
    }
    .currency-detail-title div {
        width: 30%;
        flex-grow: 1;
    }
    .percent-container {
        display: flex;
        justify-content: center;
    }
    .currency-detail-prices {
        display: flex;
        flex-direction: row;
        /* flex-wrap: wrap; */
    justify-content: space-between;
    /* flex-grow: 1; */
    align-items: center;
    align-self: stretch;
    align-self: normal;
    column-gap: 20px;
    row-gap: 20px;
    padding: 20px 0;
    height: 33vh;
    overflow: scroll;
    }
     .currency-detail-prices > div {
         display: flex;
         flex-direction: column;
    /* justify-content: space-between; */
    flex-grow: 1;
    align-self: normal;
    row-gap: 5px;
     }
     .currency-detail-prices > div > div:first-of-type {
         /* display: flex;
         justify-content: space-between; */
     }
     .currency-detail-prices > div > p {
         margin: 0;
     }
    .currency-detail-title1 {
        display: flex;
        align-items: center;
        column-gap:5px;
    }
    .currency-detail-title1 > div > p , .currency-detail-title3  > p:first-child {
        margin: 0;
        font-weight: bold;
        font-size:clamp(14px , 1vw , 18px);
        /* font-size: 1vw; */
    }
    .currency-detail-title1 > div > img {
        width: 3.5vw;
    }
    .currency-detail-title3 > p:nth-child(2) {
        font-weight: normal;
        font-size:clamp(10px , 1vw , 12px);
        /* font-size: 0.9vw; */
        margin: 0;
    }
     .currency-detail-title3 {
         text-align: center;
     }
    
    .currency-change-red {
        background: #E0342B;
        border-radius: 44px;
        width: 75px;
        height: 30px;
        color: white;
        font-weight: bold;
font-size: 14px;
display: flex;
justify-content: center;
align-items: center;
margin: auto;
    }
    .currency-change-red > p {
        direction: ltr;
    }
    .currency-change-green {
        background: #058C2B;
        border-radius: 44px;
        width: 75px;
        height: 30px;
        color: white;
        font-weight: bold;
font-size: 14px;
display: flex;
justify-content: center;
align-items: center;
margin: auto;
    }
    .currency-detail-inputs {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-self: normal;
        height: 46vh;
    }
    .activeLimit {
        background: white;
        cursor: pointer;
        text-align: center;
        width: 80px;
    }
    .deActiveLimit {
        background: rgba(15, 15, 15, 0.1);
        cursor: pointer;
        text-align: center;
        width: 80px;
    }
    .activeLimit > p , .deActiveLimit > p {
        margin: 2px;
    }
    .currency-detail-inputs > div  {
        display: flex;
    }
    .currency-detail-inputs > div > div  {
        /* background: white; */
        /* width: 80px; */
        /* text-align: center; */
        font-weight: bold;
        font-size: 16px;
    }
    .currency-detail-inputs-deal {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 20px;
        padding: 0 20px 20px 20px;
        row-gap: 20px;
        background: white;
        flex-wrap: wrap;
        height: 100%;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .currency-detail-inputs-deal::-webkit-scrollbar {
        display: none;
    }
    .currency-detail-inputs-deal > div {
        display: flex;
        align-self: normal;
        flex-grow: 1;
        flex-direction: column;

    }
    .currency-detail-inputs-sell-limit > div > button{
        background: #E0342B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        width: 100%;
        height: 40px;
    }
    .currency-detail-inputs-sell-market > div > button  {
        background: #E0342B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        width: 100%;
        height: 40px;
    }
    .currency-detail-inputs-buy-limit > div > button , .currency-detail-inputs-buy-market > div > button{
        background: #058C2B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        width: 100%;
        height: 40px;
    }
    .unit {
        font-weight: normal;
        font-size: 12px;
        color: #0F0F0F;
        opacity: 0.8;
    }
    .currency-detail-inputs-sell-limit , .currency-detail-inputs-buy-limit , .currency-detail-inputs-sell-market , .currency-detail-inputs-buy-market {
        row-gap: 20px;
    }
    .currency-detail-inputs-sell-limit  , .currency-detail-inputs-buy-limit{
        height: 340px !important;
    }
    .currency-detail-inputs-sell-limit > div >label > input , .currency-detail-inputs-buy-limit > div >label > input , .currency-detail-inputs-sell-market > div >label > input , .currency-detail-inputs-buy-market > div >label > input{
        width: 100%;
        height: 35px;
        border-radius: 2px;
        
    }
    .currency-detail-inputs-buy-limit > div >label > input ,.currency-detail-inputs-buy-market > div >label > input {
        border: 1px solid rgba(5, 140, 43, 0.8);
    }
    .currency-detail-inputs-sell-limit > div >label > input , .currency-detail-inputs-sell-market > div >label > input {
        border:1px solid rgba(224, 52, 43, 0.8)
    }
    .currency-detail-inputs-sell-limit > div >label  , .currency-detail-inputs-buy-limit > div >label  , .currency-detail-inputs-sell-market > div >label  , .currency-detail-inputs-buy-market > div >label{
        position: relative;
    }
    .currency-detail-inputs-sell-limit > div >label >span  , .currency-detail-inputs-buy-limit > div >label > span , .currency-detail-inputs-sell-market > div >label >span  , .currency-detail-inputs-buy-market > div >label > span {
        position: absolute;
        top: -22px;
    right: 10px;
    background: white;
    padding: 0 5px;
    font-weight: bold;
font-size: 14px;
    }
    .max-price-buy > p , .min-price-sell >p , .cash-toman >p , .cash-bit>p {
        font-weight: normal;
        font-size: 12px;
        margin: 10px;
        display: inline-block;
        border-bottom: 1px solid black;
    }
    .line {
        max-width: 100%;
    }
    .red1 {
        background: linear-gradient(90deg , rgba(224, 52, 43, 0.3) 95% , white 95% ) ;
    }
    .red2 {
        background: linear-gradient(90deg , rgba(224, 52, 43, 0.3) 35% , white 35% ) ;
    }
    .red3 {
        background: linear-gradient(90deg , rgba(224, 52, 43, 0.3) 50% , white 50% ) ;
    }
    .red4 {
        background: linear-gradient(90deg , rgba(224, 52, 43, 0.3) 20% , white 20% ) ;
    }
    .red5 {
        background: linear-gradient(90deg , rgba(224, 52, 43, 0.3) 80% , white 80% ) ;
    }
    .green1 {
        background: linear-gradient(90deg  , white 20% , rgba(5, 140, 43, 0.3) 20% ) ;
    }
    .green2 {
        background: linear-gradient(90deg , white 35% , rgba(5, 140, 43, 0.3) 35%  ) ;
    }
    .green3 {
        background: linear-gradient(90deg  , white 50% , rgba(5, 140, 43, 0.3) 50%) ;
    }
    .green4 {
        background: linear-gradient(90deg  , white 20% , rgba(5, 140, 43, 0.3) 20%) ;
    }
    .green5 {
        background: linear-gradient(90deg  , white 5% , rgba(5, 140, 43, 0.3) 5%) ;
    }
    @media only screen and (max-width:1000px) {
        .limitMarket {
            height: auto;
        }
        .mainProfile-container , .currency-detail , .currency-detail-title {
            height: unset;
        }
    }
   @media only screen and (max-width: 900px) {
        /* .currency-detail {
            display: none;
        } */
   }
    @media only screen and (max-width:600px) {
        .currency-detail-title {
            column-gap: 50px;
        }
    }
    .limitMarket::-webkit-scrollbar ,  .currency-detail-prices::-webkit-scrollbar , .currency-detail-title::-webkit-scrollbar{
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.limitMarket , .currency-detail-prices , .currency-detail-title {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>
<style >

.sidebar-responsive {
    position: absolute;
    z-index: 10;
}

@media screen and (max-width: 1000px) {
.currency-information {
    flex-wrap: wrap !important;
}
.currency-list , currency-detail {
    height: auto !important;
}
.status-title , .detail-status > p  {
            font-size: 10px !important;
            font-weight: 300 !important;
        }
        .buy-status > div:first-of-type , .sell-status > div:first-of-type{
            padding: 0 !important;
        }
        .status-title ~ p {
            font-size: 8px !important;
        }
        .currency-detail-title {
            padding: 15px !important;
        }

        .currency-detail-title2 > div {
            width: 90px !important;
        }
        .currency-detail-title3 > p:nth-child(2) {
            font-size: initial !important;
        }
        .currency-detail-title1 > div > img {
            width: auto !important;
        }
        .currency-detail-title1 > div > p {
           font-size:initial !important;
        }
        .currency-detail-title3 > p:first-of-type {
           font-size:initial !important;
        }
         }




 @media screen and (max-width: 600px)  {
        .sidebar {
            display: none;
            width: 135px;
        }
        /* .sidebar-responsive {
            display: none;
        } */
        .currency-list-row-amount {
            display: none;
        }
        .currency-list , .portfolio-list , .portfolio-main{
            height: auto !important;
        }
        .currency-detail-title {
            justify-content: center !important;
            
        }
        .menue {
            display:flex;
            width: 30px;
            height: 30px;
            background-color: transparent;
            background: url("../../assets/ham.svg")no-repeat;
            background-size: 100%;
        }
 }
         @media screen and (min-width: 601px) {
    
        .menue {
            display: none;
        }
        .sidebar-responsive {
            display: none;
        }
        
         }
</style>
