<template>
<div>
    <div id="profile-navbar">
        <ProfileNavbar/>
         <!-- <button class="menue" @click="showSidebar">
             
        </button> -->
        <!-- <Authentication/> -->
        <div class="history-main-container">
            <Sidebar class="sidebar" />
            <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
            <div class="history-container">
                <div class="history-items">
                    <!-- <a href="/history/trade" @click.prevent="$router.push('/history/trade')"> -->
                        <div class="history-item active" id="0" @click="showTrade" >معاملات </div>
                    <!-- </a> -->
                    <div class="history-item " id="1" @click="showTrade">تراکنش ریالی</div>
                    <div  class="history-item " id="2" @click="showTrade">تراکنش رمز ارز </div>
                    <div class="history-item " id="3" @click="showTrade">لاگین</div>
                </div>
                <!-- :class="{'active':activeTrade , 'disActive':!activeTrade}"
                :class="{'active':activeRial , 'disActive':!activeRial}"
                :class="{'active':activeCurrency , 'disActive':!activeCurrency}"
                :class="{'active':activeLogin , 'disActive':!activeLogin}"   -->
                <div class="describe-history-items">
                    <div class="history-items-container history-items-container-active" :class="{'show-trade':activeTradeTabel , 'no-show':!activeTradeTabel}">
                        <HistoryTrades  />
                        <!-- :class="{'show-trade':activeTradeTabel , 'no-show':!activeTradeTabel}" -->
                    </div>
                    <div class="history-items-container" >
                        <HistoryRialy />
                    </div>
                    <div class="history-items-container" >
                        <HistoryCurrency />
                    </div>
                    <div class="history-items-container" >
                        <HistoryLogin />
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>
</template>
<script>
// import Authentication from './Authentication.vue'
import ProfileNavbar from "./ProfileNavbar.vue"
import Sidebar from './Sidebar.vue'
import HistoryTrades from "./HistoryTrades.vue"
import HistoryRialy from "./HistoryRialy.vue"
import HistoryCurrency from "./HistoryCurrency.vue"
import HistoryLogin from "./HistoryLogin.vue"
import { getDirective } from 'vue-debounce'
// import { debounce } from 'vue-debounce'
// import vueDebounce from 'vue-debounce'


export default {
  components: { ProfileNavbar, Sidebar ,HistoryTrades , HistoryRialy , HistoryCurrency , HistoryLogin },
  name: 'MainProfile',
  data:function() {
      return {
        //   deActiveBuys:false,
        //   activeBuys:true,
        activeTrade:true,
        activeTradeTabel:true,
        deActiveTrade:false,
        activeRial:false,
        deActiveRial:true,
        activeCurrency:false,
        deActiveCurrency:false,
        activeLogin:false,
        deActiveLogin:false,
        sidebarResponsive:false,


      }
  },
  directives: {
    // Please see above for arguments you can pass to this function
    debounce: getDirective()
  },
//   created() {
//   this.showTrade = _.debounce(this.showTrade, 500);
// },
  methods : {
      showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
      showTrade (event) {
        //   if(!this.activeTrade) {
        //   this.activeTrade=!this.activeTrade
        //   this.activeRial=false
        //   this.activeTrade=false
        //   this.activeLogin=false
        //   }
        //   else
        //   return true

        //   this.activeTrade=!this.activeTrade
        var target=event.target || event.srcElement;
        var buttonList=document.querySelectorAll(".history-item");
        var item=document.getElementsByClassName("history-items-container")[target.id];
        var items=document.querySelectorAll(".history-items-container-active");
        // console.log(item)
        buttonList.forEach(function(button) {
            if (button === target && !button.classList.contains("active") ) 
                return button.classList.add("active")
                 items.forEach(function(itemActive){itemActive.classList.remove("history-items-container-active")})
                 item.classList.add("history-items-container-active")
            
            // else {
            //     button.classList.remove("active")
            //     // item.classList.remove("history-items-container-active")
            // }
            return button.classList.remove("active")
        })
    //   },
    //   showRial() {
    //       if(!this.activeRial) {
    //           this.activeRial=!this.activeRial
    //       this.activeTrade=false
          
    //       this.activeTrade=false
    //       this.activeLogin=false
    //       }
    //       else
    //       return true
    // console.log(event)
      },
    //   beActiveBuys() {
    //       if (this.deActiveBuys) {
    //           this.deActiveBuys=false
    //           this.activeBuys=true
    //       }
    //       else 
    //           return true
    //   },
    //   beActiveSells () {
    //       if (this.activeBuys) {
    //           this.deActiveBuys=true
    //           this.activeBuys=false
    //       }
    //       else 
    //           return true
    //   },

    // showRial () {
    //     if (this.deAactiveTrade) {
    //         this.activeTrade=false
    //         this.deActiveTrade=true
    //     }
    //     else
    //     return true
    // }
  }
}
</script>

<style >
.history-main-container {
    width: 100%;
    max-width: 1440px;
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    height: calc(100vh - 70px);
}
.history-container {
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.35));
    height: 98%;
}
.sidebar-responsive {
    position: absolute;
    z-index: 10;
}
.history-items {
    display: flex;
    flex-direction: row;
    cursor: pointer;
   
}
.active-buys ,.active-sells {
    background-color: #058C2B;
    color: white;
}
 
.history-item {
    background-color: rgba(243, 243, 243 , 0.1);
    /* background: rgba(15, 15, 15, 0.1); */
     /* height: 35px; */
     width: 110px;
     display: flex;
    justify-content: center;
    align-items: center;
    background:white;
    font-weight: bold;
font-size: 14px;
text-align: center;
    height: 40px;
}
.active {
    background: white;
}
.describe-history-items {
    display: flex;
    flex-grow: 1;
    height: 600px;
    border-radius: 2px;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
    background: white;
    box-shadow: none;
}
.history-items-container {
    width: 95%;
    display: none;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    row-gap: 30px;
}
.history-items-container-active {
    display: flex;
}

.history-markets {
    width: 120px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 25px;
    background: transparent;
}
.filter-history-row {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    margin-top: 20px;
}
.history-sell-buy {
    width: 200px;
    height: 39px;
    border-radius: 25px;
    display: flex;
    align-items: center;
}
.history-buys {
    width: 100px;
    height: 35px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.history-sells {
    width: 100px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.describe-row-header {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
}
    .wallet-header {
        font-weight: bold;
        font-size: 20px;
    }
    .wallet-title-p , .amount-p {
        font-weight: bold;
        font-size: 16px;
    }
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;
        justify-content: center;

    }
    .history-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        align-self: baseline;
    }
    .wallet-row {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); */
        border-radius: 2px;
        flex-wrap: wrap;
    }
    .wallet-title {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-grow: 1;
    }
    .wallet-amount {
        flex-grow: 1;
        justify-content: center;
        display: flex;
        min-width: 100px;
    }
    .wallet-options {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        gap: 5px;
    }
    .wallet-blue-button , .wallet-red-button , .wallet-green-button {
        width: 95px;
        height: 30px;
        color: white;
        border: 0;
        border-radius: 2px;
    }
    .wallet-blue-button {
        background-color: #00ACC1;
    }
    .wallet-red-button {
        background-color: #E0342B;
    }
    .wallet-green-button {
        background-color: #058C2B;
    }
     @media only screen and (max-width: 600px) {
         .history-item {
             font-size: 12px;
         }
     }
</style>
