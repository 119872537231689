<template>
<div>
    <div id="profile-navbar">
        <ProfileNavbar/>
         <!-- <button class="menue" @click="showSidebar">
             
        </button> -->
        <!-- <Authentication/> -->
        <div class="chart-main-container">
            <Sidebar class="sidebar"/>
            <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
            <div class="chart-container">
                <div class="title-chart">
                    <div class="name-title-chart">
                        <div>
                            <img :src=coins[index].image alt="">
                        </div>
                        <div class="currency-name-chart">
                            <p>{{coins[index].name}}</p>
                            <p class="ENname">{{coins[index].ENname}}</p>
                        </div>
                        <div class="name-title-chart-option">
                            <button v-on:click.stop="chartItems" v-click-outside="closeChartItems"><img src="@/assets/selector-black.png" alt=""></button>
                        </div>
                    </div>
                    <div class="detail-title-chart">
                        <div class="title-chart-percent">
                            <p
                            :class="{'negative-change' : coins[index].percentChange < 0 , 'positive-change' : coins[index].percentChange > 0}"
                            >{{Math.abs(coins[index].percentChange)}}
                                <span v-if="coins[index].percentChange > 0">+</span>
                                <span v-if="coins[index].percentChange < 0">-</span>
                            </p>
                        </div>
                        <div class="title-chart-price">
                            <p>{{coins[index].price.toLocaleString()}} تومان</p>
                            <p>حجم کل: {{coins[index].amountDay.toLocaleString()}}</p>
                        </div>
                        <a href="/trade" @click.prevent="$router.push('/trade')">
                            <div class="title-chart-buttton">
                                <button>معامله</button>
                            </div>
                        </a>
                    </div>
                    
                    <transition name="fade">
                        
                    <div class="dropdown-chart" v-if="isActive">
                        <perfect-scrollbar>
                        <div class="drop-down-parent">
                            <div class="dropdown-chart-item" v-for="(coin , index) in coins" :key="index" @click="selectCoin(index)">
                                <div class="chart-item-name">
                                    <div>
                                        <img :src=coin.image alt="">
                                    </div>
                                    <div>
                                        <p class="chart-item-currency">{{coin.name}}</p>
                                        <p class="chart-item-price"><span>{{coin.price.toLocaleString()}}</span> تومان</p>
                                    </div>
                                </div>
                                <div class="chart-item-percent">
                                    <p
                                    :class="{'negative-change' : coin.percentChange < 0 , 'positive-change' : coin.percentChange > 0}"
                                    >
                                    {{Math.abs(coin.percentChange)}}
                                    <span v-if="coin.percentChange > 0">+</span>
                                    <span v-if="coin.percentChange < 0">-</span>
                                    </p>
                                </div>
                            </div>
                        
                        </div>
                        </perfect-scrollbar>
                    </div>
                    
                    </transition>
                    
                </div>
                <div class="trading-chart">
                    <!-- <img src="@/assets/traiding.png" alt=""> -->
                    <vue-trading-view
                        style="height: 100%"
                        :options="{
                            height:'600',
                            autosize:true,
                                    
                                    timezone: 'Asia/Tehran',
                                    style: '1',
                                    
                                    locale: 'fa_IR',
                                    enable_publishing: true,
                                    withdateranges: true,
                                    range: 'ytd',
                                    hide_side_toolbar: false,
                                    allow_symbol_change: true,
                                    enabled_features: [
                                        'header_fullscreen_button'
                                        ],
                                    disabled_features: [
                                        'border_around_the_chart',
                                        'remove_library_container_border',
                                        ],
                                    loading_screen:{
                                        backgroundColor:'#00ff00',
                                        foregroundColor: '#000000',
                                        },
                                    overrides: {
                                        background: '#256'
                                    }
                        }"
                    />
                    
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import Vue2ClickOutside from 'vue2-click-outside'
import VueTradingView from 'vue-trading-view/src/vue-trading-view.vue'
// import Authentication from './Authentication.vue'
import ProfileNavbar from "./ProfileNavbar.vue"
import Sidebar from './Sidebar.vue'
// import { DataCube } from 'trading-vue-js'
import Market from '../../library/Market.js'
export default {
  components: { ProfileNavbar, Sidebar, VueTradingView },
  name: 'Chart',
//   data() {
//         return {
//             chart: new DataCube(Data),    
//             overlays: [NewOverlay]        
//         }
    // }
    data:function () {
        return {
            isActive:false,
            sidebarResponsive:false,
            coins:Market,
            index:0
        }
    },
    directives: {
      clickOutside: Vue2ClickOutside.directive
    },
    methods: {
        chartItems() {
            this.isActive=!this.isActive
        },
        showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
        closeChartItems() {
            this.isActive=false
        },
        selectCoin(index) {
            this.index=index
        }
    }
}
</script>

<style>
.chart-main-container {
    width: 100%;
    /* margin: 0 10px; */
    display: flex;
    column-gap: 10px;
    flex-direction: row;
    height: calc(100vh - 70px);
    max-width: 1440px;
    margin: 0 10px;
}
.sidebar-responsive {
    position: absolute;
    z-index: 10;
}
    .hide {
        display: none;
    }
    
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;

    }
    .chart-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        row-gap: 10px;
        height: 98%;
    }
    .title-chart , .trading-chart {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
    }
    /* .title-chart div {
        flex-grow: 1;
    } */
    .trading-chart {
        height: 100%;
    }
    .title-chart {
        /* height: 70px; */
        /* flex-wrap: wrap; */
        padding: 10px;
        justify-content: space-between;
        position: relative;
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .title-chart , .name-title-chart ,.detail-title-chart {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .detail-title-chart {
        column-gap: 30px;
        flex-wrap: wrap;
        justify-content: flex-end;
        row-gap: 10px;
    }
    .name-title-chart {
        column-gap: 10px;
        justify-content: center;
    }
    .title-chart-price > p {
        margin: 0;
    }
    .title-chart-price > p:nth-child(1) {
        font-weight: bold;
        font-size: 16px;
    }
    .title-chart-price > p:nth-child(2) {
        font-weight: normal;
        font-size: 12px;
    }
    .title-chart-percent > p {
        font-weight: bold;
        font-size: 20px;
        margin: 0;
        /* color: #058C2B; */
    }
    .currency-name-chart > p {
        margin: 0;
        font-weight: bold;
        font-size: 18px;
    }
    .name-title-chart-option > button {
        background: transparent;
    }
    .title-chart-buttton > button{
        background-color: #058C2B;
        border-radius: 40px;
        color: white;
        width: 116px;
        height: 40px;
    }
    .trading-chart > img {
        max-width: 100%;
    }
    .drop-down-parent {
        height: 329px;
    }
    .dropdown-chart {
        position: absolute;
        top: 75px;
        width: 214px;
        height: 329px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        z-index: 50;
        background: white;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .dropdown-chart::-webkit-scrollbar {
        display: none;
    }
    .dropdown-chart-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin:10px;
        border-bottom: 1px solid gainsboro;
        cursor: pointer;
    }
    .chart-item-name {
        display: flex;
        /* align-items: center; */
        column-gap: 5px;
        
    }
    .chart-item-name > div > img {
        width: 19px;
        height: 19px;
    }
    .chart-item-currency {
        margin: 0;
        font-weight: bold;
        font-size: 14px;
    }
    .chart-item-price {
        font-weight: normal;
        font-size: 12px;
        margin: 0;
    }
    .chart-item-percent > p {
        font-weight: bold;
        font-size: 16px;
        margin: 0;
    }
    .negative-change {
        color:rgba(224, 52, 43, 1);
    }
    .positive-change {
        color:rgba(5, 140, 43, 1);
    }
    
    @media only screen and (max-width: 600px) {
        .name-title-chart {
            flex-wrap: wrap;
    align-items: center;
    min-width: 50%;
        }
        .currency-name-chart > p {
            font-size: 14px;
        }
        .name-title-chart > div > img {
            width: 40px;
            height: 40px;

        }
        .title-chart-percent > p , .title-chart-price > p{
            font-size: 16px;
        }
        .detail-title-chart {
            padding: 10px 0;
            justify-content: center;
        }
        .dropdown-chart {
            top: 100px;
        }
    }
    /* .sidebar {
            display: none;
        }
    @media only screen and (max-width: 900px) {
        .sidebar {
            display: none;
        }
    } */
</style>
<style lang='scss' scoped>
    #profile-navbar {
        justify-content: center;
    }
    .ENname {
        font-size: 16px;
        font-weight: 500;
    }
    .fade-enter-active, .fade-leave-active {
        transition: height 1s ease;
        background: white;
        height: 329px;
    }

    .fade-enter, .fade-leave-to {
        height: 0;
    }
</style>
