var crypto = [
    {
        "image":require('../assets/bitcoin (2).png'),
        "name":"بیت کوین",
        "ENname":"BTN",
        "percentChange":+0.05,
        "amountDay":2132121231,
        "teterPrice":1234567890,
        "toomanPrice":9876543210,
    },
    {
        "image":require('../assets/ethereum.png'),
        "name":"کوین کش",
        "ENname":"BTN",
        "percentChange":-0.01,
        "amountDay":2132121231,
        "teterPrice":1234567890,
        "toomanPrice":9876543210,
    },
    {
        "image":require('../assets/binance.png'),
        "name":"ریپل",
        "ENname":"BTN",
        "percentChange":-0.03,
        "amountDay":2132121231,
        "teterPrice":1234567890,
        "toomanPrice":9876543210,
    },
    {
        "image":require('../assets/xrp.png'),
        "name":"بیت کوین",
        "ENname":"BTN",
        "percentChange":+0.07,
        "amountDay":2132121231,
        "teterPrice":1234567890,
        "toomanPrice":9876543210,
    },
    {
        "image":require('../assets/light (2).png'),
        "name":"اتریوم",
        "ENname":"BTN",
        "percentChange":+0.10,
        "amountDay":2132121231,
        "teterPrice":1234567890,
        "toomanPrice":9876543210,
    },
    {
        "image":require('../assets/teter.png'),
        "name":"اتریوم کلاسیک",
        "ENname":"BTN",
        "percentChange":-0.01,
        "amountDay":2132121231,
        "teterPrice":1234567890,
        "toomanPrice":9876543210,
    },
    {
        "image":require('../assets/bitCash.png'),
        "name":"بیت کوین",
        "ENname":"BTN",
        "percentChange":-0.8,
        "amountDay":2132121231,
        "teterPrice":1234567890,
        "toomanPrice":9876543210,
    },
    {
        "image":require('../assets/xrp.png'),
        "name":"دوج کوین",
        "ENname":"BTN",
        "percentChange":-0.07,
        "amountDay":2132121231,
        "teterPrice":1234567890,
        "toomanPrice":9876543210,
    },
    {
        "image":require('../assets/binance.png'),
        "name":"بیت کوین",
        "ENname":"BTN",
        "percentChange":+0.05,
        "amountDay":2132121231,
        "teterPrice":1234567890,
        "toomanPrice":9876543210,
    }
]
export default crypto