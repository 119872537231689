<template>
<div>
    <div id="authentication">
        <!-- <AuthenticationNavbar/> -->
        <ProfileNavbar/>
    </div>
    <div class="main-account"> 
        <div class="account-title">
            <p>سطح کاربری</p>
        </div>
        <!-- <perfect-scrollbar> -->
            <div class="accountLevels-container">
                <AccountLevels/>
            </div>
        <!-- </perfect-scrollbar> -->
        <div class="information-title">
            <p>اطلاعات حساب</p>
        </div>
        <div class="account-succes-message">
            <p>احراز هویت شما با موفقیت انجام شده</p>
        </div>
        <div class="account-personal">
            <div class="account-personal-title">
                <p>اطلاعات شخصی</p>
            </div>
            <div class="account-personal-form">
                <label class="input">
                    <input class="input__field" type="text" />
                    <span class="input__label">نام</span>
                    </label>
                    <label class="input">
                    <input class="input__field" type="text" />
                    <span class="input__label">  نام خانوادگی</span>
                    </label>
                    <label class="input">
                    <input class="input__field" type="text" />
                    <span class="input__label">  کد ملی</span>
                    </label>
            </div>
            <div class="account-personal-form">
                <label class="input">
                    <input class="input__field" type="text" />
                    <span class="input__label">تاریخ تولد</span>
                    </label>
                    <label class="input">
                    <input class="input__field" type="text" />
                    <span class="input__label">  شماره موبایل</span>
                    </label>
                    <label class="input">
                    <input class="input__field" type="text" />
                    <span class="input__label">  شماره ثابت</span>
                    </label>
            </div>
            <div class="account-personal-form">
                <label class="input address-input">
                    <textarea rows="3" class="input__field address textarea" type="text" />
                    <span class="input__label">  آدرس</span>
                    </label>
            </div>
        </div>
        <div class="account-bank">
            <div class="account-personal-title">
                <p>اطلاعات بانکی</p>
            </div>
            <div class="account-personal-form">
                <label class="input">
                    <input class="input__field bankName" type="text" />
                    <span class="input__label">نام بانک </span>
                    </label>
                    <label class="input">
                    <input class="input__field bankNumber" type="text" />
                    <span class="input__label">  شماره کارت</span>
                    </label>
                    <label class="input">
                    <input class="input__field shabaNumber" type="text" />
                    <span class="input__label">  شماره شبا</span>
                    </label>
            </div>
            <div class="add-bank-account">
                <button class="add-bank-account-btn" @click="addBank">
                    افزودن حساب جدید
                    <img src="@/assets/add.png" alt="">    
                </button>
            </div>
            <div class="account-bank-lists-container">
                <table class="account-bank-lists">
                    <tr class="tr-heade">
                        <td>نام بانک </td>
                        <td>شماره کارت</td>
                        <td class="td-shaba">شماره شبا</td>
                        <td>وضعیت</td>
                        <td></td>
                    </tr>
                    <tr class="tr-body" v-for="(account , index) in banks" :key="index">
                        <td>{{account.name}}</td>
                        <td>{{account.number}}</td>
                        <td class="td-shaba">{{account.shaba}}</td>
                        <td>
                            <img src="@/assets/tick-green.png" alt="" v-if="account.statuse==1">
                            <img src="@/assets/tick-red.png" alt="" v-if="account.statuse==2">
                        </td>
                        <td><img src="@/assets/trash.png" alt="" @click="deleteBank(event)" v-bind:id=account.id ></td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="account-confirm">
            <div class="account-personal-title">
                <p>تایید هویت و ارسال عکس</p>
            </div>
            <div class="account-confirm-box">
            <div class="account-text-confirm">
                <div>
                    <p>لطفا متن زیر را با دقت مطالعه کنید و در  صورت درستی اطلاعات تیک پذیرش اطلاعات را بزنی</p>
                </div>
                <div>
                    <p>متن دست نوشته</p>
                </div>
                <div>
                    <p>اینجانب مرجان خلیل پور به کد ملی  )۸۴۹۵۸۵۹۶۸۵۹( ضمن مطالعه و تایید قوانین استفاده از خدمات ویستا، متعهد می گردم که حساب کاربری و مدارک خود را به منظور خرید و فروش بیت کوین و سایر ارزهای دیجیتال در اختیار سایر افراد قرار ندهم و به درخواست شخص ثالث از جمله افراد و گروه های شبکه های اجتماعی و غیره اقدام به ایجاد حساب کاربری و خرید و فروش نکنم. در صورت تخلف، مسئولیت آن را اعم از مالی، قضایی و حقوقی به صورت کامل بر عهده می گیرم.خ</p>
                </div>
                <div>
                    <p>جهت احراز هویت در سایت باینکس</p>
                    <p>نام و نام خانوادگی امضا و تاریخ</p>
                </div>
            </div>
                <div class="div-upload">
                    <div class="image-place"><img src="@/assets/upload-image.png"></div>
                    <picture-input @change="onChange()" accept="image/jpeg,image/png"></picture-input>
                    <input type="image" src="@/assets/upload-image.png" alt=""/>
                    <label for="file-upload" class="custom-file-upload">
                        آپلود متن دست نوشته
                    </label>
                    <input id="file-upload" type="file"/>
                </div>
            </div>
        </div>
        <div class="account-confirm">
            <div class="account-personal-title">
                <p>تایید هویت و ارسال عکس</p>
            </div>
            <div class="account-confirm-box">
            <div>
                <p>لطفا یک عکس سلفی با شئونات اسلامی و  بدون ماسک، طوری که صورت شما کاملا مشخص باشد بگیرید</p>
            </div>
            <div>
                <img src="@/assets/selfie.png" alt="">
            </div>
            <div class="div-upload">
                    <div class="image-place"><img src="@/assets/upload-image.png"></div>
                    <picture-input @change="onChange()" accept="image/jpeg,image/png"></picture-input>
                    <input type="image" src="@/assets/upload-image.png" alt=""/>
                    <label for="file-upload" class="custom-file-upload">
                        آپلود متن دست نوشته
                    </label>
                    <input id="file-upload" type="file"/>
                </div>
                </div>
        </div>
        <div class="account-btn">
            <button>ثبت اطلاعات</button>
        </div>
    </div>
</div>
</template>
<script>

// import AuthenticationNavbar from "./AuthenticationNavbar.vue"
import AccountLevels from "./AccountLevels.vue"
import ProfileNavbar from './ProfileNavbar.vue';
export default {
  components: {  AccountLevels, ProfileNavbar},
  name: 'Chart',
  data : function() {
      return {
          banks : [
              {
                  name:"صادرات",
                  number:6037697422921137,
                  shaba:8100000007878787,
                  statuse:1,
                  id:1
              },
               {
                  name:"صادرات",
                  number:6037697422921137,
                  shaba:8100000007878787,
                  statuse:1,
                  id:2
              },
              {
                  name:"تجارت",
                  number:6037697422921137,
                  shaba:8100000007878787,
                  statuse:2,
                  id:3
              }
          ]
      }
  },
  methods : {
      deleteBank() {

      },
      
        // removeRow: function(index) {
        //     this.rows.splice(index, 1);
        // }
        addBank(){
            const bankName=document.getElementsByClassName("bankName")[0].value;
            const bankNumber=document.getElementsByClassName("bankNumber")[0].value;
            const shabaNumber=document.getElementsByClassName("shabaNumber")[0].value;
            if (bankName && bankNumber && shabaNumber) {
            this.banks.push({
                name:bankName , number:bankNumber , shaba:shabaNumber , statuse:1 
            });
           
           document.getElementsByClassName("bankNumber")[0].value="";
           document.getElementsByClassName("shabaNumber")[0].value="";
           document.getElementsByClassName("bankName")[0].value="";
            }
        }
   }
   
  }
  

</script>

<style lang="scss" scoped>
    .textarea {
        min-height: 95px;
        resize: none;
    }
    .main-account {
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .account-title , .information-title {
        font-weight: bold;
        font-size: 18px;
    }
    .account-succes-message {
        background: #058C2B;
        color: white;
        border-radius: 2px;
        height: 49px;
        display: flex;
        align-items: center;
    }
    .accountLevels-container {
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        background: transparent;
        filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
    }
    .accountLevels-container::-webkit-scrollbar {
        display: none;
    }
    .account-succes-message > p {
        margin: 0;
        font-weight: bold;
        font-size: 16px;
        padding: 0 20px;
    }
    .account-personal , .account-bank {
        /* height: 375px; */
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .account-personal-title {
        text-align: center;
        border-bottom:1px solid gainsboro;
    }
    .account-personal-title > p {
        font-weight: bold;
        font-size: 18px;
        margin: 5px;
    }
    .account-personal-form {
        display: flex;
        width: 90%;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-self: center;
    }
    .account-personal-form > label {
        min-width: 260px;
    }
    .address-input {
        width: 100%;
    }
    .add-bank-account {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .add-bank-account-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
    }
    .add-bank-account > button {
        width: 295px;
        height: 45px;
        color: white;
        background: #00ACC1;
        border-radius: 35px;
        font-weight: bold;
        font-size: 16px;
    }
    .account-bank-lists-container {
        overflow: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
    }
    .account-bank-lists-container::-webkit-scrollbar {
        display: none;
    }
    .account-bank-lists {
        width: 90%;
        margin: auto;
        text-align: center;
        border-collapse: collapse;
            .tr-heade , .tr-body {
                font-size: clamp(12px , 1vw , 16px);
            }
            .tr-heade {
                font-weight: bold;
            }
    }
    .account-bank-lists > tr {
        height: 70px;
        border-bottom: 1px solid gainsboro;
    }
    .account-confirm {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        display: flex;
    flex-direction: column;
    row-gap: 20px;
    }
    .account-confirm-box {
        display: flex;
        width: 90%;
        margin: auto;
        /* flex-wrap: wrap; */
        column-gap: 40px;
        margin-bottom: 20px;
        flex-wrap: wrap;
    }
    .account-text-confirm {
        max-width: 70%;
    }
    .account-text-confirm > div > p {
        font-weight: 500;
        font-size: 14px;
    }
    .account-confirm-box > div > p {
        font-weight: 500;
        font-size: 14px;
    }
    .account-confirm-box > div {
        width: 30%;
        min-width: 250px;
        flex-grow: 1;
    }
    .account-btn {
        display: flex;
        justify-content: center;
    }
    .account-btn > button {
        width: 394px;
        background: #00ACC1;
        border-radius: 35px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        height: 45px;
        margin: 50px;
    }
    .div-upload {
    box-sizing: border-box;
    /* width: 50%; */
    /* max-width: 500px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    row-gap: 5px;
    max-width: 100%;
}
.image-place {
    border-radius: 25px;
    padding: 10px 40px;
    border: 1px dashed #00ACC1;
}
input[type="file"] {
    display: none;
}
.custom-file-upload {
    height: 45px;
    background-color: #00ACC1;
    color: white;
    /* padding: 10px 70px; */
    border-radius: 20px;
    font-weight: bold;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 295px;
    width: 100%;
}
  @media screen and (max-width: 600px) {
        .td-shaba {
            display: none;
        }
    }
</style>
