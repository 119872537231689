<template>
<div>
    
    <ExitModal v-if="showModal" @close="showModal = false" />
        <div id="porofile-navbar">
        <div id="container">
            
            <div class="ham-icon">
                
            </div>
        <div id="buttons">
            <a href="/deposit/rial" @click.prevent="$router.push('/deposit/rial')">
                <button id="settle">
                واریز ریالی
                <img src="@/assets/settle.png" alt="">
                </button>
            </a>
            <a href="/withdrawal/rial" @click.prevent="$router.push('/withdrawal/rial')">
                <button id="remove">
                    برداشت ریالی
                    <img src="@/assets/remove.png" alt="">
                </button>
            </a>
        </div>
        <img id="logo-authen" src="@/assets/LOGOSITE1.png">
        <div id="name-bell">
            <a href="/notification" class="image-bell-link">
                <img src="@/assets/bell.png" alt="" class="image-bell">
                <span>5</span>
            </a>
            <button id="selector" v-on:click="detailProfile">
            <div id="name">
                <img src="@/assets/person-icon.png" >
                مرجان خلیل پور
                <img src="@/assets/selector.png" alt="">
                
            </div>
            </button>
        </div>
    </div>
    <div v-bind:class="{show: !isActive}" class="profileItem">
        <router-link to="/account"><p id="profileItem-p"><img src="@/assets/person-icon.svg" alt=""><button id="profileItem-button">حساب کاربری</button></p></router-link>
        <router-link to="/security"><p id="profileItem-p"><img src="@/assets/security.png" alt="" srcset=""><button id="profileItem-button">امنیت</button></p></router-link>
        <!-- <router-link to="/login"> -->
        <p id="profileItem-p"><img src="@/assets/exit.png" alt="" srcset=""><button id="profileItem-button" @click="showModal=true">خروج</button></p>
        <!-- </router-link> -->
    </div>
    
    </div>
                    <div :class="{'authen-ham':authenHam , 'd-authen-ham':DauthenHam}">
                    <button id="settle">
                        واریز ریالی
                        <img src="@/assets/settle.png" alt="">
                    </button>
                    <button id="remove">
                        برداشت ریالی
                        <img src="@/assets/remove.png" alt="">
                    </button>
                </div>
    </div>
</template>

<script>

import ExitModal from "../Modals/ExitModal.vue";
export default {
    components:{ExitModal  },
  name: 'AuthenticationNavebar',
  data:function() {
    return {
      isActive:false,
      isModalVisible: false,
      activeHam:false,
          hideHam:true,
          autheHam:false,
          DauthenHam:true,
          authenHam:false,
          showModal:false
    }
  },
     methods: {
    detailProfile: function() {
      this.isActive = !this.isActive;
    },
    // showModal() {
    //     this.isModalVisible = true;
    //   },
      closeModal() {
        this.isModalVisible = false;
      },
      showSidebar() {
          if (this.DauthenHam) {
              this.DauthenHam=false
              this.authenHam=true
          }
          else  {
              this.DauthenHam=true
              this.authenHam=false
          }
      }
}
}
</script>

<style scoped>
.ham-icon {
    display: none;
}
.ham-button-authen {
    width: 100px;
    background-color: black;
    color: white;
}
.authen-ham {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.d-authen-ham {
    display: none;
}
#profileItem-button {
    background-color: white;
    border: 0;
}
.show {
    display: none;
}
.profileItem {
    position: absolute;
    top: 60px;
    left: 60px;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    z-index: 90;
}
#container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    position: relative
}
    #porofile-navbar {
        text-align: center;
        width: 100%;
        height: 50px;
        background: #FFFFFF;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content:center;
        z-index: 100;
    }
    #settle , #remove {
        width: 120px;
        height: 34px;
        border-radius: 79px;
        border: 0;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-around;
        /* margin-left: 10px; */
        font-weight: bold;
    }
    #buttons {
        display: flex;
        column-gap: 15px;
        align-items: center;
    }
    #settle {
        background: #058C2B;
    }
    #remove {
        background: #E0342B;
    }
    #name-bell {
        display: flex;
        align-items: center;
        column-gap: 15px;
    }
    #name , #selector {
        width: 173px;
        height: 34px;
        background: #00ACC1;
        border-radius: 79px;
        display: flex;
        justify-items: center;
        color: white;
        font-size: 14px;
        align-items: center;
        justify-content: space-around;
        /* margin-right: 10px; */
        font-weight: bold;
    }
    .image-bell {
        width: 27px;
        height: 34px;
    }
    #selector {
        background-color: #00ACC1;
        border: 0;
    }
    #profileItem-p {
        border-bottom: 1px solid black;
        display: flex;
        justify-content: center;
        width: 150px;
        margin: 0;
    padding: 10px;
    justify-content: flex-start;
    }
    .image-bell-link {
        position: relative;
    }
    .image-bell-link > span {
        position: absolute;
        background: red;
        border-radius: 50%;
        color: white;
        right: 0;
        font-size: 12px;
        font-weight: bold;
        min-width: 15px;
    }
    @media only screen and (max-width: 900px)  {
        #logo-authen , #buttons {
            display: none;
        }
        .ham-icon {
            display: flex;
        }
    }
</style>