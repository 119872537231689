<template>
<div>
    <div id="profile-navbar">
        <ProfileNavbar/>
        <!-- <button class="menue" @click="showSidebar"></button> -->
        <!-- <Authentication/> -->
        <!-- <div class="mainProfile-container"> -->
            <div class="mainProfile-container">
        <Sidebar class="sidebar"/>
         <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
        <div class="depositRial-container">
            <div class="depositRial-title">
                <div class="depositRial-title-container">
                    <div>
                        <img src="@/assets/rialWallet.png" alt="">
                    </div>
                    <div>
                        <p>واریز ریالی</p>
                    </div>
                </div>
            </div>
            <div class="depositRial-form">
                <div class="depositRial-form-text">
                    <p>
                        جهت افزایش اعتبار کیف پول ریالی خود با  استفاده از کارت‌های بانکی عضو شبکه شتاب و از طریق درگاه پرداخت، حتما باید از کارت‌های بانکی به نام خودتان که در پروفایل‌تان ثبت و تایید شده  است، استفاده نمایید
                    </p>
                </div>
                <div class="depositRial-form-inputs">
                    <label for="">
                        <input type="text">
                        <span> مبلغ واریزی <span class="unit">(تومان)</span></span>
                        <p>یک میلیارد و سیصد میلیون تومان</p>
                    </label>
                    <label for="">
                        <select name="" id="">
                            <option value=""></option>
                        </select>
                        <span>انتخاب کارت</span>
                    </label>
                    <button>
                        انتقال به درگاه پرداخت
                    </button>
                </div>
            </div>
            <div class="depositRial-history">
                <div>
                     تاریخچه ی واریز ریالی
                </div>
                <perfect-scrollbar>
                <div class="history-deposit">
                    <table class="history-deposit-table">
                        <thead>
                            <tr>
                                <td>نوع پرداخت</td>
                                <td>مقدار واریز (تومان)</td>
                                <td class="transaction-id">شناسه پرداخت</td>
                                <td class="date-td">تاریخ درخواست</td>
                                <td>وضعیت</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>درگاه پرداخت</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="date-td">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td>درگاه پرداخت</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="date-td">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td>درگاه پرداخت</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="date-td">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td>درگاه پرداخت</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="date-td">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td>درگاه پرداخت</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="date-td">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td>درگاه پرداخت</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="date-td">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td>درگاه پرداخت</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="date-td">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                </perfect-scrollbar>
            </div>
        </div>
<!-- </div> -->
</div>
    </div>
    
</div>
</template>
<script>

// import Authentication from './Authentication.vue'
import ProfileNavbar from "./ProfileNavbar.vue"
import Sidebar from './Sidebar.vue'

export default {
  components: { ProfileNavbar, Sidebar },
  name: 'MainProfile',
  data : function() {
      return {
          showBuy:false,
          sidebarResponsive:false
      }
  },
  methods : {
      showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
  }
}
</script>

<style scoped>
.mainProfile-container {
    width: 100%;
    display: flex;
    margin: 0 10px;
    column-gap: 10px;
    height:calc(100vh - 70px);
    max-width: 1440px;
}
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;
    }
    .depositRial-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        height: 98%;
        /* padding: 0 10px; */
        /* overflow: scroll;
         -ms-overflow-style: none;
        scrollbar-width: none; */
    }
    /* .depositRial-container::-webkit-scrollbar {
        display: none;
    } */
    .depositRial-title , .depositRial-form , .depositRial-history {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        /* width: 100%; */
        display: flex;
    }
    .depositRial-title {
        min-height: 70px;
        align-items: center;
        column-gap: 20px;
        font-weight: bold;
        font-size: 20px;
    }
    .depositRial-title-container {
        width: 95%;
        margin: auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 10px;
    }
    .depositRial-form {
        flex-direction: column;
        min-height: 200px;
        justify-content: space-around;
        row-gap: 20px;
        padding: 20px;
    }
    .depositRial-form-text > p {
        font-weight: 600;
        font-size: clamp(12px , 1vw , 16px);
    }
    .depositRial-form-inputs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        row-gap: 40px;
        column-gap: 100px;
    }
    .depositRial-form-inputs >label {
        position: relative;
        display: flex;
        min-width: 250px;
        flex-grow: 1;
    }
    .depositRial-form-inputs >label > input {
        border: 1.5px solid #00ACC1;
        box-sizing: border-box;
        /* width: 321px; */
        width: 100%;
        height: 35px;
    }
    .depositRial-form-inputs >label > select {
        border: 1.5px solid #00ACC1;
        box-sizing: border-box;
        border-radius: 2px;
        /* width: 321px; */
        width: 100%;
        height: 35px;
    }
    .depositRial-form-inputs >label > span {
        font-weight: bold;
        font-size: 14px;
        position: absolute;
        right: 10px;
        top: -13px;
        background: white;
        padding: 0 5px;
    }
    .depositRial-form-inputs >label > p {
        position: absolute;
        bottom: -15px;
        margin: 0;
        font-weight: normal;
        font-size: 12px;
    }
    .depositRial-form-inputs > button {
        background: #058C2B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        width: 250px;
        height: 40px;
    }
    .depositRial-history {
        /* min-height: 490px; */
        flex-direction: column;
        row-gap: 20px;
        flex-grow: 1;
        height: 30vh;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .depositRial-history::-webkit-scrollbar {
        display: none;
    }
    .depositRial-history > div:first-of-type {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        padding: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    .history-deposit {
        overflow: scroll;
        -ms-overflow-style: none; 
        scrollbar-width: none;
    }
    .history-deposit::-webkit-scrollbar {
        display: none;
    }
    .history-deposit-table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
        /* min-width: 600px; */
    }
    .history-deposit-table > thead > tr {
        border-bottom: 1px solid black;
        font-weight: bold;
font-size: 14px;
color: rgba(15, 15, 15, 0.6);
    }
    .history-deposit-table > tbody > tr {
        border-bottom: 1px solid gainsboro;
        font-weight: bold;
        font-size: 14px;
        height: 40px;
    }
    .deposit-amount {
        color: #058C2B;
    }
    .unit {
        font-weight: normal;
        font-size: 12px;
        color: #0F0F0F;
    }
    @media only screen and (max-width:1000px) {
        .mainProfile-container {
            height: unset;
        }
        .transaction-id , .date-td {
            display: none;
        }
    }
</style>
