<template>
    <div id="faq">
      <Navbar/>
        <div id="container" class="faq-container">
          <p class="title">سوالات متداول</p>
            <div class="questions-box" v-for="questions , index in questions" :key="index">
              <div class="question" @click="check(index)" >{{questions.question}}<button id="toggle-image"><img src="@/assets/toggle (2).png" v-bind:class="{rotate: questions.active}"></button></div>
              <div id="answer" :class="{active: !questions.active}">
                <p class="answer">{{questions.answer}}</p>
              </div>
            </div>
            <div id="ask-question"><p> جواب خود را پیدا نکردید ؟ </p><button id="ask-us">از ما بپرسید</button></div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Footer from './firstPage/Footer.vue'
import Navbar from './Navbar.vue'
export default {
  components: {Footer ,Navbar },
  name: 'FAQ',
  data:function() {
    return {
      isActive:false,
      questions : [
        {
          question:'چرا تایید هویت لازم است؟',
          answer:'بر اساس قوانین موجود در بازارهای مالی دنیا  و نیز حمایت از قوانین مرتبط با پولشویی، احراز هویت مشتریان به سبب جلوگیری از هرگونه سوء استفاده احتمالی الزامی است. احراز هویت به افرادی که در سایت والکس ثبتنام می‌کنند کمک می‌کند تا با امنیت بیشتر و خیال راحت تری به معامله با افراد داخل پلتفرم بپردازند. موارد محدودی در این زمینه برای شروع به کار با سایت والکس در نظر گرفته شده است',
          active:false
        },
        {
          question:'چگونه احراز هویت کنیم؟',
          answer:'بر اساس قوانین موجود در بازارهای مالی دنیا  و نیز حمایت از قوانین مرتبط با پولشویی، احراز هویت مشتریان به سبب جلوگیری از هرگونه سوء استفاده احتمالی الزامی است. احراز هویت به افرادی که در سایت والکس ثبتنام می‌کنند کمک می‌کند تا با امنیت بیشتر و خیال راحت تری به معامله با افراد داخل پلتفرم بپردازند. موارد محدودی در این زمینه برای شروع به کار با سایت والکس در نظر گرفته شده است',
          active:false
        },
        {
          question:'ارز دیجیتال چیست؟',
          answer:'بر اساس قوانین موجود در بازارهای مالی دنیا  و نیز حمایت از قوانین مرتبط با پولشویی، احراز هویت مشتریان به سبب جلوگیری از هرگونه سوء استفاده احتمالی الزامی است. احراز هویت به افرادی که در سایت والکس ثبتنام می‌کنند کمک می‌کند تا با امنیت بیشتر و خیال راحت تری به معامله با افراد داخل پلتفرم بپردازند. موارد محدودی در این زمینه برای شروع به کار با سایت والکس در نظر گرفته شده است',
          active:false
        },
        {
          question:'والت سخت افزاری چیست؟',
          answer:'بر اساس قوانین موجود در بازارهای مالی دنیا  و نیز حمایت از قوانین مرتبط با پولشویی، احراز هویت مشتریان به سبب جلوگیری از هرگونه سوء استفاده احتمالی الزامی است. احراز هویت به افرادی که در سایت والکس ثبتنام می‌کنند کمک می‌کند تا با امنیت بیشتر و خیال راحت تری به معامله با افراد داخل پلتفرم بپردازند. موارد محدودی در این زمینه برای شروع به کار با سایت والکس در نظر گرفته شده است',
          active:false
        },
        {
          question:'نحوه ثبت سفارش خریدوفروش در باینکس چگونه است؟',
          answer:'بر اساس قوانین موجود در بازارهای مالی دنیا  و نیز حمایت از قوانین مرتبط با پولشویی، احراز هویت مشتریان به سبب جلوگیری از هرگونه سوء استفاده احتمالی الزامی است. احراز هویت به افرادی که در سایت والکس ثبتنام می‌کنند کمک می‌کند تا با امنیت بیشتر و خیال راحت تری به معامله با افراد داخل پلتفرم بپردازند. موارد محدودی در این زمینه برای شروع به کار با سایت والکس در نظر گرفته شده است',
          active:false
        },
        {
          question:'چگونه از حساب ریالی خود برداشت کنم ؟',
          answer:'بر اساس قوانین موجود در بازارهای مالی دنیا  و نیز حمایت از قوانین مرتبط با پولشویی، احراز هویت مشتریان به سبب جلوگیری از هرگونه سوء استفاده احتمالی الزامی است. احراز هویت به افرادی که در سایت والکس ثبتنام می‌کنند کمک می‌کند تا با امنیت بیشتر و خیال راحت تری به معامله با افراد داخل پلتفرم بپردازند. موارد محدودی در این زمینه برای شروع به کار با سایت والکس در نظر گرفته شده است',
          active:false
        }
      ]
    }
  },
     methods: {
    check(index) {
      this.questions[index].active=!this.questions[index].active
    }
}
}


</script>

<style>
  #faq {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
    .rotate {
      transform: rotate(270deg);
    }
    .active {
      display:none;
    }
    .faq-container {
      display: flex;
      flex-direction: column;
      max-width: 1284px;
    row-gap: 10px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    cursor: pointer;
    }
    .questions-box {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
      border-radius: 25px;
      display: grid;
      align-content: center;
      background-color: white;
    }
    #questions , #answer {
      font-weight: 700;
      font-size: 16px;
    }
    #questions {
      display: flex;
      justify-content: space-between;
      padding: 30px;
    }
    .title {
      font-size: 20px;
      font-weight: bold;
      margin: 30px 0;
    }
    .question {
      display: flex;
      justify-content: space-between;
      font-weight: 700;
      font-size: 16px;
      font-size: clamp(12px , 1.5vw , 16px);
      min-height: 60px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 20px;
    }
    /* #answer {
      padding: 20px;
    } */
    #toggle-image {
      background-color: white;
      border: 0;
    }
    #ask-question {
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 25px;
    display: flex;
    justify-self: center;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 50px;
    font-weight: bold;
    font-size: 16px;
    padding: 5px;
    max-width: 419px;
    margin: auto;
    column-gap: 25px;
    min-height: 60px;
    padding: 5px 25px;
    margin: 20px auto;
    }
    #ask-us {
      width: 172px;
      height: 45px;
      background: #00ACC1;
      border-radius: 35px;
      border: 0;
      color: white;
    }
    .answer {
      font-size: 14px;
      font-size: clamp(12px , 1.5vw , 14px);
      margin: 0 55px 40px 50px;
    }
</style>
