<template>
    <div class="orders-list">
                <div class="active-orders">
                    <div>
                        سفارشات فعال من
                    </div>
                    <perfect-scrollbar>
                    <div>
                        
                        <table class="active-orders-table">
                            
                        <tr>
                            <th class="date-td">زمان</th>
                            <th>بازار</th>
                            <th class="price-td">قیمت واحد</th>
                            <th>مقدار</th>
                            <th>قیمت کل</th>
                            <th class="statuse-td">انجام شده</th>
                            <th>لغو</th>
                        </tr>
                        <tr v-for="(ActiveOrders , index) in ActiveOrdersList" :key=index >
                            <td class="date-td">
                                {{ActiveOrders.date}}
                            </td>
                            <td>
                                {{ActiveOrders.market}}
                            </td>
                            <td class="price-td">
                                {{ActiveOrders.price.toLocaleString()}}
                            </td>
                            <td>
                                {{ActiveOrders.amount.toLocaleString()}}
                            </td>
                            <td :class="{'buy-amount':ActiveOrders.deal==='buy' , 'sell-amount':ActiveOrders.deal==='sell'}">
                                {{ActiveOrders.totalPrice.toLocaleString()}}
                            </td>
                            <td class="statuse-td">
                                {{ActiveOrders.done}}%
                            </td>
                            <td >
                                <img src="@/assets/trash.png" alt="" @click="deleteR(row)">
                            </td>
                        </tr>
                        
                    </table>
                    
                    </div>
                    </perfect-scrollbar>
                </div>
                <div class="history-orders">
                     <div>
                        تاریخچه سفارشات من
                    </div>
                    <perfect-scrollbar>
                    <div>
                        <table class="active-orders-table">
                        <tr>
                            <th class="id-td">شناسه سفارش</th>
                            <th class="date-td">زمان</th>
                            <th> بازار</th>
                            <th class="price-td">قیمت واحد</th>
                            <th>مقدار</th>
                            <th>قیمت کل</th>
                            <th>وضعیت</th>
                        </tr>
                        <tr v-for="(HistoryOrders , index) in HistoryOrdersList" :key="index">
                            <td class="id-td">
                                {{HistoryOrders.OrderId}}
                            </td>
                            <td class="date-td">
                                {{HistoryOrders.date}}
                            </td>
                            <td>
                                {{HistoryOrders.market}}
                            </td>
                            <td class="price-td">
                                {{HistoryOrders.price.toLocaleString()}}
                            </td>
                            <td>
                                {{HistoryOrders.price.toLocaleString()}}
                            </td>
                            <td :class="{'buy-amount':HistoryOrders.deal==='buy' , 'sell-amount':HistoryOrders.deal==='sell'}">
                                {{HistoryOrders.totalPrice.toLocaleString()}}
                            </td>
                            <td>
                                <img v-if="HistoryOrders.statuse==='done'" src="@/assets/tick-green.png" alt="">
                                <img v-if="HistoryOrders.statuse==='canceled'" src="@/assets/tick-red.png" alt="">
                            </td>
                        </tr>
                       
                    </table>
                    </div>
                    </perfect-scrollbar>
                </div>
            </div>
</template>

<script>
import ActiveOrders from "../../library/ActiveOrders.js"
import HistoryOrders from "../../library/HistoryOrders.js"
export default {
  name: 'HomePage',
  components :{
  },
  data() {
      return {
          ActiveOrdersList:ActiveOrders,
          HistoryOrdersList:HistoryOrders
      }
  },
  }
</script>

<style scoped>

    .orders-list {
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        /* justify-content: space-between; */
        align-items: top;
        flex-grow: 4;
        /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); */
        /* min-width: 650px; */
        /* min-width: 55%; */
        /* overflow-x: scroll; */
        row-gap: 10px;
        width: 45%;
        height: 98%;
    }
    .active-orders , .history-orders {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        /* padding: 10px; */
        overflow: scroll;
        -ms-overflow-style: none; 
        scrollbar-width: none;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        /* row-gap: 20px; */
    }
    .active-orders {
        height: 302px;
    }
    .active-orders > div , .history-orders > div {
        padding: 10px;
    }
    .active-orders > div:first-of-type , .history-orders > div:first-of-type {
        font-weight: bold;
        font-size: 14px;
    }
    /* .active-orders {
        height: 290px;
    } */
    .history-orders {
        height: 350px;
    }
    .active-orders::-webkit-scrollbar {
        display: none;
    }
    .history-orders::-webkit-scrollbar {
        display: none;
    }
    .active-orders-table {
        width: 100%;
        border-collapse: collapse;
        /* min-width: 600px; */
        text-align: center;
    }
    .active-orders-table > tr {
        border-bottom: 1px solid gainsboro;
        height: 45px;
    }
    .active-orders-table > tr > th {
        color: rgba(15, 15, 15, 0.6);
        font-size: clamp(12px , 1vw , 14px);
        /* font-size: 1vw; */
    }
    .active-orders-table > tr > td {
        font-weight: bold;
        /* font-size: 14px; */
        /* font-size: 1vw; */
        font-size: clamp(8px , 0.8vw , 14px);
    }
    .orders-list {
        /* height: 100%; */
        border-radius: 2px;
        
    }
    @media only screen and (max-width:1000px) {
    .currency-information {
        flex-wrap: wrap;
    }
    .active-orders-table {
        min-width: 600px;
    }
    .active-orders-table > tr > th , .active-orders-table > tr > td {
        font-size: 14px;
    }
    .orders-list {
        height: unset;
    }
}
 @media only screen and (max-width:600px) {
        .currency-detail-title {
            column-gap: 50px;
        }
    }
</style>
<style >
    .scroll {
    width: 100%;
}
</style>
