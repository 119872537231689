<template>
<div>
                <div class="filter-history-row">
                    <div class="history-sell-buy">
                        <button class="history-buysSells " v-on:click="beActiveCurrBuys" :class="{'deActive-buys':deActiveCurrBuys , 'active-buys':activeCurrBuys}" >
                            واریز
                        </button>
                        <button class="history-buysSells " v-on:click="beActiveCurrSells" :class="{'deActive-sells':activeCurrBuys , 'active-sells':deActiveCurrBuys}"  >
                            برداشت
                        </button>
                    </div>
                    <!-- v-on:click="showMarkets" -->
                    <button class="history-markets" @click="showMarkets=!showMarkets">
                       همه  <img src="@/assets/selector-black.png"/>
                    </button>
                </div>
                
                <transition name="fade">
                    
                    <div v-if="showMarkets" class="showCurrencyMarkets">
                        <perfect-scrollbar>
                        <div class="markets-container-child">
                        <div v-for="(currency , index) in currencyList" :key="index" class="markets-container">
                            <img :src=currency.image alt="" class="markets-image">
                            <p>{{currency.name}}</p>
                        </div>
                        </div>
                         </perfect-scrollbar>
                    </div>
                    
                </transition>
               
                <table class="historyTrades-table">
                    
                        <tr>
                            <td>بازار</td>
                            <td>مقدار</td>
                            <td class="price-td">قیمت واحد </td>
                            <td >قیمت کل</td>
                            <td class="commition-td">کارمزد</td>
                        </tr>
                        <tr>
                            <td>اتریوم</td>
                            <td>0.7,532,144 ET</td>
                            <td class="price-td">6,464,654,654,654 تومان</td>
                            <td>6,464,654,654,654 تومان</td>
                            <td class="commition-td"><img src="@/assets/tick-green.png" alt=""></td>
                        </tr>
                        <tr>
                            <td>اتریوم</td>
                            <td>0.7,532,144 ET</td>
                            <td class="price-td">6,464,654,654,654 تومان</td>
                            <td>6,464,654,654,654 تومان</td>
                            <td class="commition-td"><img src="@/assets/tick-green.png" alt=""></td>
                        </tr>
                        <tr>
                            <td>اتریوم</td>
                            <td>0.7,532,144 ET</td>
                            <td class="price-td">6,464,654,654,654 تومان</td>
                            <td>6,464,654,654,654 تومان</td>
                            <td class="commition-td"><img src="@/assets/tick-green.png" alt=""></td>
                        </tr>

                        <tr>
                            <td>اتریوم</td>
                            <td>0.7,532,144 ET</td>
                            <td class="price-td">6,464,654,654,654 تومان</td>
                            <td>6,464,654,654,654 تومان</td>
                            <td class="commition-td"><img src="@/assets/tick-green.png" alt=""></td>
                        </tr>
                        <tr>
                            <td>اتریوم</td>
                            <td>0.7,532,144 ET</td>
                            <td class="price-td">6,464,654,654,654 تومان</td>
                            <td>6,464,654,654,654 تومان</td>
                            <td class="commition-td"><img src="@/assets/tick-green.png" alt=""></td>
                        </tr>
                        <tr>
                            <td>اتریوم</td>
                            <td>0.7,532,144 ET</td>
                            <td class="price-td">6,464,654,654,654 تومان</td>
                            <td>6,464,654,654,654 تومان</td>
                            <td class="commition-td"><img src="@/assets/tick-green.png" alt=""></td>
                        </tr>
                        <tr>
                            <td>اتریوم</td>
                            <td>0.7,532,144 ET</td>
                            <td class="price-td">6,464,654,654,654 تومان</td>
                            <td>6,464,654,654,654 تومان</td>
                            <td class="commition-td"><img src="@/assets/tick-green.png" alt=""></td>
                        </tr>
                    
                </table>
                
</div>
</template>
<script >
import Market from "../../library/Market.js"
import Vue2ClickOutside from 'vue2-click-outside'
export default {
  components: {},
  name: 'historyCurrency',
  data:function() {
      return {
          deActiveCurrBuys:false,
          activeCurrBuys:true,
          hideMarkets:true,
          currencyList:Market,
          showMarkets:false
      }
  },
  directives: {
      clickOutside: Vue2ClickOutside.directive
    },
  methods : {
      beActiveCurrBuys() {
          if (this.deActiveCurrBuys) {
              this.deActiveCurrBuys=false
              this.activeCurrBuys=true
          }
          else 
              return true
      },
      beActiveCurrSells () {
          if (this.activeCurrBuys) {
              this.deActiveCurrBuys=true
              this.activeCurrBuys=false
          }
          else 
              return true
      },
    //   showMarkets() {
    //       this.hideMarkets=!this.hideMarkets
    //   },
      onclickOutSide() {
          this.hideMarkets=true
      }
  },
  created:function() {
      window.addEventListener("click",(event) => {
          if(!event.target.closest('.history-markets')) {
              this.showMarkets=false
          }
      })
  }
}
</script>

<style >
.deActive-sells , .deActive-buys {
    background: white;
}
.historyTrades-table {
    width: 100%;
    border-collapse: collapse;
}
.historyTrades-table > tr {
    border-bottom: 1px solid #a5a5a5;
    height: 50px;
}
.describe-row-list {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.history-items {
    display: flex;
    flex-direction: row;
   
}
 .active-sells {
    /* background-color: #058C2B; */
    color: white;
}
.active-buys {
    /* background-color: red; */
    color: white;
}
 
.history-item {
    /* padding: 0 15px; */
    background-color: rgba(243, 243, 243 , 0.1);
    /* background: rgba(15, 15, 15, 0.1); */
     /* height: 35px; */
     width: 110px;
     display: flex;
    justify-content: center;
    align-items: center;
}
.active {
    background: white;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 35%);
}
.describe-history-items {
    display: flex;
    flex-grow: 1;
    height: 600px;
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); */
    border-radius: 2px;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
    background: white;
}
.history-items-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    row-gap: 30px;
}
.currencyMarkets {
    display: none;
}
.showCurrencyMarkets {
    position: absolute;
    width: 183px;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    /* row-gap: 10px; */
    padding: 5px 10px;
    font-weight: bold;
font-size: 14px;
z-index: 10;
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
border-radius: 2px;
left: 10px;
height: 214px;
overflow: scroll;
-ms-overflow-style: none;
scrollbar-width: none;
row-gap: 5px;
}
.showCurrencyMarkets::-webkit-scrollbar  {
    display: none;
}
.showCurrencyMarkets .markets-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
    width: 100%;
    border-bottom: 1px solid gainsboro;
    height: 35px;
    align-items: center;
    cursor: pointer;
}
/* .markets-container-child {
    padding: 0 10px;
} */
.showCurrencyMarkets > div > p {
    margin: 0;
}
.history-markets {
    width: 120px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 25px;
    background: transparent;
    column-gap: 5px;
}
.filter-history-row {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    margin-top: 20px;
}
.history-sell-buy {
    width: 200px;
    height: 39px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 25px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.history-buysSells{
    width: 100px;
    height: 35px;
    /* color: white; */
    border-radius: 25px;
    /* background-color: #E0342B; */
    /* background: transparent; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.history-sells {
    width: 100px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: transparent; */
}

.describe-row-header {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
}
    .wallet-header {
        font-weight: bold;
        font-size: 20px;
    }
    .wallet-title-p , .amount-p {
        font-weight: bold;
        font-size: 16px;
    }
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;

    }
    .history-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        align-self: baseline;
    }
    .wallet-row {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        flex-wrap: wrap;
    }
    .wallet-title {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-grow: 1;
    }
    .wallet-amount {
        flex-grow: 1;
        justify-content: center;
        display: flex;
        min-width: 100px;
    }
    .wallet-options {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        gap: 5px;
    }
    .wallet-blue-button , .wallet-red-button , .wallet-green-button {
        width: 95px;
        height: 30px;
        color: white;
        border: 0;
        border-radius: 2px;
    }
    .wallet-blue-button {
        background-color: #00ACC1;
    }
    .wallet-red-button {
        background-color: #E0342B;
    }
    .wallet-green-button {
        background-color: #058C2B;
    }

    /* .sidebar {
            display: none;
        }
    @media only screen and (max-width: 900px) {
        .sidebar {
            display: none;
        }
    } */
    @media only screen and (max-width: 900px) {
        .commition-td , .price-td {
            display: none;
        }
    }
    @media only screen and (max-width: 900px) {
         .historyTrades-table {
             font-size: 12px;
         }
    }
   
    
</style>
<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  transition: height 0.7s ease-in;
  background: white;
  height: 214px;
}
  .fade-enter, .fade-leave-to {
  height: 0;
}
.active-sells {
    background-color: red;

}
.active-buys {
    
    background-color: #058C2B;
}
</style>