<template>
<!-- <div id="container"> -->
    <div id="Athutication">
        <!-- <AuthenticationNavbar/> -->
        <ProfileNavbar/>
        <!-- <button class="menue" @click="showSidebar">
             
        </button> -->
        <div id="authen-container">
            <Sidebar class="sidebar"/>
             <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
                <div id="authen-form1">
                    <div id="container" class="authen-container authentication-container">
                <div id="line1-athuntication">
                    <p id="line1-athuntication-title">احراز هویت</p>
                </div>
                
                <!-- <div id="line2-athuntication">
                    <div id="athutication-nav" class="done">اطلاعات فردی</div>
                    <div id="athutication-nav" class=""> اطلاعات بانکی</div>
                    <div id="athutication-nav" class="">آپلود متن دست نویس</div>
                    <div id="athutication-nav" class="">آپلود تصویر سلفی</div>
                </div> -->
                <!-- <div id="line2-athuntication">
                <div id="steps">
                    <div>
                        <div class="step1">اطلاعات فردی</div>
                        <div class="triangle"></div>
                    </div>
                    <div>
                        <div class="inner-triangle"></div>
                        <div class="step2">اطلاعات بانکی</div>
                        <div class="triangle"></div>
                    </div>
                    <div>
                        <div class="inner-triangle"></div>
                        <div class="step2">آپلود متن دست نویس</div>
                        <div class="triangle"></div>
                    </div>
                    <div>
                        <div class="inner-triangle"></div>
                        <div class="step4"> آپلود تصویر سلفی</div>
                    </div>
                    
                </div>
                </div> -->
                <div id="line2-athuntication">
                <AuthenProcess1/>
                <!-- <AccountLevels/> -->
                <!-- <AuthenProcess/> -->
                </div>
               
                <div id="line3-athuntication">
                    
                        <div id="line3-title">
                            <p id="line3-title-p">اطلاعات فردی</p>
                        </div>
                    <perfect-scrollbar>
                        <div id="line3-form">
                            <div class="line3-form-group-container">
                        <div id="lin3-form-group">
                        <label class="input">
                        <input class="input__field" type="text" />
                        <span class="input__label">نام</span>
                        </label>
                        <label class="input">
                        <input class="input__field" type="text" />
                        <span class="input__label">  کد ملی</span>
                        </label>
                        <label class="input">
                        <input class="input__field" type="text" />
                        <span class="input__label"> تاریخ تولد </span>
                        </label>
                        </div>
                        <div id="lin3-form-group">
                        <label class="input">
                        <input class="input__field" type="text" />
                        <span class="input__label">  نام خانوادگی</span>
                        </label>
                        <label class="input">
                        <input class="input__field" type="text" />
                        <span class="input__label">  شماره موبایل</span>
                        </label>
                        <label class="input">
                        <input class="input__field" type="text" />
                        <span class="input__label">  آدرس ایمیل</span>
                        </label>
                        </div>
                        <div id="lin3-form-group">
                        <label class="input">
                        <input class="input__field" type="text" />
                        <span class="input__label">  نام پدر</span>
                        </label>
                        <label class="input">
                        <input class="input__field" type="text" />
                        <span class="input__label">  کد پستی  </span>
                        </label>
                        </div>
                    </div>
                    <label class="input">
                    <textarea rows="3" class="input__field address" type="text" />
                    <span class="input__label">  آدرس</span>
                    </label>
                    <div id="line3-buttons">
                    <button id="pre">مرحله قبل </button>
                    <router-link to="/mobile-confirm"><button id="next">مرحله بعد</button></router-link>
                </div>
                
                </div>
                </perfect-scrollbar>
                    <!-- <label class="input">
                    <textarea rows="3" class="input__field address" type="text" />
                    <span class="input__label">  آدرس</span>
                    </label> -->
                <!-- <div id="line3-buttons">
                    <button id="pre">مرحله قبل </button>
                    <router-link to="/mobile-confirm"><button id="next">مرحله بعد</button></router-link>
                </div> -->
                </div>
                
                </div>
                </div>
        </div>
    </div>
    <!-- </div> -->
</template>

<script>

import AuthenProcess1 from './AuthenProcess1.vue'
import ProfileNavbar from './ProfileNavbar.vue'
// import AuthenProcess from './AuthenProcess.vue'
// import AuthenticationNavbar from "./AuthenticationNavbar.vue"
import Sidebar from './Sidebar.vue'
export default {
    components:{  Sidebar, AuthenProcess1, ProfileNavbar,   },
  name: 'Authentication',
  data : function () {
      return {
          sidebarResponsive:false
      }
  },
  methods : {
       showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
  }
}
</script>

<style scoped>
    
    .authentication-container {
        /* display: flex; */
        /* row-gap: 30px; */
        display: flex;
        flex-direction: column;
        height: 100%;
        /* width: 100%; */
    }
    
    #authen-form1 {
        /* display: grid; */
        justify-items: center;
        width: 100%;
        /* margin-top: 20px; */
        height: 98%;
        /* overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none; */
        padding: 0 10px;
    }
    /* #authen-form1::-webkit-scrollbar {
        display: none;
    } */
    
    #line2-athuntication {
        /* display: flex; */
        justify-items: right;
        justify-content: center;
        width: 100%;
        height: 120px;
        align-items: center;
        /* height: -webkit-fill-available; */
    }
    #line1-athuntication-title {
        font-weight: bold;
        font-size: 18px;
        text-align: right;
        margin: 0;
    }
    #athutication-nav {
        /* width: 360px; */
        height: 50px;
        border-radius: 25px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        position: relative;
    }
    .done {
        background: #00ACC1;
        color: white;
    }
    #line3-athuntication {
        /* width: 1273px; */
        /* height: 478px; */
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        /* display: grid; */
        padding: 0 20px 20px 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        height: 65vh;
        flex-grow: 1;
    }
    #line3-athuntication::-webkit-scrollbar {
        display: none;
    }
    #line3-form {
        display: grid;
        justify-content: normal;
        row-gap: 20px;
        display: grid;
    justify-content: normal;
    row-gap: 20px;
    padding: 0 10px;
    /* grid-template-columns: repeat(3,250px [col-start]); */
    /* grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto; */
    }
    #lin3-form-group {
        /* display: flex; */
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 50px;
        align-items: center;
        display: grid;
    grid-template-rows: max-content max-content max-content;
    grid-template-columns: 1fr;
    }
    .line3-form-group-container {
        display: grid;
        justify-content: normal;
        row-gap: 20px;
        display: grid;
    justify-content: normal;
    row-gap: 20px;
    /* grid-template-columns: repeat(3,250px [col-start]); */
    /* grid-template-columns: repeat(auto-fill,minmax(270px,1fr)); */
    grid-template-columns: repeat(auto-fill , minmax(230px,28%));
    grid-template-rows:auto;
    column-gap: 7%;
    }
    #line3-buttons {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-between;
        align-items: center;
        row-gap: 10px;
    }
    #line3-title {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    #line3-title-p {
        font-weight: bold;
        font-size: 18px;
        padding: 5px;
        text-align: center;
        margin: 5px;
    }
    #pre {
        border: 2px solid #00ACC1;
        box-sizing: border-box;
        border-radius: 35px;
        width: 172px;
        font-weight: bold;
        font-size: 16px;
        color: #00ACC1;
        background-color: white;
        height: 45px;
    }
    #next {
        width: 172px;
        background: #058C2B;
        border-radius: 35px;
        font-weight: bold;
        font-size: 16px;
        color: white;
        height: 45px;
        border: 0;
    }
    textarea.address {
        width: 100%;
        height: 68px;
    }
    .input {
        display: flex;
        flex-grow: 1;
    }
    .input__label {
        font-weight: 600;
        font-size: 16px;
    }
    textarea {
        resize: none;
        /* height: 68px; */
    }
   
.triangle {
  width: 0px; 
  height: 0px; 
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent; 
  
  border-right:50px solid #00ACC1;  
  display:inline-block;
  box-sizing: border-box;
  margin:0;
  margin-left:-49px;
  background: transparent;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
 
}

.inner-triangle {
  width: 0px; 
  height: 0px; 
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent; 
  vertical-align: top;
  border-left:50px solid red;  
  transform: matrix(-1, 0, 0, 1, 0, 0);
  display:inline-block;
  box-sizing: border-box;
  margin:0;
  background: transparent;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  box-shadow: none;
}
@media only screen and (max-width: 900px){
    #line1-athuntication-title {
        text-align: center;
    }
}
</style>
<style >
@media screen and (max-width: 900px)  {
        .sidebar {
            display: none;
        }
}
#authen-container {
        display: flex;
        width: 100%;
        max-width: 1440px;
        direction: rtl;
        flex-direction: row;
        /* width: 100%; */
        margin: 0 10px;
        /* column-gap: 10px; */
        height: calc(100vh - 70px);
        padding: 0 10px;
        height: 100%;
    }
    #Athutication {
        justify-items: right;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        align-items: center;
        height: 100%;
    }
    #line2-athuntication {
        display: grid;
    grid-template-columns: minmax(300px,1fr);
    }
  /*#line2-athuntication {
    height: 100px;
    -ms-overflow-style: none;  
  scrollbar-width: none; 
  align-items: center;
  }
  #line2-athuntication::-webkit-scrollbar {
  display: none;
} */
@media only screen and (max-width: 600px) {
    /* #line2-athuntication {
        display: none !important;
    } */
}


</style>