<template>
<div>
    <Navbar/>
    <div id="terms">
        
        <div id="container">
            <p id="terms-title">قوانین و مقررات</p>
            <p id="terms-text"> حوزه معاملات آنلاین و پرداخت‌های مجازی، با هدف دسترسی ایمن و آسان مخاطبان به بازارهای جهانی، در سال ۲۰۱۷ فعالیت خود را آغاز کرد. خرید و فروش پی اس ووچر ، پرفکت مانی ، ووچر پرفکت مانی ، بیت کوین ، اتریوم ، ریپل ، ترون ، دوج کوین ، تتر ، لایت کوین و سایر رمز ارز ها قسمتی از خدمات قابل ارائه ما به شماست. تیم جوان و متخصص ما، مسئولیت خود را آشنایی شما با پرداخت‌های آنلاین و ایجاد فضایی امن برای معاملات می‌داند. هدف ما این است که با توضیح دقیق و مشاوره مفید در زمینه‌ی پرداخت‌های آنلاین و دیگر خدمات، تمامی جوانب را به شما نشان دهیم تا بتوانید با خیالی </p>
            <p id="terms-text"> حوزه معاملات آنلاین و پرداخت‌های مجازی، با هدف دسترسی ایمن و آسان مخاطبان به بازارهای جهانی، در سال ۲۰۱۷ فعالیت خود را آغاز کرد. خرید و فروش پی اس ووچر ، پرفکت مانی ، ووچر پرفکت مانی ، بیت کوین ، اتریوم ، ریپل ، ترون ، دوج کوین ، تتر ، لایت کوین و سایر رمز ارز ها قسمتی از خدمات قابل ارائه ما به شماست. تیم جوان و متخصص ما، مسئولیت خود را آشنایی شما با پرداخت‌های آنلاین و ایجاد فضایی امن برای معاملات می‌داند. هدف ما این است که با توضیح دقیق و مشاوره مفید در زمینه‌ی پرداخت‌های آنلاین و دیگر خدمات، تمامی جوانب را به شما نشان دهیم تا بتوانید با خیالی </p>
        </div>
        
    </div>
    <Footer/>
    </div>
</template>
<script>
import Footer from './firstPage/Footer.vue'
import Navbar from './Navbar.vue'


export default {
  components: {Navbar , Footer },
  name: 'Terms',
}
</script>

<style>
#terms {
    background-color: white;
    /* height: 600px; */
    min-height: 600px;
    width: 95%;
    margin:auto;
}
    #terms-title , #terms-text {
        text-align: right;
    }
    #terms-title {
        font-weight: bold;
        font-size: 20px;
    }
    #terms-text {
        font-weight: 500;
        font-size: 16px;
    }
    @media only screen and (max-width: 700px) {
        #terms {
            height: auto;
        }
    }
</style>
