<template>
<div>
    <div id="profile-navbar">
        <ProfileNavbar/>
        <!-- <button class="menue" @click="showSidebar"></button> -->
        <!-- <Authentication/> -->
        <!-- <div class="mainProfile-container"> -->
            <div class="mainProfile-container">
        <Sidebar class="sidebar"/>
        <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
        <div class="withdrawlBit-container">
            <div class="withdrawlBit-title">
                <div class="withdrawlBit-title-container">
                    <!-- <div> -->
                        <img src="@/assets/bitcoin.png" alt="">
                    <!-- </div> -->
                    <div>
                        <p>برداشت بیت کوین</p>
                    </div>
                </div>
                <!-- <div class="detail-title-chart">
                        <div class="title-chart-percent">
                            <p>0.45%+</p>
                        </div>
                        <div class="title-chart-price">
                            <p>172,500,254 تومان</p>
                        </div>
                    </div> -->
                    <div class="bit-amounts">
                        <div class="total-order">
                            <span>موجودی کل :</span> {{this.total.toLocaleString()}} <span> تومان </span>
                        </div> 
                        <div class="total-order">
                            <span>در سفارش:</span> {{this.order.toLocaleString()}} <span> تومان </span>
                        </div>
                        <div class="withdraw-p">
                            <span> قابل برداشت:</span> {{this.withdraw.toLocaleString()}} <span> تومان </span>
                        </div>
                    </div>
            </div>
            <div class="withdrawlBit-net">
                    <div class="net-title">
                        شبکه :
                    </div>
                    <div class="withdrawlBit-net-container">
                        <div class="withdrawlBit-net-detail">
                            
                            <div class="withdrawlBit-net-address">
                                <div>
                                    <div class="net-code">ERC-20</div>
                                    <p class="withdrawlBit-net-address-title">لطفا توجه فرمایید که کیف پول مبدا، شبکه ی  انتخابی شما را پشتیبانی کند</p>
                                </div>
                                <div>
                                    <label for="">
                                        <input type="text">
                                        <span>آدرس کیف پول مقصد</span>
                                        
                                    </label>
                                    <p class="input-footer">دقت کنید که وارد کردن آدرس اشتباه ممکن است باعث از دست رفتن دارایی شما شود.</p>
                                </div>
                                <div>
                                    <label for="">
                                        <input type="text">
                                        <span> میزان برداشت <span class="coin-btc">(BTC)</span> </span>
                                        
                                    </label>
                                    <p class="input-footer">موجودی قابل برداشت شما: 0.07,030,108 ETH</p>
                                </div>
                                <div class="income-fees">
                                    <div class="income-fees-title">
                                        <div>
                                            دریافتی شما: ۴۷,۸۷۵۴
                                        </div>
                                        <div>
                                            کارمزد شبکه: ۴۷,۸۷۵۴
                                        </div>
                                    </div>
                                    <div class="income-fees-footer">
                                        توجه داشته باشید که کارمزد شبکه، مربوط به تراکنش در شبکه ی بیت کوین است.
                                    </div>
                                </div>
                                <div>
                                    <label for="">
                                        <input type="text">
                                        <span>کد تایید</span>
                                        <button class="code-request">درخواست کد</button>
                                    </label>
                                </div>
                                <div class="code-input-container">
                                    <div class="security-code">
                                    <label for="">
                                        <input type="text">
                                        <span>کد امنیتی</span>
                                        
                                    </label>
                                    </div>
                                    <div class="code-img-container">
                                        <img src="@/assets/OIP1.png" alt="">
                                    </div>
                                    
                                </div>
                                
                                <div>
                                    <button class="withdrawlBit-btn">برداشت</button>
                                </div>
                            </div>
                        </div>
                        <div class="withdrawlBit-net-text">
                            <p>
                                در صورت تمایل به برداشت موجودی کیف  پول‌های خود، درخواست خود را اینجا ثبت نمایید
                            </p>
                            <p>
                                 درخواست‌ها به صورت دستی تایید و سپس پرداخت خواهند شد کارمزدهای معمول انتقال از مبلغ برداشت کسر خواهد شد.
                            </p>
                            <p>
                                 درخواست های تسویه حساب کوین در سریع ترین زمان ممکن معمولا ظرف حدود نیم ساعت انجام خواهد گرفت. */
                            </p>
                            <p>
                                در صورت تمایل به برداشت موجودی کیف پول‌های خود، درخواست خود را اینجا ثبت نمایید. درخواست‌ها به صورت دستی تایید و سپس پرداخت خواهند شد
                            </p>
                        </div>
                    </div>
            </div>
            <div class="withdrawlBit-history">
                <div>
                     تاریخچه ی برداشت بیت کوین
                </div>
                <div class="history-deposit">
                    <table class="history-deposit-table">
                        <thead>
                            <tr>
                                <td>نوع واریز</td>
                                <td>مقدار دزخواستی</td>
                                <td class="transaction-id">شناسه تراکنش</td>
                                <td class="request-date">تاریخ درخواست</td>
                                <td>وضعیت</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
<!-- </div> -->
</div>
    </div>
    
</div>
</template>
<script>

// import Authentication from './Authentication.vue'
import ProfileNavbar from "./ProfileNavbar.vue"
import Sidebar from './Sidebar.vue'

export default {
  components: { ProfileNavbar, Sidebar },
  name: 'MainProfile',
  data : function() {
      return {
          showBuy:false,
          sidebarResponsive:false,
          total:123456789,
          order:5456456,
          withdraw:21321321321,
      }
  },
  methods : {
      showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
  }
}
</script>

<style scoped>
.mainProfile-container {
    width: 95%;
    display: flex;
    margin: auto;
    /* column-gap: 10px; */
    height: calc(100vh - 70px);
}
.code-img-container {
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;

    }
    .withdrawlBit-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding: 0 10px;
        /* overflow: scroll; */
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .withdrawlBit-container::-webkit-scrollbar {
        display: none;
    }
    .net-title {
        width: 95%;
    margin: auto;
    margin-top: 20px;
    font-weight: bold;
    font-size: 16px;
    }
    .bit-amounts {
        width: 70%;
        display: flex;
        justify-content: space-around;
        column-gap: 30px;
    }
    .income-fees {
        display: flex;
        flex-direction: column;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
    }
    .income-fees-title {
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        font-size: 14px;
        margin: 5px;
    }
    .income-fees-footer {
        font-weight: normal;
        font-size: 12px;
        margin: 5px;
    }
    .withdrawlBit-title , .withdrawlBit-net , .withdrawlBit-history {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        width: 100%;
        display: flex;
    }
    .withdrawlBit-title {
        min-height: 70px;
        align-items: center;
        column-gap: 20px;
        font-weight: bold;
        font-size: clamp(14px , 1.5vw , 20px);
        /* flex-wrap: wrap; */
    }
    .withdrawlBit-title-container {
        display: flex;
        width: 30%;
        margin: auto;
         align-items: center;
        column-gap: 20px;

    }
    .withdrawlBit-net {
        flex-direction: column;
        /* min-height: 473px; */
        /* justify-content: space-around; */
        flex-wrap: wrap;
    }
    .withdrawlBit-net-container {
        display: flex;
        /* flex-wrap: wrap; */
        width: 90%;
        /* margin: auto; */
        column-gap: 100px;
        margin: 5px auto;
    }
    .withdrawlBit-net-detail {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        text-align: center;
        width: 400px;
    max-width: 100%;
    }
    .net-code {
        width: 107px;
        height: 35px;
        background: #00ACC1;
        border: 1.25px solid #141292;
        box-sizing: border-box;
        border-radius: 2px;
        color: white;
        align-items: center;
        display: flex;
        justify-content: center;
        font-weight: bold;
        font-size: 16px;
    }
    .copy-address-withdrawlBit {
        background: #058C2B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        max-width: 300px;
        height: 40px;
        width: 100%;
    }
    .withdrawlBit-btn {
        width: 100%;
        height: 40px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        background: #E0342B;
        border-radius: 2px;
        max-width: 400px;
    }
    .withdrawlBit-net-text {
        font-weight: 600;
        font-size: 14px;
        width: 50%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .coin-btc {
        font-weight: normal;
font-size: 12px;
    }
    .withdrawlBit-history {
        min-height: 490px;
        flex-direction: column;
        row-gap: 20px;
    }
    .withdrawlBit-history > div:first-of-type {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        padding: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    .history-deposit-table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
    }
    .history-deposit-table > thead > tr {
        border-bottom: 1px solid black;
        font-weight: bold;
font-size: 14px;
color: rgba(15, 15, 15, 0.6);
    }
    .history-deposit-table > tbody > tr {
        border-bottom: 1px solid gainsboro;
        font-weight: bold;
        font-size: 14px;
        height: 40px;
    }
    .deposit-amount {
        color: #058C2B;
    }
    .code-request {
        width: 106px;
height: 35px;
background: #058C2B;
border-radius: 2px;
color: white;
    }
    .code-input-container {
        display: flex;
    align-items: center;
    flex-wrap: wrap;
    }
    .input-footer {
        font-weight: normal;
font-size: 12px;
text-align: right;
margin: 0;
    }
    .withdrawlBit-net-address-title {
        margin: 0;
        font-weight: 500;
font-size: 14px;
    }
    input {
        border: 2px solid #00ACC1;
        box-sizing: border-box;
        border-radius: 2px;
        width: 100%;
        height: 35px;
    }
    label {
        position: relative;
        width: 100%;
        display: flex;
        /* flex-wrap: wrap; */
        
    }
    label > span {
       font-weight: bold;
font-size: 14px;
position: absolute;
right: 10px;
top: -15px;
background: white;
padding: 0 5px;
    }
    .withdrawlBit-net-address {
        display: flex;
        flex-direction: column;
        row-gap: 25px;
    }
    .total-order {
        font-weight: 500;
        /* font-size: 16px; */
        font-size: clamp(12px , 1vw , 16px);
    }
    .withdraw-p {
        font-weight: bold;
        /* font-size: 16px; */
        font-size: clamp(12px , 1vw , 16px);
    }
    .security-code {
        flex-grow: 1;
    }
    @media screen and (max-width: 1000px) {
         .withdrawlBit-title {
            flex-wrap: wrap;
            justify-content: center;
        }
        .bit-amounts[data-v-40013512] {
        display: flex;
        justify-content: space-around;
        width: 100%;
        flex-wrap: wrap;
        row-gap: 40px;
        padding: 20px 0;
        }
        .withdrawlBit-net-container {
            flex-wrap: wrap;
        }
        .withdrawlBit-title-container {
            width: 100%;
        }
    }
    @media only screen and (max-width:1000px) {
        .mainProfile-container {
            height: unset;
        }
    }
    @media screen and (max-width: 600px) {
        .transaction-id , .request-date {
            display: none;
        }
        .code-request {
            font-size: 12px;
        }
        .withdrawlBit-title-container {
            flex-wrap: wrap;
            justify-content: center;
            padding: 10px 0;
        }
        .detail-title-chart {
            padding: 0 0 0 10px;
        }
       
    }
</style>
