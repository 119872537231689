<template>
<div>
    <div id="profile-navbar">
        <ProfileNavbar/>
         <!-- <button class="menue" @click="showSidebar">
             
        </button> -->
        <!-- <Authentication/> -->
            <div class="invitefriends-container">
                <Sidebar class="sidebar"/>
                <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
                <div class="inviteFriend-container">
            <div class="invite-friend-links">
                <p>هر فردی که با استفاده از کد دعوت و یا  لینک دعوت شما ثبت نام نماید 20% درصد از کارمزد ی که باینکس از هر معامله آن فرد دریافت می کند به شما تعلق می گیرد. هم چنین فرد دعوت شده می تواند دو معامله با کارمزد صفر انجام دهد و دو پله هم کارمزد معاملاتی شخص کاهش پیدا می کند</p>
                <div class="inviteFriend-inputs">
                    <div class="invite-link-input">
                        <label for="" class="invite-link-label">
                            <input type="text" :value=inviteLink>
                            <span>لینک دعوت</span>
                            <div class="copy-alarm" v-if="copyAlarm">کپی شد</div>
                        </label>
                        <button @click="copyLink">کپی لینک</button>
                    </div>
                    <div class="invite-code-input">
                        <label for="" class="invite-link-label">
                            <input type="text" :value=inviteCode>
                            <span>کد دعوت</span>
                             <div class="copy-alarm" v-if="codeAlarm">کپی شد</div>
                        </label>
                        <button @click="copyCode">کپی کد</button>
                        </div>
                </div>
            </div>
            <!-- <div class="invite-friend-information-container"> -->
            <!-- <perfect-scrollbar> -->
            <div class="invite-friend-information">
                <div class="my-money">
                    <img src="@/assets/myMoney.png" alt="">
                    <p>درآمد من</p>
                    <p>x</p>
                </div>
                <div class="inviteFriend-image">
                    <img src="@/assets/inviteFriend.png" alt="">
                    <p>دوستان من</p>
                    <p>n</p>
                </div>
            </div>
            <!-- </perfect-scrollbar> -->
            <!-- </div> -->
                </div> 
            </div>
    </div>
</div>
</template>
<script>
// import Authentication from './Authentication.vue'
import ProfileNavbar from "./ProfileNavbar.vue"
import Sidebar from './Sidebar.vue'

export default {
  components: { ProfileNavbar, Sidebar },
  name: 'MainProfile',
  data:function () {
      return {
          sidebarResponsive:false,
          copyAlarm:false,
          codeAlarm:false,
          inviteLink:"kjfgkjshdgflkjsgdhv654",
          inviteCode:"65465465465454"
      }
  },
  methods : {
      showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
        copyLink() {
            navigator.clipboard.writeText(this.inviteLink);
            this.copyAlarm=true
            setTimeout(() => { this.copyAlarm=false }, 1500);
        },
        copyCode() {
            navigator.clipboard.writeText(this.inviteCode);
            this.codeAlarm=true
            setTimeout(() => { this.codeAlarm=false }, 1500);
        }
  }
}
</script>

<style>
.invitefriends-container {
    width: 100%;
    max-width: 1440px;
    margin: 0 10px;
    display: flex;
    column-gap: 10px;
    height: calc(100vh - 70px);
}
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;

    }
    .inviteFriend-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        align-self: baseline;
        row-gap: 10px;
        width: 0;
        height: 98%
    }
    .invite-friend-links , .invite-friend-information {
        display: flex;
        flex-grow: 1;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        font-weight: bold;
        /* font-size: 14px; */
        font-size: clamp(12px , 1vw , 14px);
        overflow:scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .invite-friend-links::-webkit-scrollbar , .invite-friend-information::-webkit-scrollbar {
        display: none;
    }
    .invite-friend-links {
        flex-direction: column;
        flex-wrap: wrap;
        min-height: 224px;
        justify-content: space-around;
        row-gap: 20px;
        padding: 10px 20px;
    }
    .invite-friend-information-container {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        height: 50vh;
    }
    .invite-friend-information {
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .invite-friend-information > div {
        padding: 60px 0 0;
    }
    .inviteFriend-inputs {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
    row-gap: 30px;
    }
    .inviteFriend-inputs > input {
        margin: 50px 0;
    }
    .my-money , .inviteFriend-image {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .inviteFriend-image > p , .my-money > p {
        font-weight: bold;
        font-size: 20px;
    }
    .invite-link-input > button {
        width: 89px;
        height: 45px;
        background: #058C2B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 14px;
    }
    .invite-code-input > button {
        width: 89px;
        height: 45px;
        background: #058C2B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 14px;
    }
    .invite-link-label {
        position: relative;
    }
    .invite-link-label > input {
        width: 285px;
        height: 45px;
        border: 1px solid #00ACC1;
        box-sizing: border-box;
        border-radius: 2px;
    }
    .invite-link-label > span {
        position: absolute;
        top: -25px;
        right: 10px;
        background: transparent;
        background-color: white;
        padding: 0 5px;
        font-weight: bold;
        font-size: 14px;
    }
    .copy-alarm {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #058C2B;
        background: #d9fce3;
        width: 75px;
        top: 0;
        left: 20px;
        border-radius: 5px;
    }
    @media only screen and (max-width:1000px) {
        .invitefriends-container , .inviteFriend-container {
            height: unset;
        }
        .invite-link-label > input {
            width: unset;
        }
    }
    /* .sidebar {
            display: none;
        }
    @media only screen and (max-width: 900px) {
        .sidebar {
            display: none;
        }
    } */
</style>
