<template>
<!-- <div id="container"> -->
    <div id="Athutication">
        <!-- <AuthenticationNavbar/> -->
        <ProfileNavbar/>
         <!-- <button class="menue" @click="showSidebar">
             
        </button> -->
        <div id="authen-container">
            <Sidebar class="sidebar"/>
            <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
                <div id="authen-form1">
                    <div id="container">
                <div id="line1-athuntication">
                    <p id="line1-athuntication-title">احراز هویت</p>
                </div>
                <perfect-scrollbar>
                <div id="line2-athuntication">
                    <AuthenProcess1/>
                </div>
                </perfect-scrollbar>
                <div id="line3-athuntication">
                    <div id="line3-title">
                        <p id="line3-title-p"> تایید شماره موبایل</p>
                    </div>
                    <div id="mobile-title">برای احراز هویت ابتدا شماره موبایل خود را وارد کنید تو جه داشته باشد که شماره موبایل باید متعلق به خود شخص باشد</div>
                   <perfect-scrollbar>
                    <div id="line3-form">
                        <div class="inputs-container">
                    <div id="lin3-form-group">
                    <label class="input mobile-number-input">
                    <input class="input__field" type="text" />
                    <span class="input__label">   شماره موبایل</span>
                    </label>
                    
                    
                </div>
                    <div id="code-confirm">
                        
                        <label class="input">
                        <input class="input__field__image" type="text" />
                        <span class="input__label__image">کد عددی</span>
                        </label>
                        <img src="../../assets/OIP1.png" id="numberform">
                    </div>
                    </div>
                    <div id="get-code"><button id="get-code-button">دریافت کد تایید</button></div>
                <!-- <div id="line3-buttons">
                    <router-link to="/authentication"><button id="pre">مرحله قبل </button></router-link>
                    <router-link to="/bank-info"><button id="next">مرحله بعد</button></router-link>
                </div> -->
                </div>
                
                <div id="line3-buttons">
                    <router-link to="/authentication"><button id="pre">مرحله قبل </button></router-link>
                    <router-link to="/bank-info"><button id="next">مرحله بعد</button></router-link>
                </div>
                </perfect-scrollbar>
                </div>
                
                </div>
                </div>
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
// import AuthenticationNavbar from "./AuthenticationNavbar.vue"
import Sidebar from './Sidebar.vue'
import AuthenProcess1 from './AuthenProcess1.vue'
import ProfileNavbar from './ProfileNavbar.vue'
export default {
    components:{  Sidebar , AuthenProcess1, ProfileNavbar},
  name: 'AuthenticationMob',
  data : function () {
      return {
          sidebarResponsive:false
      }
  },
  methods:{
      showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
  }
  
}
</script>

<style scoped>
    #Athutication {
        /* display: grid; */
        justify-items: right;
        height: 100%;
    }
    #authen-form1 {
        /* display: grid; */
        justify-items: center;
        width: 100%;
        padding: 0 10px;
        /* overflow: scroll; */
        -ms-overflow-style: none;
        scrollbar-width: none;
        height: 98%;

    }
    #authen-form1::-webkit-scrollbar {
        display: none;
    }
    .input__field {
        /* width: 295px; */
        min-width: 250px;
    }
    .input {
        /* display: flex;
        flex-grow: 1; */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #authen-container {
        display: flex;
        direction: rtl;
        padding: 0 10px;
    }
    #line2-athuntication {
        /* display: flex; */
        justify-items: right;
        height: 120px;
        align-items: center;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        /* margin-bottom: 40px; */
        /* height: -webkit-fill-available; */

    }
    #line2-athuntication::-webkit-scrollbar {
        display: none;
    }
    #line1-athuntication-title {
        font-weight: bold;
        font-size: 18px;
        text-align: right;
        margin: 0;
    }
    #athutication-nav {
        width: 360px;
        height: 50px;
        border-radius: 25px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }
    .done {
        background: #00ACC1;
        color: white;
    }
    #line3-athuntication {
        /* width: 1273px; */
        /* height: 478px; */
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        /* display: grid; */
        padding: 0 20px 20px 20px;
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        height: 64vh;
        overflow: scroll;
        flex-grow: 1;
        justify-content: space-between;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    #line3-athuntication::-webkit-scrollbar {
        display: none;
    }
    #line3-form {
        display: grid;
        justify-content: normal;
        row-gap: 20px;
    }
    #lin3-form-group {
        display: flex;
        /* justify-content: center; */
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 50px;
        align-items: center;
    }
    #line3-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap-reverse;
        row-gap: 10px;
    }
    #line3-title {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    #line3-title-p {
        font-weight: bold;
        font-size: 18px;
        padding: 5px;
        margin: 5px;
        text-align: center;
    }
    #pre {
        border: 2px solid #00ACC1;
        box-sizing: border-box;
        border-radius: 35px;
        width: 172px;
        font-weight: bold;
        font-size: 16px;
        color: #00ACC1;
        background-color: white;
        height: 45px;
    }
    #next {
        width: 172px;
        background: #058C2B;
        border-radius: 35px;
        font-weight: bold;
        font-size: 16px;
        color: white;
        height: 45px;
        border: 0;
    }
    .address {
        width: 100%;
    }
    .input__field {
        /* width: 394px; */
        /* max-width: 295px; */
    }
    .input__label {
        font-weight: 600;
        font-size: 16px;
    }
     /* #lin3-form-group {
        justify-self: center;
    } */
    #code-confirm {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
    }
    .input__label__image {
        position: absolute;
        padding: 0 5px 0 10px;
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        background-color:white;
        transition: transform 120ms ease-in;
        font-weight: 500;
        font-size: 16px;
        top: 0;
        right: 20px;
        font-weight: 600;
        font-size: 16px;
    }
    .input__field__image {
        /* width: 221px; */
        max-width:221px ;
        height: 45px;
        margin: 10px 0 10px 0;
        border: 1px solid #00ACC1;
        box-sizing: border-box;
        display:inline-block;
        padding: 10px;
        border-radius:25px;
        vertical-align: text-top;
        }
        #numberform {
        display: inline-block;
        vertical-align: bottom;
    }
    #container {
        width: 100%;
        display: flex;
        flex-direction: column;
        /* row-gap: 30px; */
        height: 100%;
    }
    #get-code {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #get-code-button {
        /* width: 394px; */
        max-width: 394px;
        max-width: 394px;
        height: 45px;
        background: #00ACC1;
        border-radius: 35px;
        border: 0;
        color: white;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
    }
    #mobile-title {
        text-align: center;
        font-weight: 600;
        font-size: 16px;
    }
    .inputs-container {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }
    .mobile-number-input {
        width: 100%;
    }
</style>
<style >
#line2-athuntication::-webkit-scrollbar {
    display: none;
     
}
#line2-athuntication {
    /* height: 64px; */
    -ms-overflow-style: none;
        scrollbar-width: none;
}
@media only screen and (max-width: 600px) {
#line3-buttons {
        justify-content: center !important;
    }
}
    
</style>