<template>
    <div>
      <AuthenticationNavbar/>
      <div class="container-banks">
      <p>استعلام شماره شبا</p>
      <div class="banks">
        <a href="https://bank-day.ir/index.aspx?pageid=628" target="_blank" rel="noopener noreferrer"><img src="@/assets/dey.png" alt="" srcset=""></a>
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
        <img src="@/assets/dey.png" alt="" srcset="">
      </div>
      </div>
    </div>
</template>

<script>
import AuthenticationNavbar from './AuthenticationNavbar.vue';
  export default {
    components:{ AuthenticationNavbar  },
    name: 'AuthenticationForm'
}
</script>
  
<style scoped>
 .container-banks {
   width: 90%;
   margin: auto;
   display: flex;
   flex-direction: column;
 }
 .banks {
   display: flex;
    flex-wrap: wrap;
    justify-content: center;
 }
</style>