<template>
<div>
    <!-- <div id="profile-navbar"> -->
        <!-- <ProfileNavbar/> -->
        <!-- <Authentication/> -->
        <!-- <Sidebar class="sidebar"/>
        
        <div class="history-container">
            <div class="history-items">
                <div class="history-item active">معاملات </div>
                <div class="history-item">تراکنش ریالی</div>
                <div class="history-item">تراکنش رمز ارز </div>
                <div class="history-item">لاگین</div>
            </div> -->
            <!-- <div class="describe-history-items">
                <div class="history-items-container"> -->
                <div class="filter-history-row">
                <table class="historyTrades-table">
                    <!-- <thead>
                        <tr>
                            <td>بازار</td>
                            <td>مقدار</td>
                            <td>قیمت واحد </td>
                            <td>قیمت کل</td>
                            <td>کارمزد</td>
                            <td>دریافتی شما</td>
                            <td>تاریخ معامله</td>
                            <td>وضعیت</td>
                        </tr>
                    </thead> -->
                    <!-- <tbody> -->
                        <tr>
                            <td>زمان ورود</td>
                            <td>آی پی</td>
                            <td class="login-tool"> نوع دستگاه </td>
                            <td class="login-platform">پلتفورم</td>
                            <td class="login-browser">نوع مرور گر</td>
                            <td>وضعیت</td>
                        </tr>
                        <tr>
                            <td>xxxx/xx/xx</td>
                            <td>172.131.26.39</td>
                            <td class="login-tool">mobile</td>
                            <td class="login-platform"> windows 10</td>
                            <td class="login-browser">chrome 90.35</td>
                            <td><img src="@/assets/tick-green.png" alt=""></td>
                        </tr>
                        <tr>
                            <td>xxxx/xx/xx</td>
                            <td>172.131.26.39</td>
                            <td class="login-tool">mobile</td>
                            <td class="login-platform"> windows 10</td>
                            <td class="login-browser">chrome 90.35</td>
                            <td><img src="@/assets/tick-green.png" alt=""></td>
                        </tr>
                        
                    <!-- </tbody> -->
                </table>
                <!-- <div class="describe-row-header">
                    <div>بازار</div>
                    <div>مقدار </div>
                    <div>قیمت واحد</div>
                    <div>قیمت کل </div>
                    <div>کارمزد</div>
                    <div>دریافتی شما</div>
                    <div>تاریخ معامله</div>
                    <div>وضعیت</div>
                </div> -->
                <!-- <div class="describe-row-lists">
                    <div class="describe-row-list"> -->
                        <!-- <div>
                            <p>اتریوم</p>
                            <p>تومان</p>
                        </div>
                        <div>
                            <p>0.77+871827 ET</p>
                        </div>
                        <div>
                            <p>6464654654654 تومان</p>
                        </div>
                        <div>
                            <p>6464654654654 تومان</p>
                        </div>
                        <div>
                            <p>0.26%</p>
                        </div>
                        <div>
                            <p>0.45564654654 ET</p>
                        </div>
                        <div>
                            <p>xx/xx/xxxx</p>
                        </div>
                        <div>
                            <img src="@/assets/tick-green.png" alt="">
                        </div>
                    </div> -->
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                <!-- </div> -->
                <!-- </div>
            </div> -->
        <!-- </div>  -->
    </div>
</div>
</template>
<script>
// import Authentication from './Authentication.vue'
// import ProfileNavbar from "./ProfileNavbar.vue"
// import Sidebar from './Sidebar.vue'

export default {
  components: {},
  name: 'MainProfile',
  data:function() {
      return {
          deActiveBuys:false,
          activeBuys:true,
      }
  },
  methods : {
      beActiveBuys() {
          if (this.deActiveBuys) {
              this.deActiveBuys=false
              this.activeBuys=true
          }
          else 
              return true
      },
      beActiveSells () {
          if (this.activeBuys) {
              this.deActiveBuys=true
              this.activeBuys=false
          }
          else 
              return true
      },
  }
}
</script>

<style >
.historyTrades-table {
    width: 100%;
    border-collapse: collapse;
}
.historyTrades-table > tr {
    border-bottom: 1px solid #a5a5a5;
    height: 50px;
}
.describe-row-list {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.history-items {
    display: flex;
    flex-direction: row;
   
}
.active-buys ,.active-sells {
    background-color: #058C2B;
    color: white;
}
 
.history-item {
    /* padding: 0 15px; */
    background-color: rgba(243, 243, 243  , 0.1);
    /* background: rgba(15, 15, 15, 0.1); */
     /* height: 35px; */
     width: 110px;
     display: flex;
    justify-content: center;
    align-items: center;
}
.active {
    background: white;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 35%);
}
.describe-history-items {
    display: flex;
    flex-grow: 1;
    height: 600px;
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); */
    border-radius: 2px;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
    background: white;
}
.history-items-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    row-gap: 30px;
}
.history-markets {
    width: 120px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 25px;
    background: transparent;
}
.filter-history-row {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    margin-top: 20px;
}
.history-sell-buy {
    width: 200px;
    height: 39px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 25px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.history-buys {
    width: 100px;
    height: 35px;
    /* color: white; */
    border-radius: 25px;
    /* background-color: #E0342B; */
    /* background: transparent; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.history-sells {
    width: 100px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: transparent; */
}

.describe-row-header {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
}
    .wallet-header {
        font-weight: bold;
        font-size: 20px;
    }
    .wallet-title-p , .amount-p {
        font-weight: bold;
        font-size: 16px;
    }
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;

    }
    .history-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        align-self: baseline;
    }
    .wallet-row {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        flex-wrap: wrap;
    }
    .wallet-title {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-grow: 1;
    }
    .wallet-amount {
        flex-grow: 1;
        justify-content: center;
        display: flex;
        min-width: 100px;
    }
    .wallet-options {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        gap: 5px;
    }
    .wallet-blue-button , .wallet-red-button , .wallet-green-button {
        width: 95px;
        height: 30px;
        color: white;
        border: 0;
        border-radius: 2px;
    }
    .wallet-blue-button {
        background-color: #00ACC1;
    }
    .wallet-red-button {
        background-color: #E0342B;
    }
    .wallet-green-button {
        background-color: #058C2B;
    }

    /* .sidebar {
            display: none;
        }
    @media only screen and (max-width: 900px) {
        .sidebar {
            display: none;
        }
    } */
</style>
<style scoped>
table {
    width: 100% !important;
    text-align: center;
}
.filter-history-row {
    display: block !important;
}
@media only screen and (max-width: 900px) {
    .login-tool , .login-platform , .login-browser {
        display: none;
    }
    }
</style>
