var historyOrders = [
    {
        "OrderId":'bmdLRB',
        "date":'xxxx/xx/xx-xx:xx',
         "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "deal":"sell",
        "statuse":"done"
    },
    {
        "OrderId":'bmdLRB',
        "date":'xxxx/xx/xx-xx:xx',
         "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "deal":"sell",
        "statuse":"done"
    },
    {
        "OrderId":'bmdLRB',
        "date":'xxxx/xx/xx-xx:xx',
         "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "deal":"buy",
        "statuse":"canceled"
    },
    {
        "OrderId":'bmdLRB',
        "date":'xxxx/xx/xx-xx:xx',
         "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "deal":"buy",
        "statuse":"canceled"
    },
    {
        "OrderId":'bmdLRB',
        "date":'xxxx/xx/xx-xx:xx',
         "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "deal":"sell",
        "statuse":"canceled"
    },
    {
        "OrderId":'bmdLRB',
        "date":'xxxx/xx/xx-xx:xx',
         "market":'بیتکوین -تومان',
        "price":54654654654,
        "amount":0.025,
        "totalPrice":8798798,
        "deal":"sell",
        "statuse":"done"
    }
]
export default historyOrders