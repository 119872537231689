<template>
<div id="container">
    <div id="mainintro">
                <div id="introright">
                    <h4>اپلیکیشن باینکس</h4>
                    <h5>با استفاده از اپلیکیشن باینکس میتوانید هرجایی که هستید <br/> حساب خود را بررسی کنید و معاملات خود را به راحتی و در اسرع وقت انجام دهید</h5>
                    <a href="#"><h5>دریافت اپلیکیشن باینکس</h5></a>
                    <img src="@/assets/application.png" id="application"/>
                </div>
                <div id="introleft">
                    <img src="@/assets/mobile.png" id="mobile"/>
                </div>
                <div id="introbottom">
                    <button id="start">همین حالا شروع کنید</button>
                    <button id="education">آموزش خرید و فروش</button>
                </div>
    </div>
</div>
</template>

<script>
export default {
  name: 'Application'
}
</script>

<style scoped>
#container {
    width: 90%;
    margin: auto;
}
    #mainintro {
    direction: rtl;
    width: 100%;
    /* height: 600px; */
    background-color: white;
    text-align: right;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;

}
#introright , #introleft , #introbottom {
    box-sizing: border-box;
    display: inline-block;
}
#introright {
    /* width: 60%;
    height: 50%; */
    vertical-align: text-bottom;
    padding: 20px;
}
#introleft {
    /* width: 40%;
    height: 60%; */
    text-align: center;
}
#introbottom {
    width: 100%;
    height: 20%;
    text-align: center;
    margin-bottom: 50px;
}
#start , #education {
    width: 172px;
    color: white;
    margin: 20px;
    border: 0;
    font-family: shabnam;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 35px;
    height: 45px;
    font-size: 16px;
    font-weight: bold;
}
#start {
    background-color: #058C2B;
}
#education {
    background-color: #00ACC1;
}
#application {
    display: block;
    width: 207px;
    height: 80px;
}
#mobile {
    width: 300px;
    height: 400px;
}
@media only screen and (max-width:1000px) {
    #mainintro {
        justify-content: center;
    }
}

</style>
