<template>
<div>
    <div id="profile-navbar">
        <ProfileNavbar/>
         <!-- <button class="menue" @click="showSidebar">
             
        </button> -->
        <!-- <Authentication/> -->
        <div class="support-main-container">
            <Sidebar class="sidebar"/>
            <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
            <div class="support-container">
                <div class="activity-list">
                     <perfect-scrollbar>
                    <div class="table-container">
                   
                    <table>
                        <tr class="head-tr-message">
                            <td>عنوان</td>
                            <td class="department-td">بخش</td>
                            <td class="date-td">تاریخ ایجاد</td>
                            <td>وضعیت</td>
                            <td>جزئیات</td>
                        </tr>
                        <tr class="body-tr-message" v-for="(message , index) in messages" :key="index">
                            <td>{{message.title}}</td>
                            <td class="department-td">{{message.department}}</td>
                            <td class="date-td">{{message.date}}</td>
                            <td>
                                <p v-if="message.statuse==1"  class="statuse statuse-ok">پاسخ داده شده</p>
                                <p v-if="message.statuse==2" class="statuse statuse-Notok">در انتظار پاسخ</p> 
                            </td>
                            <td @click="showMessage(index)"><img src="@/assets/showDetail.png" alt="" class="show-detail"></td>
                        </tr>
                    </table>
                    
                    </div>
                    </perfect-scrollbar>
                    <div class="button-container">
                    <button v-if="this.addMessageButton===true" class="add-message" @click="hideMessage"> ایجاد پیام جدید <img src="@/assets/add.png" alt="" ></button>
                    </div>
                </div>
                <div class="chat-box" >
                    <div class="head-chat-box">
                        <label for="" v-if="this.addMessage===false">
                            <input type="text" class="head-chat-box-input-title"/>
                            <span>عنوان</span>
                        </label>
                        <label for="" v-if="this.addMessage===false">
                            <select  class="head-chat-box-input-department">
                                <option value="technical">فنی</option>
                                <option value="finance">مالی</option>
                            </select>
                            <span>بخش</span>
                        </label>
                        <div v-if="this.addMessage===true" class="showMessage-title" >
                            <div >عنوان
                                <p  class="sub-title-message">{{this.selectesMessage[0].title}}</p>
                            </div>
                            <div >بخش 
                                <p class="sub-title-message">{{this.selectesMessage[0].department}}</p>
                            </div>
                            <div >تاریخ ایجاد
                                <p class="sub-title-message">{{this.selectesMessage[0].date}}</p>
                            </div>
                            <div >وضعیت
                                <p class="sub-title-message" v-if="selectesMessage[0].statuse==1"  >پاسخ داده شده</p>
                                <p class="sub-title-message" v-if="selectesMessage[0].statuse==2" >در انتظار پاسخ</p>
                            </div>
                        </div>
                    </div>
                    <div class="chat">
                        
                        <div class="messages" >
                            <perfect-scrollbar>
                            <div class="message-container">
                                
                            <!-- v-if="message.body" -->
                        <template v-if="this.messages[0].body && showw">
                        
                            <div class="test-scroll"
                             
                             :class="{ 'message-area-admin': message.author === 'admin', 'message-area-customer': message.author !== 'admin' }"
                             v-for="message , index in messages" :key="index"
                             >
                                <p  class="message-text-customer">
                                {{ message.body }}
                                </p>
                                    <span 
                                    :class="{ 'message-date-admin': message.author === 'admin', 'message-date-customer': message.author !== 'admin' }"
                                    >
                                    {{message.date}}
                                    </span>
                                <svg 
                                :class="{ 'message-admin-svg': message.author === 'admin', 'message-customer-svg': message.author !== 'admin' }"
                                
                                 height="20" width="10">
                                    <polygon v-if="message.author === 'admin'" class="message-admin-polygon" points="0,15  0,0  15,0 "  />
                                    <polygon v-if="message.author !== 'admin'" class="message-customer-polygon" points="10,0  -5,0  10,15 "  />
                                </svg>

                            </div>
                        
                        </template>
                         <template v-if="this.message[0]">
                        
                            <div class="test-scroll"
                             
                             :class="{ 'message-area-admin': message.author === 'admin', 'message-area-customer': message.author !== 'admin' }"
                             v-for="message , index in message" :key="index"
                             >
                                <p  class="message-text-customer">
                                {{ message.body }}
                                </p>
                                    <span 
                                    :class="{ 'message-date-admin': message.author === 'admin', 'message-date-customer': message.author !== 'admin' }"
                                    >
                                    {{message.date}}
                                    </span>
                                <svg 
                                :class="{ 'message-admin-svg': message.author === 'admin', 'message-customer-svg': message.author !== 'admin' }"
                                
                                 height="20" width="10">
                                    <polygon v-if="message.author === 'admin'" class="message-admin-polygon" points="0,15  0,0  15,0 "  />
                                    <polygon v-if="message.author !== 'admin'" class="message-customer-polygon" points="10,0  -5,0  10,15 "  />
                                </svg>

                            </div>
                        
                        </template>
                        
                        </div>
                        </perfect-scrollbar>
                        <div class="chat-bar-container">
                            <div class="chat-bar">
                                <div class="input-file-container">
                                 <img src="@/assets/attachment.png" alt="">
                                 <input type="file" name="" id="">
                                </div>
                                <img src="@/assets/sendMessage.png" alt="" @click="sendMessage">
                                <!-- <input type="text" class="input-message"> -->
                                <!-- <perfect-scrollbar> -->
                                <textarea  class="input-message resize-ta" name="" id="" @input="setHeight" @change="setMinHieght">
                                    
                                </textarea>
                                <!-- </perfect-scrollbar> -->
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    </div>
</div>
</template>
<script>
// import Authentication from './Authentication.vue'
import ProfileNavbar from "./ProfileNavbar.vue"
import Sidebar from './Sidebar.vue'

export default {
  components: { ProfileNavbar, Sidebar },
  name: 'MainProfile',
  data : function() {
      return {
          addMessage:false,
          addMessageButton:false,
          sidebarResponsive:false,
          adminMessage: '',
        customerMessage: '',
        selectesMessage:[],
        showw:false,
        message : [

        ],
    messages: [
        {
            body: 'تیم پشتیبانی باینکس',
            author: 'admin',
            date:"xxxx/xx/xx",
            time:"xx:xx",
            title:"برداشت وجه",
            department:"finance",
            statuse:1,
        },
        {
            body: 'سوال یک',
            author: '',
            date:"xxxx/xx/xx",
            time:"xx:xx",
            title:"واریز وجه",
            department:"technical",
            statuse:2,
        },
        {
            body: 'سوال دو',
            author: '',
            date:"xxxx/xx/xx",
            time:"xx:xx",
            title:"درخواست راهنمایی",
            department:"finance",
            statuse:2,
        },
        {
            body: 'جواب دو',
            author: 'admin',
            date:"xxxx/xx/xx",
            time:"xx:xx",
            title:"درخواست راهنمایی",
            department:"finance",
            statuse:2,
        },
        {
            body: 'جواب یک',
            author: 'admin',
            date:"xxxx/xx/xx",
            time:"xx:xx",
            title:"درخواست راهنمایی",
            department:"finance",
            statuse:2,
        },
        {
            body: 'سوال سه',
            author: '',
            date:"xxxx/xx/xx",
            time:"xx:xx",
            title:"درخواست راهنمایی",
            department:"finance",
            statuse:2,
        },
        {
            body: 'سوال چهار',
            author: '',
            date:"xxxx/xx/xx",
            time:"xx:xx",
            title:"درخواست راهنمایی",
            department:"finance",
            statuse:2,
        },
        {
            body: 'سوال پنج',
            author: '',
            date:"xxxx/xx/xx",
            time:"xx:xx",
            title:"درخواست راهنمایی",
            department:"finance",
            statuse:2,
        },

    ]
      }
  },
  methods: {
      showMessage(index) {
          this.addMessage=true,
          this.addMessageButton=true
          this.selectesMessage[0]=''
          this.selectesMessage[0]=this.messages[index]
          console.log(this.selectesMessage[0].title)
          this.showw=true
      },
      hideMessage() {
          this.addMessage=false,
          this.addMessageButton=false
          this.showw=false
      },
      sendMessage() {
          const message=document.getElementsByClassName("input-message")[0].value
          const title = document.getElementsByClassName("head-chat-box-input-title")[0].value
          const department = document.getElementsByClassName("head-chat-box-input-department")[0].value
          const date=new Date()
          const localDate=date.toLocaleString();
          if(message) {
              this.message.push({body: message, author: 'customer', date:localDate , title:title , department:department , statuse:2})
          document.getElementsByClassName("input-message")[0].value=''
        //   this.messages.push({body: "پیام دریافت شد و در اسرع وقت پاسخ داده خواهد شد", author: 'admin', date:localDate})
          
          console.log(this.messages[4])
          let index=this.messages.length-1
          console.log('---->' , index)
        //   this.messages[index].body.scrollIntoView({block: "end"});
        document.getElementsByClassName('test-scroll')[`${index-1}`].scrollIntoView({block: "end"});
        // console.log(document.getElementsByClassName('test-scroll')[index])
          }
          this.calcHeight('')
        //   let textarea = document.querySelector(".resize-ta");
        //   textarea.style.height = this.calcHeight('') + "px";
      },
      showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
        setMinHieght() {
            let textarea = document.querySelector(".resize-ta");
            textarea.style.height = 40 + "px";
        },
        setHeight() {
            let textarea = document.querySelector(".resize-ta");
            if(textarea.value!=='') {
                textarea.style.height = this.calcHeight(textarea.value) + "px";
                console.log(textarea.style.height)
            }
            else {
                this.calcHeight(0)
            }
            // textarea.value.lenght=0
            
        },
        calcHeight(value) {
            let textarea = document.querySelector(".resize-ta");
            if(textarea.value!=='') {
                let numberOfLineBreaks = (value.match(/\n/g) || []).length;
                // min-height + lines x line-height + padding + border
                let newHeight = 20 + numberOfLineBreaks * 20 + 20 ;
                return newHeight;
            }
            else {
                let newHeight = 0 ;
                return newHeight;
            }
        }
  },
  
  mounted() {
      let a= this.messages.length-1
          console.log(this.messages[a]);
        //   document.getElementsByClassName('test-scroll')[7].scrollIntoView({block: "end"});
  },
//   computed : {
//       showMessage: function(index) {
//           this.addMessage=true,
//           this.addMessageButton=true
//           this.selectesMessage[0]=''
//           this.selectesMessage[0]=this.messages[index]
//           console.log(this.selectesMessage[0].title)
//       }
//   }
}
</script>

<style>
.support-main-container {
    width: 100%;
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    height: calc(100vh - 70px);
    max-width: 1440px;
}
.chat-bar-container {
    width: 100%;
    /* position: absolute;
    bottom: 0; */
    
}
.statuse-ok {
    color: #058C2B;
}
.statuse-Notok {
    
    color: #FF9800;
}
.wating-for-res {
    color: #FF9800;
}
.ok-for-res {
    color: #058C2B;
}
.chat-bar {
    width: 95%;
    display: flex;
    margin: auto;
    column-gap: 1px;
    /* height: 40px; */
    align-items: flex-end;
}
.input-message {
    display: flex;
    flex-grow: 1;
    border: 1px solid #00ACC1;
    box-sizing: border-box;
    border-radius: 2px;
     /* overflow: hidden; */
  height: 40px;
  overflow: hidden;
  resize: none;
  padding: 5px;
  max-height: 150px;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.input-message::-webkit-scrollbar {
    display: none;
}
.chat {
    background-image: url("../../assets/backgroundChat.png");
    background-size: contain;
    /* height: 550px; */
    position: relative;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    flex-grow: 1;
    height: 85%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 600px;
}
.head-chat-box {
    width: 100%;
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    padding-top: 10px;
    flex-wrap: wrap;
}
.head-chat-box > label {
    position: relative;
    display: flex;
    flex-grow: 1;
    min-width: 150px;
}
.head-chat-box > label > span {
    position: absolute;
    background: white;
    top: -5px;
    right: 20px;
    padding: 0 5px;
}
.head-chat-box-input-title , .head-chat-box-input-department {
    height: 35px;
    margin: 10px;
    display: flex;
    flex-grow: 1;
    border: 2px solid #00ACC1;
box-sizing: border-box;
border-radius: 2px;
}
.head-chat-box-input-department {
    min-width: 150px;
}
.chat-box {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    flex-grow: 1;
    row-gap: 10px;
    width: 50%;
}
.show-detail {
    width: 24px;
    height: 19px;
    cursor: pointer;
}
.activity-row {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    border-bottom: 1px solid lightgrey;
    padding: 10px;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
}
.activity-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    min-width: 50%;
    position: relative;
    justify-content: inherit;
    width: 50%;
    /* overflow: scroll; */
    -ms-overflow-style: none; 
    scrollbar-width: none;  
    row-gap: 40px;
    position: relative;
}
.activity-list::-webkit-scrollbar {
    display: none;
}
.activity-list-title {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    border-bottom:1px solid black;
    padding: 10px;
    align-items: center;
    color: rgba(15, 15, 15, 0.6);
    font-weight: bold;
font-size: 14px;
}
.history-items {
    display: flex;
    flex-direction: row;
   
}
.history-item {
    /* padding: 0 15px; */
    background-color: rgba(243, 243, 243 , 0.1);
    /* background: rgba(15, 15, 15, 0.1); */
     /* height: 35px; */
     width: 110px;
     display: flex;
    justify-content: center;
    align-items: center;
}
.active {
    background: white;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 35%);
}
.describe-history-items {
    display: flex;
    flex-grow: 1;
    height: 600px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    flex-direction: column;
    row-gap: 30px;
}
.history-markets {
    width: 120px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 25px;
}
.filter-history-row {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    margin-top: 20px;
}
.history-sell-buy {
    width: 200px;
    height: 39px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 25px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.history-buy  {
    width: 100px;
    height: 35px;
    color: white;
    border-radius: 25px;
    background-color: #E0342B;
    display: flex;
    justify-content: center;
    align-items: center;
}
.history-sell {
    width: 100px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.describe-row-header {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
}
.messages {
    margin: 10px;
    /* overflow: scroll; */
    height: 85%;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    /* -ms-overflow-style: none; 
    scrollbar-width: none;   */
    justify-content: space-between;
    flex-grow: 1;
    margin-bottom: 0;
}
.message-container {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    overflow: scroll;
    -ms-overflow-style: none; 
    scrollbar-width: none;
    flex-grow: 1;
}
.message-container::-webkit-scrollbar {
    display: none;
    }
.message-area-customer {
    width:70%;
    background: #F2FAFB;
    margin-left: 20px;
    margin-right: auto;
    position:relative;
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
    border-radius: 0 2px 2px 2px;
    margin-bottom: 5px;
}
.message-text-customer {
    margin: 20px;
    font-weight: 500;
    /* font-size: 14px; */
    font-size: clamp(12px , 1vw , 14px);
    margin: 5px 10px;
    overflow-wrap: anywhere;
    white-space: break-spaces;
}
.message-date-customer {
    margin: 0 20px 10px;
    font-weight: 500;
    font-size: 14px;
    color: rgba(15, 15, 15, 0.8);
    display: flex;
    column-gap: 10px;
    direction: rtl;
}
.message-customer-svg {
    position:absolute;
  left:-10px;
  border-top-left-radius: 2px;
}
.message-customer-polygon {
    fill:#F2FAFB;
}


.message-area-admin {
    width:70%;
    background: white;
    margin-right: 35px;
    position:relative;
    display: flex;
    flex-direction: column;
    filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
    border-radius: 2px 0 2px 2px;
}
.message-text-admin {
    margin: 20px;
    font-weight: 500;
    /* font-size: 14px; */
    font-size: clamp(12px , 1vw , 14px);
}
.message-date-admin {
    margin: 0 20px 10px;
    font-weight: 500;
    font-size: 14px;
    color: rgba(15, 15, 15, 0.8);
    display: flex;
    column-gap: 10px;
    direction: ltr;
}
.message-admin-svg {
    position:absolute;
  right:-10px;
  border-top-right-radius: 2px;
}
.message-admin-polygon {
    fill:white;
}
.input-message[contenteditable]:empty::before {
  content: "Placeholder still possible";
  color: gray;
}

    .wallet-header {
        font-weight: bold;
        font-size: 20px;
    }
    .wallet-title-p , .amount-p {
        font-weight: bold;
        font-size: 16px;
    }
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;
    }
    .support-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-grow: 1;
        align-self: baseline;
        /* flex-wrap: wrap; */
        column-gap: 10px;
        row-gap: 10px;
        height: 98%;
    }
    .wallet-row {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        flex-wrap: wrap;
    }
    .wallet-title {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-grow: 1;
    }
    .wallet-amount {
        flex-grow: 1;
        justify-content: center;
        display: flex;
        min-width: 100px;
    }
    .wallet-options {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        gap: 5px;
    }
    .wallet-blue-button , .wallet-red-button , .wallet-green-button {
        width: 95px;
        height: 30px;
        color: white;
        border: 0;
        border-radius: 2px;
    }
    .wallet-blue-button {
        background-color: #00ACC1;
    }
    .wallet-red-button {
        background-color: #E0342B;
    }
    .wallet-green-button {
        background-color: #058C2B;
    }
    select {
        background: white;
    }
  
</style>
<style lang="scss" scoped>
    .button-container {
        width: 100%;
        position: absolute;
        bottom: 0;
        background-color: #ffffff;
}
.table-container {
    height: 120%;
    overflow: scroll;
    width:100%;
    -ms-overflow-style: none; 
    scrollbar-width: none;  
}
.table-container::-webkit-scrollbar {
    display: none;
}
.sub-title-message {
    font-weight: normal;
    margin: 5px;
}
table {
    width: 95%;
    margin: 0 auto;
    border-collapse: collapse;
    text-align: center;
}
table > tr {
    height: 50px;
    border-bottom: 1px solid gainsboro;
    font-weight: bold;
font-size: 14px;
}
.head-tr-message {
    border-bottom: 1px solid rgba(15, 15, 15, 0.8);
    font-weight: bold;
    font-size: clamp(10px , 1vw , 14px);
    color: rgba(15, 15, 15, 0.6);
}
.add-message {
    width: 250px;
    height: 40px;
    background: #00ACC1;
    border-radius: 2px;
    margin: 10px auto;
    font-weight: bold;
font-size: 16px;
color: white;
display: flex;
align-items: center;
justify-content: center;
column-gap: 10px;
}
.showMessage-title {
    display: flex;
    width: 90%;
    margin:  0 auto;
    justify-content: space-between;
    text-align: center;
}
.showMessage-title div {
    flex-grow: 1
}
.showMessage-title {
    font-weight: bold;
    /* font-size: 16px; */
    font-size: clamp(12px , 1vw , 16px);
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 50px;
    flex-grow: 1;
    row-gap: 20px;
}
.body-tr-message {
    font-size: clamp(10px , 1vw , 14px);
    font-weight: bold;
}
.input-file-container {
    position: relative;
    width: 53px;
    height: 40px;
    cursor: pointer;
        input {
            position: absolute;
            opacity: 0;
            width: 53px;
            height: 40px;
            right: 0;
        }
}
@media screen and (max-width: 1000px) {
    .support-container {
        flex-wrap: wrap;
    }
    .chat {
        margin-bottom: 20px;
    }
}
@media screen and (max-width: 600px) {
      .date-td , .department-td {
          display: none;
      }
  }
</style>
