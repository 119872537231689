<template>
 
  <transition name="modal-fade">
     <BuySuccess v-if="buySuccess" @close="buySuccess = false"/>
        <div class="modal-backdrop">
          <div class="modal"
            role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <header
              class="modal-header"
              id="modalTitle"
            >
              <slot name="header">
                <p>خرید بیت کوین</p>
              </slot>
              <button
                type="button"
                class="btn-close"
                @click="close"
                aria-label="Close modal"
              >
              <img src="@/assets/closeModal.png" alt="">
              </button>
            </header>
    
            <section
              class="modal-body"
              id="modalDescription"
            >
              <slot name="body">
                  <div class="body-title">
                    <p>آیا از ثبت درخواست خرید به شرح زیر مطمئن هستید؟</p>
                  </div>
                  <div class="body-items">
                    <p>قیمت واحد(تومان)</p>
                    <p>856,142,367</p>
                  </div>
                  <div class="body-items">
                    <p>مقدار بیت کوین</p>
                    <p>84</p>
                  </div>
                  <div class="body-items">
                    <p>قیمت کل</p>
                    <p>85,614,321</p>
                  </div>
              </slot>
              
            </section>
    
            <footer class="modal-footer">
              <!-- <slot name="footer">
                This is the default footer!
              </slot> -->
              <button
                type="button"
                class="btn-buy-modal"
                @click="close1"
                
                aria-label="Close modal"
              >
               خرید
              </button>
              <button
                type="button"
                class="cancel-sell-modal"
                @click="close"
                aria-label="Close modal"
              >
                انصراف
              </button>
            </footer>
          </div>
        </div>
      </transition>
</template>

<script>
import BuySuccess from './BuySuccess.vue';
  export default {
  components: { BuySuccess },
    name: 'Modal',
    data:function() {
      return {
        buySuccess:false,
        closeBoolean:false
      }
      
    },
    methods: {
      close() {
        this.$emit('closeModal' , false);
      },
      close1() {
        // this.buySuccess=true
        this.$emit('closeModal' , false);
        
      }
    },
    created: function () {
    window.addEventListener("click",(event)=>{
            if(!event.target.closest( '.modal , .buy-btn')) {
              this.$emit('closeModal' , this.closeBoolean)
            }
            // else if(event.target.closest('.modal , .add-btn')) {
            // }
            else return true
          } )
  },
  };
</script>
    
<style scoped>
body {
  position: relative;
}
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 101;
    
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 520px;
    min-height: 332px;
    row-gap: 20px;
    margin: 0 10px;
    padding: 10px 0;
  }

  .modal-header {
    padding: 15px 0 0 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    display: flex;
    justify-content: center;
  }
  .body-title > p {
    font-weight: bold;
    /* font-size: 18px; */
    font-size: clamp(16px , 1vw , 18px);
    margin: 5px;
  }
  .body-items {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid gainsboro;
  }
  .body-items > p {
    font-weight: 500;
    /* font-size: 16px; */
    font-size: clamp(14px , 1vw , 16px);
    margin: 0;
  }
  .modal-header  > p {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
  }

  .modal-footer {
    /* border-top: 1px solid #eeeeee; */
    /* flex-direction: column; */
    justify-content: space-around;
    align-items: center;
    display: flex;
    direction: rtl;
  }

  .modal-body {
    position: relative;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    direction: rtl;
    column-gap: 20px;
    row-gap: 20px;

  }
  .modal-body > slot > div {
    display: flex;
    justify-content: space-around;
    padding: 7px;
    border-bottom: 1px solid gainsboro;
  }
  
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
    /* font-weight: bold; */
    /* color: #4AAE9B; */
    background: transparent;
  }
  .btn-buy-modal {
    background: #058C2B;
    border-radius: 2px;
    color: white;
    width: 146px;
    height: 40px;
    border: 0;
    font-weight: bold;
    font-size: 16px;
  }
  .cancel-sell-modal {
    border: 1px solid #00ACC1;
    box-sizing: border-box;
    border-radius: 2px;
    width: 146px;
    height: 40px;
    color: #00ACC1;
    background: transparent;
    font-weight: bold;
    font-size: 16px;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }

</style>