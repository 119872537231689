<template>
<div>
  <EmailModal v-if="showModalEmail" @close="showModalEmail = false" @closeModaleEmit="closeModal"/>
  <ModalGoogle v-if="showModalGoogle" @close="showModalGoogle = false" @closeModaleEmit="closeModal"/>
  <MessageModal v-if="showModalMessage" @close="showModalMessage = false" @closeModaleEmit="closeModal"/>
    <div id="profile-navbar">
        <ProfileNavbar/>
        <!-- <button class="menue" @click="showSidebar"></button> -->
        <!-- <Authentication/> -->
        <div class="security-container">
        <Sidebar class="sidebar"/>
        <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
        <div class="security-form-container">
            <div class="security-items">
              <div>
                <p class="security-items-title">تایید هویت دو مرحله ای</p>
              </div>
              <div>
                <p class="security-items-p">برای امنیت بیشتر حساب کاربری شما فعالسازی یکی از روش های شناسایی دو عاملی ضروری میباشد</p>
              </div>
              <div class="security-items-row">
                <div class="security-items-button">
                  <button  name="" id="" class="security-btn" @click="showModalMessage=true">
                  </button>
                  پیامک
                </div>
                <div class="security-items-button">
                  <button type="checkbox" class="security-btn" id="" @click="showModalEmail=true">
                  
                  </button>
                  ایمیل
                </div>
                <div class="security-items-button">
                  <button type="checkbox" name="" id="" class="security-btn" @click="showModalGoogle=true"></button>
                  شناسایی دو عاملی گوگل
                </div>
              </div>
            </div>
            <div class="security-change-password">
              <div>
                تغییر رمز عبور
              </div>
              <form class="security-change-inputs">
                <div>
                  <label class="input">
                    <!-- <button class="show-pass-security" @click="showPassword">
                      <img src="@/assets/showpass.png" >
                    </button> -->
                    <input class="input__field"  :type="type"  v-model="pass"  />
                    <span class="input__label">  رمز عبور فعلی</span>
                    </label>
                    
                </div>
                <div>
                  <label class="input">
                    <input class="input__field" type="password" />
                    <span class="input__label">  رمز عبور جدید</span>
                    </label>
                </div>
                <div>
                  <button class="security-change-password-button">تغییر رمز عبور</button>
                </div>
              </form>
            </div>
        </div> 
        </div>
    </div>
</div>
</template>
<script>
// import Authentication from './Authentication.vue'
import ProfileNavbar from "./ProfileNavbar.vue"
import Sidebar from './Sidebar.vue'
import EmailModal from '../Modals/EmailModal.vue'
import ModalGoogle from '../Modals/ModalGoogle.vue'
import MessageModal from '../Modals/MessageModal.vue'

export default {
  components: { ProfileNavbar, Sidebar , EmailModal , MessageModal, ModalGoogle },
  name: 'Security',
    
  data:function() {
    return {
      isActive:false,
      type: 'password',
      btnText: 'Show Password',
      showModalEmail:false,
      showModalMessage:false,
      showModalGoogle:false,
      sidebarResponsive:false,
      // showModalMessage:false,
    }
  },
     methods: {
       showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
    myFilter: function() {
      this.isActive = !this.isActive;
    },
    closeModal(value) {
      this.showModalEmail=value
      this.showModalMessage=value
      this.showModalGoogle=value
      console.log(value)
    }
    // showMessage () {
    //   showModalMessage=true
    // },
    // showEmail() {
    //   showModalEmail=true
    // },
    // showModalGoogle() {
    //   showModalGoogle=true
    // }
},
 showPassword() {
			if(this.type === 'password') {
				this.type = 'text'
				this.btnText = 'Hide Password'
        console.log(this.type)
			} else {
				this.type = 'password'
				this.btnText = 'Show Password'
        console.log(this.type)
			}
      console.log(this.type)
		}
}
</script>

<style scoped>
  .security-container {
    width: 100%;
    margin: 0 10px;
    flex-grow: inherit;
  }
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;

    }
    .security-container {
        display: flex;
        /* flex-direction: column; */
        flex-direction: row;
        justify-content: center;
        /* flex-grow: 1; */
        align-self: baseline;
        row-gap: 10px;
        column-gap: 10px;
        height: calc(100vh - 70px);
    }
    .security-form-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      row-gap: 10px;
    }
    .security-row-title {
        column-gap: 10px;
    }
    .security-row {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
      border-radius: 2px;
      display: grid;
      align-content: center;
      background-color: white;
    }
    .security-items {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
      border-radius: 2px;
      padding: 0 10px 40px;
    }
    .security-items-button > button{
      width: 20px;
    height: 20px;
    border-radius: 50%;
    background: transparent;
    border: 1px solid #00ACC1;
    }
    .security-items-title {
      font-weight: bold;
      font-size: 20px;
    }
    .security-items-p {
      font-weight: 600;
font-size: 16px;
    }
    .security-items-row {
      display: flex;
      column-gap: 50px;
      flex-wrap: wrap;
      row-gap: 20px;
    }
    
    .security-items-row {
      font-weight: 600;
font-size: 16px;
    }
    .security-toggle-image {
      background-color: white;
      border: 0;
    }
    .security-row-date , .security-row-title ,  .security-toggle-image {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .security-row-container {
      display: flex;
      justify-content: space-between;
      padding: 10px;
    }
    .security-row-describe {
        max-width: 1300px;
    }
    .security-change-password {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
      border-radius: 2px;
      padding: 20px;
      font-weight: bold;
font-size: 20px;
    }
    .security-change-password-button {
      width: 146px;
      height: 40px;
      background: #00ACC1;
      border-radius: 25px;
      color: white;
    }
    .security-change-inputs {
      display: flex;
    align-items: center;
    column-gap: 30px;
    width: 90%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    row-gap: 20px;
    }
    .security-change-inputs > div {
      display: flex;
    flex-grow: 1;
    }
    .security-change-inputs > div > label , .security-change-inputs > div > label > input {
      /* min-width: 280px; */
      width: 100%;
    }
    .show-pass-security {
      position: absolute;
      z-index: 10;
      background:transparent;
      top: 18px;
    }
    .input__error {
        font-size: 16px;
        margin: 10px 0 10px 0;
        border: 1px solid red;
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 45px;
        padding: 10px;
        border-radius:25px;
        position: relative;
    }
    .show-pass {
        position: absolute;
        top: 20px;
        left: 0;
        background: transparent;
        border: 0;
    }
    .sidebar-responsive {
      right: 0;
    }
</style>
