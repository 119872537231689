<template>
    <div class="backgroung">
        <Navbar/>
        <div id="binex-parent">
    <div class="binex">
        <div class="binexright">
            <SubscribForm/>
        </div>
        <div class="binexleft">
            <div class='img-container'>
                <a href="/" @click.prevent="$router.push('/')"><img src="../assets/LOGOSITE1.png" id="binexleft-image" ></a>
            </div>
           
            <div id="binexleft-text">
                <p id="binexleft-text-p">
                   <br> پلتفرم برخط معاملاتی باینکس خدمات خود را  با استفاده از پروتکل امن SSL و با آدرس https://binex.pro در اختیار مشتریان قرار می‌دهد.
                  <br>   لطفا پیش از وارد کردن هرگونه اطلاعات،آدرس مرورگر وب خود را با آدرس فوق مقایسه نموده و در صورت مشاهده هرگونه مغایرت از ادامه ی کار خودداری فرمایید و مراتب امر را با ما به آدرس https://binex.pro/ در میان بگذارید.
                   <br>   تحت هیچ شرایطی اطلاعات حساب کاربری)نام کاربری و رمزعبور( خود را در اختیار دیگران قرا ر ندهید. در غیر این صورت مسئولیت تمام اتفاقات احتمالی بر عهده ی شخص شما خواهد بود. پس از اتمام کار با باینکس تریدر سعی کنید روی دکمه ی خروج کلیک نموده و از حساب کاربری خود خارج شوید‌. */


                </p>
            </div>
        </div>
        
    </div>
    </div>
    </div>
</template>

<script>
import Navbar from './Navbar.vue'
import SubscribForm from './SubscribForm.vue'
export default {
    components:{ SubscribForm , Navbar},
  name: 'BinexSub'
}
</script>

<style lang="scss" scoped>
#binex-parent {
    display: flex;
    justify-content: center;
}
    .binex {
        /* margin: auto;
        margin-top: 10px; */
        /* width: 980px; */
        /* height: 550px; */
        /* background-color: white !important;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        border-radius: 2px; 
        display: flex;
        justify-content: center;
        flex-wrap: wrap; */
         /* margin: auto; */
        margin: 5px;
        /* width: 980px; */
        /* height: 550px; */
        background-color: white !important;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        border-radius: 2px; 
        /* display: grid; */
        /* grid-template-columns: repeat(2 , minimax(300px,auto)); */
        /* grid-template-columns:minmax(300px , 1fr) minmax(300px , 1fr);
        grid-template: minmax(300px,auto); */
        display: flex;
        flex-wrap: wrap;
        max-width: 980px;
        min-width: auto;
        justify-content: center;
        
    }
    .binexright {
        /* display: inline-block;
        box-sizing: border-box;
        width: 50%;
        height: 100%;
        background-color: white;
        vertical-align: top;
        border-left: 1px solid gainsboro; */
        display: inline-block;
        box-sizing: border-box;
        /* width: 50%; */
        /* width: 100%; */
        /* height: 100%; */
        background-color: white;
        vertical-align: top;
        border-left: 1px solid gainsboro;
        max-width: 900px;
        /* min-width: auto; */
        /* padding: 20px; */
        text-align: center;
        flex-grow: 4;
    }
    .binexleft {
        /* display: inline-block;
        box-sizing: border-box;
        width: 50%;
        height: 80%;
        background-color: white;
        vertical-align: bottom;
        margin: auto; */
        /* display: inline-block; */
        box-sizing: border-box;
        /* width: 50%; */
        /* height: 80%; */
        background-color: white;
        /* vertical-align: bottom; */
        /* margin: auto; */
        display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /* justify-content: center; */
    margin-top: 40px;
    align-items: center;
    padding: 20px;
    flex-grow: 5;
    }
    .binexright , .binexleft{
        /* flex-grow: 1;
    flex-shrink: 1; */
    flex-basis: 400px;
    }
    #binexleft-image {
        /* width: 170px;
        height: 170px; */
        /* margin: auto; */
    }
    #binexleft-text {
        /* width: 440px; */
        /* height: 170px; */
        text-align: right;
        /* margin: auto; */
    }
    #binexleft-text-p {
        font-weight: normal;
font-size: 12px;
margin: 0;
    }
    .img-container {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
            a {
                height: 35px;
            }
    }
    .backgroung {
        row-gap: 0;
    }
</style>