<template>
<div>
            <!-- <BuyBit v-if="showBuy" @close="showBuy = false"/> 
            <SellBit v-if="showSell" @close="showSell = false"/> -->
                <div class="currency-detail-inputs">
                    <div class="LimitMrket-top">
                        <!-- @click="showLimit"  :class="{'activeLimit':active , 'deActiveLimit':!active}"-->
                        <div @click="showLimit" :class="{'activeLimit':active , 'deActiveLimit':!active}" >
                            <p>حد</p>
                        </div>
                         <!-- @click="showLimit" :class="{'activeLimit':!active , 'deActiveLimit':active}" -->
                        <div @click="showLimit" :class="{'activeLimit':!active , 'deActiveLimit':active}"  >
                            <p>بازار</p>
                        </div>
                    </div>
                    <perfect-scrollbar>
                    <div class="currency-detail-inputs-deal">
                        <!-- <div class="choose-buy-sell">
                            <p class="choose-buy" :class="{'activeChooseBuy': activeChooseBuy}" @click="chooseBuy">خرید
                                <svg class="buy-svg" height="40" width="30">
                                    <polygon class="buy-pol" points="0,0 30,0 30,40"  :class="{'activeChooseBuy': activeChooseBuy}"/>
                                </svg>
                            </p>
                            <p class="choose-sell" :class="{'activeChooseSell' : activeChooseSell}" @click="chooseSell">فروش
                                <svg class="sell-svg" height="40" width="30">
                                    <polygon class="sell-pol" :class="{'activeChooseSell' : activeChooseSell}" points="0,0 30,40 0,40"  />
                                </svg>
                            </p>
                        </div> -->
                        <!-- v-if="limitActive" -->
                        <template class="" v-if="active"> 
                            <div class="currency-detail-inputs-buy-limit" >
                                <div class="min-price-sell">
                                    <p>کمترین میزان فروش : </p>
                                    <p class="number-toman-teter">{{this.deal[0].minPriceBuy.toLocaleString()}} تومان</p>
                                </div>
                                <div>
                                    <label for="">
                                        <input class="LimitMrket-input price-rial-limit-buy"
                                         value="" type="text" >
                                        <span>قیمت واحد
                                            <span class="toman-orders">(تومان)</span>
                                        </span>
                                    </label>
                                </div>
                                <div>
                                    <label for="">
                                        <input class="LimitMrket-input bit-amount-buy" type="text"
                                        
                                        >
                                        <span>مقدار بیت کوین</span>
                                    </label>
                                    <div >
                                        <label for="" >
                                            <input type="range" value="" min="0" max=""  class="progress progress1 rial-progress-buy" @input="setMaxRange1">
                                            <span class="span1-thumb" ></span>
                                            <span class="span2-thumb" ></span>
                                            <span class="span3-thumb" ></span>
                                            <span class="span4-thumb" ></span>
                                            <span class="span5-thumb" ></span>
                                            <span class="show-percent show-percent1" ></span>
                                        </label>    
                                    </div>
                                </div>
                                <div>
                                    <label for="">
                                        <input class="LimitMrket-input total-price-input-buy" type="text" value=""
                                        
                                        >
                                        <span>قیمت کل
                                             <span class="toman-orders">(تومان)</span>
                                        </span>
                                    </label>
                                    <div class="cash-toman" >
                                        <p>موجودی : </p>
                                        <p class="number-toman-teter">{{this.person[0].tooman.toLocaleString()}} تومان</p>
                                    </div>
                                </div>
                                <div>
                                    <button class="buy-btn" @click="showBuyFun">
                                        خرید
                                    </button>
                                </div>
                            </div>
                            <div class="currency-detail-inputs-sell-limit">
                            <div class="max-price-buy">
                                <p>بیشترین پیشنهاد خرید : </p>
                                <p class="number-toman-teter">172,456,320 تومان</p>
                            </div>
                            <div>
                                <label for="" >
                                    <input  class="LimitMrket-input price-rial-limit-sell" value="" type="text">
                                    <span>قیمت واحد
                                         <span class="toman-orders">(تومان)</span>
                                    </span>
                                </label>
                            </div>
                            <div>
                                <label for="">
                                    <input class="LimitMrket-input bit-amount-sell" type="text">
                                    <span>مقدار بیت کوین </span>
                                </label>
                                <div >
                                    <label for="" >
                                        <input type="range" value="" min="0" max=""  class="progress progress2 rial-progress"
                                        @input="setMaxRange2"
                                         >
                                       
                                        <span class="span1-thumb"></span>
                                        <span class="span2-thumb"></span>
                                        <span class="span3-thumb"></span>
                                        <span class="span4-thumb"></span>
                                        <span class="span5-thumb"></span>
                                        <span class="show-percent show-percent2" ></span>
                                    </label>  
                                </div>
                            </div>
                          <div>
                                <label for="">
                                    <input class="LimitMrket-input total-price-input-sell" type="text">
                                    <span>قیمت کل
                                         <span class="toman-orders">(تومان)</span>
                                    </span>
                                </label>
                                <div class="cash-bit">
                                    <p>موجودی : </p>
                                    <p class="number-toman-teter">0.25 BTN</p>
                                </div>
                            </div>
                            <div>
                                <button class="sell-btn" @click="showSellFun">
                                    فروش
                                </button>
                            </div>
                            </div>
                        </template>
                         <!-- v-if="!limitActive" -->
                        <template class="" v-if="!active"> 
                            <div class="currency-detail-inputs-buy-limit" >
                                <div class="cash-toman" >
                                    <p>موجودی : </p>
                                    <p class="number-toman-teter">{{this.person[0].tooman.toLocaleString()}} تومان</p>
                                </div>
                                <div>
                                    <label for="">
                                        <input class="LimitMrket-input price-rial-market-buy" value="" type="text" >
                                        <span>بهترین قیمت
                                             <span class="toman-orders">(تومان)</span>
                                        </span>
                                    </label>
                                </div>
                                <div>
                                    <label for="">
                                        <input class="LimitMrket-input bit-amount-buy" type="text">
                                        <span>مقدار بیت کوین</span>
                                    </label>
                                    <div >
                                        <label for="" >
                                            <input type="range" value="" min="0" max=""  class="progress progress3 rial-progress-buy" >
                                            <span class="span1-thumb" ></span>
                                            <span class="span2-thumb" ></span>
                                            <span class="span3-thumb" ></span>
                                            <span class="span4-thumb" ></span>
                                            <span class="span5-thumb" ></span>
                                            <span class="show-percent show-percent1" ></span>
                                        </label>    
                                    </div>
                                </div>
                                <div>
                                    <label for="">
                                        <input class="LimitMrket-input total-price-input-buy" type="text" value="">
                                        <span>قیمت کل
                                             <span class="toman-orders">(تومان)</span>
                                        </span>
                                    </label>
                                </div>
                                <div>
                                    <button class="but-btn">
                                        خرید
                                    </button>
                                </div>
                            </div>
                            <div class="currency-detail-inputs-sell-limit" >
                            <div class="max-price-buy">
                                <p>بیشترین پیشنهاد خرید : </p>
                                <p class="number-toman-teter">172,456,320 تومان</p>
                            </div>
                            <div>
                                <label for="">
                                    <input class="LimitMrket-input price-rial-market-sell" value="" type="text">
                                    <span>قیمت واحد
                                         <span class="toman-orders">(تومان)</span>
                                    </span>
                                </label>
                            </div>
                            <div>
                                <label for="">
                                    <input class="LimitMrket-input bit-amount-sell" type="text">
                                    <span>مقدار بیت کوین </span>
                                </label>
                                <div >
                                    <label for="" >
                                        <input type="range" value="" min="0" max=""  class="progress progress2 rial-progress" >
                                       
                                        <span class="span1-thumb"></span>
                                        <span class="span2-thumb"></span>
                                        <span class="span3-thumb"></span>
                                        <span class="span4-thumb"></span>
                                        <span class="span5-thumb"></span>
                                        <span class="show-percent show-percent2" ></span>
                                    </label>  
                                </div>
                            </div>
                          <div>
                                <label for="">
                                    <input class="LimitMrket-input total-price-input-sell" type="text">
                                    <span>قیمت کل
                                         <span class="toman-orders">(تومان)</span>
                                    </span>
                                </label>
                            </div>
                            <div>
                                <button class="sell-btn">
                                    فروش
                                </button>
                            </div>
                        </div>
                        </template>
                    </div>
                    </perfect-scrollbar>
                </div>
</div>
</template>
<script>
// import BuyBit from '../Modals/BuyBit.vue'
// import SellBit from '../Modals/SellBit.vue'

// import Authentication from './Authentication.vue'



export default {
  components: {  },
  name: 'MainProfile',
  
  data : function() {
      return {
          showBuy:true,
          showSell:true,
          active:true,
          limitActive:true,
          activeChooseBuy:true,
          activeChooseSell:false,
          person:[
              {
                  tooman:1000000,
                  bit:0.25,
                  priceRialBuy:[]
              }
          ],
           deal : [
               {
                   minPriceBuy:1000
               }
           ]
      }
      
  },
  mounted:function(){
    //   this.setMaxRange2()
    //     this.setMaxRange1()
    document.getElementsByClassName("price-rial-market-buy")[0].disable=true
  },
  watch:{

  },

//   mounted:function(){
        
//         this.setMaxRange2()
//   },
  methods: {
          showLimit () {
              this.active=!this.active
            //   this.limitActive=!this.limitActive
              console.log(this.active)
          },
          inputPriceBuy() {
              this.person.priceRialBuy.push()
              console.log(this.priceRialBuy)
          },
          chooseBuy () {
              if(!this.activeChooseBuy) {
                  this.activeChooseBuy=true
                  this.activeChooseSell=false
              }
              else
              return true
          },
          chooseSell () {
              if(!this.activeChooseSell) {
                  this.activeChooseSell=true
                  this.activeChooseBuy=false
              }
              else
              return true
          },
          setMaxRange1() {
              
              document.getElementsByClassName("progress1")[0].max=this.person[0].tooman
               const progress = document.querySelector('.progress1');
                progress.addEventListener('input', function() {
               const max = this.max;
               const value=this.value;               
                const total=document.getElementsByClassName("total-price-input-buy")[0]
               const price=document.getElementsByClassName('price-rial-limit-buy')[0].value
            //    const showPercent=document.getElementsByClassName('show-percent1')[0]
               const percent =(value/max*100) - (value/max*100)%1
               if(price !=0) {
                // if(!price) {
               const bit = value/price
               document.getElementsByClassName("bit-amount-buy")[0].value=bit+" "+"BTN"
               total.value=value+" "+"تومان"
            //    showPercent.innerHTML=percent+"%"
            //    showPercent.style.left=`${percent}%`
               console.log(percent)
                // progress.style.background = `linear-gradient(to right, #82CFD0 0%, #82CFD0 ${percent}%, #fff ${percent}%, white 100%)`
               }
               else {
            //    return true
               alert("لطفا قیمت واحد را وارد کنید")
               
               
               }
                
                })
              
          },
          setMaxRange2() {
              document.getElementsByClassName("progress2")[0].max=this.person[0].tooman
               const progress = document.querySelector('.progress2');
                progress.addEventListener('input', function() {
               const max = this.max;
               const value=this.value;               
                const total=document.getElementsByClassName("total-price-input-sell")[0]
               const price=document.getElementsByClassName('price-rial-limit-sell')[0].value
            //    const showPercent=document.getElementsByClassName('show-percent2')[0]
               const percent =(value/max*100) - (value/max*100)%1
               if(price !=0) {
                // if(!price) {
               const bit = value/price
               document.getElementsByClassName("bit-amount-sell")[0].value=bit+" "+"BTN"
               total.value=value+" "+"تومان"
            //    showPercent.innerHTML=percent+"%"
            //    showPercent.style.left=`${percent}%`
               console.log(percent)
                // progress.style.background = `linear-gradient(to right, #82CFD0 0%, #82CFD0 ${percent}%, #fff ${percent}%, white 100%)`
               }
               else {
            //    return true
               alert("لطفا قیمت واحد را وارد کنید")
               
               
               }
                
                })
              
          },
          setMaxRange3() {
            //   document.getElementsByClassName("progress3")[0].max=this.person[0].tooman
                document.getElementsByClassName("price-rial-market-buy")[0].disable=true
               const progress = document.querySelector('.progress2');
                progress.addEventListener('input', function() {
               const max = this.max;
               const value=this.value;               
                const total=document.getElementsByClassName("total-price-input-sell")[0]
               const price=document.getElementsByClassName('price-rial-limit-sell')[0].value
            //    const showPercent=document.getElementsByClassName('show-percent2')[0]
               const percent =(value/max*100) - (value/max*100)%1
               if(price !=0) {
                // if(!price) {
               const bit = value/price
               document.getElementsByClassName("bit-amount-sell")[0].value=bit+" "+"BTN"
               total.value=value+" "+"تومان"
            //    showPercent.innerHTML=percent+"%"
            //    showPercent.style.left=`${percent}%`
               console.log(percent)
                // progress.style.background = `linear-gradient(to right, #82CFD0 0%, #82CFD0 ${percent}%, #fff ${percent}%, white 100%)`
               }
               else {
            //    return true
               alert("لطفا قیمت واحد را وارد کنید")
               
               
               }
                
                })
              
          },
          block1 () {

          },
          showBuyFun() {
              const price=document.getElementsByClassName('price-rial-limit-buy')[0].value
              const totalPrice=document.getElementsByClassName('total-price-input-buy')[0].value
              if(price!=0 && totalPrice!=0){
                this.$emit('childToParent', this.showBuy)  
              console.log(this.showBuy)
              }
              else{
                  alert("لطفا قیمت کل و قیمت واحد را مشخص کنید")
              }
              
          },
          showSellFun() {
              const price=document.getElementsByClassName('price-rial-limit-sell')[0].value
              const bit=document.getElementsByClassName('total-price-input-sell')[0].value
              if(price!=0 && bit!=0){
                  this.$emit('childToParentSell', this.showSell) 
              console.log(this.showSell)
              }
              else{
                  alert("لطفا قیمت کل و قیمت واحد را مشخص کنید")
              }
              
          },
          showTrade (event) {
        //   if(!this.activeTrade) {
        //   this.activeTrade=!this.activeTrade
        //   this.activeRial=false
        //   this.activeTrade=false
        //   this.activeLogin=false
        //   }
        //   else
        //   return true

        //   this.activeTrade=!this.activeTrade
        var target=event.target || event.srcElement;
        var buttonList=document.querySelectorAll(".history-item");
        var item=document.getElementsByClassName("history-items-container")[target.id];
        var items=document.querySelectorAll(".history-items-container-active");
        console.log(item)
        buttonList.forEach(function(button) {
            if (button === target && !button.classList.contains("active") ) 
                return button.classList.add("active")
                 items.forEach(function(itemActive){itemActive.classList.remove("history-items-container-active")})
                 item.classList.add("history-items-container-active")
            
            // else {
            //     button.classList.remove("active")
            //     // item.classList.remove("history-items-container-active")
            // }
            return button.classList.remove("active")
        })
    //   },
    //   showRial() {
    //       if(!this.activeRial) {
    //           this.activeRial=!this.activeRial
    //       this.activeTrade=false
          
    //       this.activeTrade=false
    //       this.activeLogin=false
    //       }
    //       else
    //       return true
    // console.log(event)
      },
        //   putcash(a, p) {
        //         var up = 0
        //         if (this.realprice) {
        //             up = this.realprice
        //         } else {
        //             up = this.unitprice
        //         }
        //         if (this.unitprice || this.realprice) {
        //             this.percent = p
        //             var e = (this.percent * (this.cash * (1 - this.wage / 100))) / up
        //             this.state.buyAmount = e.toFixed(a)
        //             this.calculate()
        //             this.danger = 0
        //         } else {
        //             this.danger = 1
        //         }
        //     },
      },
      
    //   watch: {
    // showBuy: function() {
    //   this.$emit("child-showBuy", this.showBuy);
    // }
  }

  
</script>

<style scoped>
.limitMarket-template {
    display: none;
}
.mainProfile-container {
    width: 95%;
    display: flex;
    margin: auto;
    column-gap: 10px;
}
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;

    }
    
   .LimitMrket-top {
       background: white;
       width: 160px;
   }
    .currency-detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        row-gap: 10px;
    }
    .currency-detail > div {
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.35));
        /* background: white; */
    }


    .detail-status > p {
        font-weight: bold;
        font-size: 14px;
        padding: 0 10px;
    }
    .buy-status , .sell-status {
        min-width: 250px;
    }
    .status-title {
        font-weight: bold;
        font-size: 14px;
        margin: 0;
    }
    .status-title+p {
        margin: 0;
        font-weight: normal;
        font-size: 12px;
    }
    .currency-detail  {
        height: 100%;
        border-radius: 2px;
        
    }
    .currency-list {
        height: 98%;
        border-radius: 2px;
    }
    
    .currency-detail-inputs {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-self: normal;
        height: 46vh;
    }
    .activeLimit {
        background: white;
        cursor: pointer;
        text-align: center;
        width: 80px;
    }
    .deActiveLimit {
        background:rgba(15, 15, 15, 0.1);
        cursor: pointer;
        text-align: center;
        width: 80px;
    }
    .activeLimit > p , .deActiveLimit > p {
        margin: 2px;
    }
    .currency-detail-inputs  .LimitMrket-top ,  .currency-detail-inputs  .currency-detail-inputs-deal {
        display: flex;
    }
    .currency-detail-inputs > div > div  {
        /* background: white; */
        /* width: 80px; */
        /* text-align: center; */
        font-weight: bold;
        font-size: 16px;
        /* align-self: baseline; */
    }
    .currency-detail-inputs-deal {
        flex-direction: row;
        justify-content: space-between;
        /* flex-wrap: wrap; */
        /* column-gap: 20px; */
        /* padding: 0 20px 20px 20px; */
        row-gap: 20px;
        background: white;
        /* min-height: 320px;  */
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        /* height: 320px;*/
    }
    .currency-detail-inputs-deal::-webkit-scrollbar {
        display: none;
    }
    .currency-detail-inputs-deal > div {
        display: flex;
        align-self: normal;
        flex-grow: 1;
        flex-direction: column;

    }
    .currency-detail-inputs-sell-limit > div > button{
        background: #E0342B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        width: 100%;
        height: 40px;
    }
    .currency-detail-inputs-sell-market > div > button  {
        background: #E0342B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        width: 100%;
        height: 40px;
    }
    .currency-detail-inputs-buy-limit > div > button , .currency-detail-inputs-buy-market > div > button{
        background: #058C2B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        width: 100%;
        height: 40px;
    }
    .currency-detail-inputs-sell-limit , .currency-detail-inputs-buy-limit , .currency-detail-inputs-sell-market , .currency-detail-inputs-buy-market {
        row-gap: 10px;
        min-width: 50%;
        box-sizing: border-box;
        padding: 13px;
    }
    .currency-detail-inputs-sell-limit , .currency-detail-inputs-buy-limit {
        height: 340px;
    }
    .currency-detail-inputs-sell-limit > div >label > input , .currency-detail-inputs-buy-limit > div >label > input , .currency-detail-inputs-sell-market > div >label > input , .currency-detail-inputs-buy-market > div >label > input{
        width: 100%;
        height: 35px;
        border-radius: 2px;
        box-sizing: border-box;
        margin-top: 10px;
        
    }
    /* .currency-detail-inputs-buy-limit > div >label > input ,.currency-detail-inputs-buy-market > div >label > input {
        border: 2px solid ;
    }
    .currency-detail-inputs-sell-limit > div >label > input , .currency-detail-inputs-sell-market > div >label > input {
        border:2px solid ;
    } */
    .currency-detail-inputs-sell-limit > div >label  , .currency-detail-inputs-buy-limit > div >label  , .currency-detail-inputs-sell-market > div >label  , .currency-detail-inputs-buy-market > div >label{
        position: relative;
    }
    .currency-detail-inputs-sell-limit > div >label >span  , .currency-detail-inputs-buy-limit > div >label > span , .currency-detail-inputs-sell-market > div >label >span  , .currency-detail-inputs-buy-market > div >label > span {
        position: absolute;
        top: -17px;
    right: 10px;
    background: white;
    padding: 0 5px;
    font-weight: bold;
font-size: 14px;
    }
    .max-price-buy > p , .min-price-sell >p , .cash-toman >p , .cash-bit>p {
        font-weight: normal;
        font-size: 12px;
        margin: 10px;
        display: inline-block;
        /* border-bottom: 1px solid black; */
    }
    .number-toman-teter {
        border-bottom: 1px solid black;
    }
    .line {
        max-width: 100%;
    }
    .LimitMrket-input {
        height: 30px;
    }
    .progress {
  /* background: linear-gradient(to right, #82CFD0 0%, #82CFD0 40%, #fff 40%, #fff 100%); */
  background: rgba(0, 172, 193, 0.3);
  border: 0;
  border-radius: 8px;
  height: 7px;
  /* width: 400px; */
  width: 100%;
  outline: none;
  transition: background 450ms ease-in;
  -webkit-appearance: none;
  direction: ltr;
}

.progress::-webkit-slider-thumb {
  width: 20px;
  height: 30px;
  border-radius: 50%;
  -webkit-appearance: none;
  cursor: ew-resize;
  background: #00ACC1;
  z-index: 100;
}
.rial-progress {
    position: relative;
    /* height: 0; */
    background: rgba(0, 172, 193, 0.3);
    border: 0;
    z-index: 1;
}
.rial-progress::before {
    /* content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background: url("../../assets/Rectangle 82.svg")no-repeat; */
  
}
.cash-toman > p , .cash-bit > p , .min-price-sell > p , .max-price-buy > p {
    margin: 0;
    /* margin-top: 10px; */
}
.min-price-sell , .min-price-buy , .max-price-sell , .max-price-buy , .cash-bit , .cash-toman {
    height: -webkit-fill-available;
    height: 100%;
}
label {
    position: relative;
}
.range-svg {
    position: absolute;
    width: 100px;
    height: 10px;

}
.toman-orders {
    font-weight: normal;
font-size: 12px;
}
.price-rial-limit-buy , .price-rial-market-buy  {
    border:2px solid rgba(5, 140, 43, 0.8);
}
.price-rial-limit-sell , .price-rial-market-sell {
    border:2px solid rgba(224, 52, 43, 0.8);
}
.total-price-input-sell {
border:2px solid rgba(224, 52, 43, 0.5);
}
.total-price-input-buy {
    border:2px solid rgba(5, 140, 43, 0.5);
}
.bit-amount-buy {
    border:2px solid rgba(5, 140, 43, 1);
}
.bit-amount-sell {
    border:2px solid rgba(224, 52, 43, 1);
}








input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 0px;
height: 0px;
  border-radius: 50%;
  background:#00ACC1;
  cursor: pointer;
  /* margin-top: -14px;  */
position: absolute;
bottom: -20px;
/* display: none; */
z-index: 10;
}
.span1-thumb {
width: 6px;
height: 6px;
  border-radius: 50%;
  background: #00ACC1;
  cursor: pointer;
  /* margin-top: -14px;  */
position: absolute;
bottom: 9px;
left: 0;
}
.span2-thumb {
width: 7px;
height: 7px;
  border-radius: 50%;
  background: #00ACC1;
  cursor: pointer;
  /* margin-top: -14px;  */
position: absolute;
bottom: 7px;
left: 25%;
}
.span3-thumb {
width: 8px;
height: 8px;
  border-radius: 50%;
  background: #00ACC1;
  cursor: pointer;
  /* margin-top: -14px;  */
position: absolute;
bottom: 7px;
left: 50%;
}
.span4-thumb {
width: 9px;
height: 9px;
  border-radius: 50%;
  background: #00ACC1;
  cursor: pointer;
  /* margin-top: -14px;  */
position: absolute;
bottom: 7px;
left: 75%;
}
.span5-thumb {
width: 10px;
height: 10px;
  border-radius: 50%;
  background: #00ACC1;
  cursor: pointer;
  /* margin-top: -14px;  */
position: absolute;
bottom: 7px;
/* left: 100%; */
right: 0;
}
span {
    font-size: 1vw;
}
.choose-buy-sell {
    display: none !important;
    justify-content: center;
    column-gap: 40px;
    
}
.choose-buy-sell > p {
    width: 25%;
    height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 0;
}

.activeChooseSell {
    background: rgba(224, 52, 43, 1);
}
.choose-buy {
    color:white;
    background: rgba(5, 140, 43, 0.3);
}
.choose-sell {
    color:white;
    background: rgba(224, 52, 43, 0.3);
}
.buy-svg {
position: absolute;
left: -30px;
}
.buy-pol {
    fill: rgba(5, 140, 43, 0.3);
}
.sell-svg {
position: absolute;
right: -30px;
}
.sell-pol {
    fill: rgba(224, 52, 43, 0.3);
}
.activeChooseBuy {
    background: rgba(5, 140, 43, 1);
    fill: rgba(5, 140, 43, 1);
}
.activeChooseSell {
    background: rgba(224, 52, 43, 1);
    fill: rgba(224, 52, 43, 1);
}
/* .show-percent {
    width: 20px;
height: 20px;
  border-radius: 50%;
  background: #00ACC1;
  cursor: pointer;

position: absolute;
bottom: 2px;

left: 0;
font-size: 10px;
color: white;
display: flex;
align-items: center;
} */
    
    /* .sidebar {
            display: none;
        }
    @media only screen and (max-width: 900px) {
        .sidebar {
            display: none;
        }
    } */
    @media only screen and (max-width: 500px) {
        .currency-detail-inputs-deal span {
            /* flex-wrap: wrap;
            flex-direction: column; */
            font-size: 8px;
        }
        .LimitMrket-input + span:first-of-type {
            font-size: 12px !important;
        }
        .choose-buy-sell {
            display: flex !important;
            flex-direction: row !important;
        }
    }
    
</style>
