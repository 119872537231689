<template @load="autoClose">
    <transition name="modal-fade" >
        <div class="modal-backdrop">
          <div class="modal"
            role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            
    
            <section
              class="modal-body"
              id="modalDescription"
            >
              <slot name="body">
                  <div>
                    <img src="@/assets/SuccessBuy.png" alt="">
                  </div>
                  <div>
                    <p>خرید با موفقیت انجام شد</p>
                  </div>
              </slot>
              
            </section>
    
            
          </div>
        </div>
      </transition>
</template>

<script>
  export default {
    name: 'Modal',
    mounted() {
        this.autoClose()
    },
    methods: {
      close() {
        this.$emit('close');
      },
      autoClose() {
            // setTimeout(function() { 
            //      this.$emit('close');
            // }, 3000);
            this.$emit('close');
      }
    },
  };
</script>
    
<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 520px;
    height: 133px;
    align-items: center;
    justify-content: center;
  }
  .modal-body {
    position: relative;
    padding: 10px 30px;
    display: flex;
    flex-direction: row;
    direction: rtl;
    column-gap: 10px;
  }
  .modal-body >slot > div {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
    font-family: shabnam;
  }
  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }



</style>