<template>
    <div class="backgroung">
    <Navbar/>
    <Head/>
    <Box/>
    <Options/>
    <Application/>
    <Footer/>
    </div>
</template>

<script>
import Navbar from "../Navbar.vue";
import Application from './Application.vue';
import Box from './Box.vue';
import Footer from './Footer.vue';
import Head from './Head.vue';
import Options from './Options.vue';
export default {
  name: 'firstPage',
  components :{
    Box,
    Head,
    Footer,
    Application,
    Options,
    Navbar
  }}
</script>

<style >
 .backgroung {
   background-image: linear-gradient(#f1feff, white);
   display: grid;
   row-gap: 20px;
 }
</style>
