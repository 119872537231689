<template>
    <div class="currency-list">
        <perfect-scrollbar>
                <div class="currency-list-container">
                    
                <table>
                    <tr class="currency-list-head-row">
                        <td>
                            <p>نام ارز </p>
                        </td>
                        <td>
                            <p>قیمت</p>
                        </td>
                        <td>
                            <p>درصد تغییرات</p>
                        </td>
                        <td class="currency-list-row-amount">
                            <p>حجم شبانه روز گذشته</p>
                        </td>
                    </tr>
                    <tr class="currency-list-row" v-for="(Market , index) in MarketList" :key="index" @click="select(index)">
                        <td class="currency-list-row-title">
                            <div class="currency-list-row-image">
                                <img v-bind:src=Market.image alt="">
                            </div>
                            <div class="currency-list-row-name">
                                <p class="crypto-name">{{Market.name}}</p>
                                <p class="ENname">{{Market.ENname}}</p>
                            </div>
                        </td>
                        <td class="currency-list-row-price">
                            <p class="crypto-price" v-if="unit.tomanMarketActive">{{Market.price.toLocaleString()}}</p>
                            <p class="crypto-price" v-if="unit.tomanMarketDeactive"> 5.5</p>
                        </td>
                        <td class="currency-list-row-change">
                            <div  :class="{'currency-change-red':Market.percentChange < 0 , 'currency-change-green':Market.percentChange > 0 }">
                                <p class="crypto-change"> % {{Math.abs(Market.percentChange.toLocaleString())}} 
                                    <span v-if="Market.percentChange > 0"> + </span>
                                    <span v-if="Market.percentChange < 0"> - </span>
                                    </p>
                            </div>
                        </td>
                        <td class="currency-list-row-amount">
                            <p class="crypto-amount">{{Market.amountDay.toLocaleString()}}</p>
                        </td>
                    </tr>
                </table>
                
                </div>
                </perfect-scrollbar>
            </div>
</template>

<script>
import Market from "../../library/Market.js"
export default {
  name: 'HomePage',
  components :{
    
  },
  props:[
      'unit'
  ],
  data() {
      return {
          MarketList:Market
      }
  },
    methods: {
        select(index) {
            this.$emit('selectCrypto' , index)
            console.log(index)
        }
    }
  }
</script>

<style lang="scss" scoped>
  .currency-list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: top;
        flex-grow: 4;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        /* min-width: 650px; */
        // min-width: 55%;
        width: 45%;
        height: 98%;
        overflow: scroll;
        // max-height: 700px;
        /* overflow-x: scroll; */
    }
    .currency-list::-webkit-scrollbar {
  display: none;
}

.currency-list {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
    .currency-list-head-row {
        border-bottom: 1px solid black;
        font-weight: bold;
        font-size: 14px;
        height: 40px;
            td p {
                margin: 0;
                color: #0F0F0F;
            }
    }
    .currency-list-head-row > td > p {
        opacity: 0.8;
    }
    .currency-list-head-row > td {
        width: 25%;
    }
    .currency-list-head-row > td:first-of-type {
        text-align: right;
    padding-right: 40px;
    }
    .currency-list-container {
        width: 100%;
        /* min-width: 400px; */
    }
    .currency-list-row {
        /* display: flex; */
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: space-between;
        border-bottom: 1px solid gainsboro;
        align-items: center;
        padding: 5px;
        font-weight: bold;
font-size: 14px;
cursor: pointer;
    }
    .currency-list-row:hover {
        background: rgba(15, 15, 15, 0.05);
    }
    .currency-list-head-row , .currency-list-row , .currency-list-row > td {
        text-align: center;
    }
    .currency-list-row-image {
        display: flex;
        align-items: center;
    }
    .currency-list-row-image > img {
        width: 35px;
        height: 35px;
    }.currency-list-row-name {
        display: flex;
        flex-direction: column;
        row-gap: 5px;
    }
    .currency-list-row-name > p {
        margin: 0;
        text-align: right;
        width: max-content;
    }
    .ENname {
        text-align: right;
        font-weight: 500;
font-size: 14px;
    }
    .currency-list-row-title {
        display: flex;
        column-gap: 15px;
            padding: 5px 0;
        /* justify-content: center; */
        margin-right: 15px;
    }
    .currency-list-head-row {
        /* display: flex; */
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(15, 15, 15, 0.8);
    }
    .currency-list {
        /* height: 100%; */
        border-radius: 2px;
        
    }
    .currency-change-red {
        background: #E0342B;
        border-radius: 44px;
        width: 75px;
        height: 30px;
        color: white;
        font-weight: bold;
font-size: 14px;
display: flex;
justify-content: center;
align-items: center;
margin: auto;
    }
    .currency-change-green {
        background: #058C2B;
        border-radius: 44px;
        width: 75px;
        height: 30px;
        color: white;
        font-weight: bold;
font-size: 14px;
display: flex;
justify-content: center;
align-items: center;
margin: auto;
    }
    table  {
        width: 100%;
        border-collapse: collapse;
    }
    .crypto-name , .crypto-price , .crypto-amount , .crypto-change , .currency-list-head-row{
        font-size: clamp(10px , 1vw , 14px);
        font-weight: bold;
    }
    .ENname {
        font-size: clamp(10px , 1vw , 14px);
    }
    @media screen and (max-width: 1000px) {
        .currency-list {
    height: auto !important;
}
    }
    @media screen and (max-width: 600px) {
        .currency-list-row-amount , .currency-list-row-image {
            display: none;
        }
        .currency-list {
            height: auto !important;
        }
    }
</style>
