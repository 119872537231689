<template>
    <div class="portfolio-main">
                <div class="title-portfolio"  v-for="(Portfo , index) in PortfolioInfo" :key="index">
                    <div class="order5">
                        <p>مجموع پورتفوی</p>
                        <p class="title-portfolio-numbers">{{Portfo.totalPortfolio.toLocaleString()}} تومان </p>
                        <!-- <img v-bind:src=this.info[0].image alt=""> -->
                    </div>
                    <div class="order1">
                        <p>مجموع خرید </p>
                        <p class="title-portfolio-numbers">{{Portfo.totalBuy.toLocaleString()}} تومان</p>
                    </div>
                    <div class="order3">
                        <p>ارزش فعلی</p>
                        <p class="title-portfolio-numbers">{{Portfo.value.toLocaleString()}}تومان</p>
                    </div>
                    <div class="order2">
                        <p>درصد کل سود و زیان</p>
                        
                        <!-- :class="{'currency-percentChange-positive':this.incomePercent>0 , 'currency-percentChange-negative':this.incomePercent < 0}">{{this.incomePercent}}%  -->
                        <p class="title-portfolio-numbers" :class="{'positive-change': Portfo.percentCost > 0 , 'negative-change':Portfo.percentCost < 0}">
                         <span v-if="Portfo.percentCost > 0">+</span>
                         <span v-if="Portfo.percentCost < 0">-</span>
                          {{Math.abs(Portfo.percentCost.toLocaleString())}} %
                         </p>
                    </div>
                    <div class="order4">
                        <p>مقدار کل سود و زیان</p>
                        
                        <p class="title-portfolio-numbers"
                        :class="{'positive-change': Portfo.percentCost > 0 , 'negative-change':Portfo.percentCost < 0}"
                        >{{Portfo.amountCost.toLocaleString()}} تومان</p>
                    </div>
                </div>
                
                <div class="portfolio-list">
                    <perfect-scrollbar>
                     <table class="portfolio-table">
                         
                             <tr class="tr-head">
                                 <td>نام ارز</td>
                                 <td class="amount-buy">مجموع خرید<p class="portfolio-thead-unit">
                                     <span v-if="unit.tomanMarketActive">تومان</span>
                                     <span v-if="unit.tomanMarketDeactive">تتر</span>
                                     </p></td>
                                 <td class="crrent-price">ارزش فعلی<p class="portfolio-thead-unit">
                                     <span v-if="unit.tomanMarketActive">تومان</span>
                                     <span v-if="unit.tomanMarketDeactive">تتر</span>
                                      </p></td>
                                 <td>درصد سود و زیان</td>
                                 <td>مقدار سود و زیان<p class="portfolio-thead-unit">
                                     <span v-if="unit.tomanMarketActive">تومان</span>
                                     <span v-if="unit.tomanMarketDeactive">تتر</span>
                                     </p></td>
                             </tr>
                         
                         
                             <tr v-for="(Market ,index) in MarketList" :key="index" @click="select(index)">
                                 <td class="portfolio-td-name">
                                     <div>
                                         <img v-bind:src=Market.image alt="">
                                         <!-- {{currency.image}} -->
                                     </div>
                                     <div>
                                         <div>
                                             <p class="table-currency-name">{{Market.name}}</p>
                                             <p :class="{'currency-percentChange-negative':Market.percentChange < 0 , 'currency-percentChange-positive':Market.percentChange > 0 }"
                                                  >
                                                  {{Math.abs(Market.percentChange)}}%
                                                  <span v-if="Market.percentChange > 0">+</span>
                                                  <span v-if="Market.percentChange < 0">-</span>
                                                  </p>
                                         </div>
                                         <div>
                                             <p class="table-currency-price">0.065 تومان</p>
                                         </div>
                                     </div>
                                 </td>
                                 <td class="amount-buy">
                                     <span v-if="unit.tomanMarketActive">{{Market.buyAmount.toLocaleString()}}</span>
                                     <span v-if="unit.tomanMarketDeactive">12</span> 
                                     </td>
                                 <td class="crrent-price" :class="{'currency-percentChange-negative':Market.incomePercent < 0 , 'currency-percentChange-positive':Market.incomePercent > 0 }">
                                     <span v-if="unit.tomanMarketActive">{{Market.price.toLocaleString()}}</span>
                                     <span v-if="unit.tomanMarketDeactive">6.5</span>
                                     </td>
                                 <td :class="{'currency-percentChange-negative':Market.incomePercent < 0 , 'currency-percentChange-positive':Market.incomePercent > 0 }">
                                    
                                     % {{Math.abs(Market.incomePercent)}} 
                                     <span v-if="Market.incomePercent > 0">+</span>
                                    <span v-if="Market.incomePercent < 0">-</span>
                                     </td>
                                 <td :class="{'currency-percentChange-negative':Market.incomePercent < 0 , 'currency-percentChange-positive':Market.incomePercent > 0 }">
                                     <span v-if="unit.tomanMarketActive">{{Market.incomeamount.toLocaleString()}}</span>
                                     <span v-if="unit.tomanMarketDeactive">24</span>
                                     </td>
                             </tr>         
                     </table>
                     </perfect-scrollbar>
                </div>
                
            </div>
</template>

<script>
import Market from "../../library/Market.js"
import Portfo from "../../library/Portfo.js"
export default {
  name: 'HomePage',
  props:[
      'unit'
  ],
  data() {
      return {
          MarketList:Market,
          PortfolioInfo:Portfo
      }
  },
  methods: {
       select(index) {
            this.$emit('selectCrypto' , index)
            console.log(index)
        }
  }
  }
</script>

<style scoped>
  .portfolio-main {
        display: flex;
        flex-direction: column;
        /* flex-wrap: wrap; */
        justify-content: space-between;
        align-items: top;
        flex-grow: 4;
        /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); */
        /* min-width: 650px; */
        width: 45%;
        /* overflow-x: scroll; */
        row-gap: 10px;
        justify-content: flex-start;
        height: 98%;
    }
    .title-portfolio , .portfolio-list {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        -ms-overflow-style: none; 
        scrollbar-width: none;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
    }
    .portfolio-list {
        height: 92%;
        overflow: scroll;
        /* max-height: 600px; */
    }
    .portfolio-list::-webkit-scrollbar {
        display: none;
    }
    .title-portfolio-numbers {
        font-weight: 500;
        font-size: clamp(12px , 1vw , 14px);
        text-align: center;
        margin: 0;
    }
    .title-portfolio {
        min-height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 20px;
    /* padding: 14px; */
    padding: 10px 5px;
    min-height: 70px;
    box-sizing: border-box;
    }
    .title-portfolio > div > p:first-of-type {
        margin: 0;
        font-weight: bold;
        font-size:clamp(12px , 1vw , 14px);
        /* font-size: 0.95vw; */
        text-align: center;
    }
    .portfolio-table {
        border-collapse: collapse;
        text-align: center;
        /* min-width: 500px; */
        width: 95%;
        margin: 0 auto;
        table-layout: fixed;
    }
    .tr-head > td:first-of-type {
        width: 200px;
        text-align: right;
        box-sizing: border-box;
        padding-right: 40px;
    }
    .tr-head > td {
        vertical-align: baseline;
        box-sizing: border-box;
        padding-top: 10px;
    }
    .portfolio-thead-unit {
        margin: 0;
        font-weight: normal;
/* font-size: 12px; */
font-size: 0.8vw;
    }
    .portfolio-table > thead {
        font-weight: bold;
        font-size: 14px;
        border-bottom: 1px solid black;
    }
    .portfolio-table  > tr {
        height: 56px;
        /* display: grid; */
    /* grid-template-columns: 25% 20% 20% 15% 20%; */
    grid-template-columns: minmax(25%,1fr) minmax(20%,1fr) minmax(20%,1fr) minmax(15%,1fr) minmax(20%,1fr);
    align-items: center;
    padding: 5px 10px;
    }
    .portfolio-table  > tr:first-of-type {
        border-bottom: 1px solid black;
    }
    .portfolio-table  > tr {
        border-bottom: 1px solid rgba(15, 15, 15, 0.2);
    }
    .portfolio-table  > tr > td {
        font-weight: bold;
        font-size:clamp(12px , 1vw , 14px);
        /* font-size: 1vw; */
        margin-top: 2px;
    }
    .portfolio-td-name {
        display: flex;
        align-items: center;
        column-gap: 10px;
        /* justify-content: center; */
    }
    .portfolio-td-name > div {
        display: flex;
    flex-direction: column;
    /* row-gap: 10px; */
    row-gap: 5px;
    }
    .portfolio-td-name > div > img {
        /* width: 35px;
        height: 35px; */
        width: 2.5vw;
    }
    .portfolio-td-name > div > div {
        display: flex;
        column-gap: 10px;
    }
    .portfolio-td-name > div > div > p {
        margin: 0;
        text-align: right;
    }
    .portfolio-main {
        /* height: 100%; */
        border-radius: 2px;
        width: 45%;
        flex-grow: 4;
        
    }
    .currency-percentChange-negative {
        color: #E0342B;
        /* font-size: 14px; */
        /* font-size: 1.2vw; */
        font-size: clamp(12px , 1.2vw , 14px);
        /* direction: ltr; */
        /* font-weight: 500; */
    }
    .currency-percentChange-positive {
        color: #058C2B;
        /* font-size: 14px; */
        /* font-size: 1.2vw; */
        font-size: clamp(12px , 1.2vw , 14px);
        /* font-weight: 500; */
    }
    .currency-information {
        display: flex;
        /* align-items: flex-end; */
        justify-content: space-between;
        gap: 10px;
        flex-grow: 1;
        /* width: 98%; */
        /* flex-wrap: wrap; */
        width: 100%;
    }
     .table-currency-name {
        font-weight: bold;
        font-size:clamp(12px , 1vw , 14px);
        /* font-size: 1vw; */
        column-gap: 10px;
    }
    .table-currency-price {
        font-weight: 500;
        /* font-size: 14px; */
        font-size: clamp(12px , 1vw , 14px);
        column-gap: 10px;
    }
    .currency-change {
        background: #E0342B;
        border-radius: 44px;
        width: 75px;
        height: 30px;
        color: white;
        font-weight: bold;
font-size: 14px;
display: flex;
justify-content: center;
align-items: center;
    }
    .positive-change {
        color: #058C2B;
    }
    .negative-change {
        color: #E0342B;
        direction: ltr;
    }
    @media only screen and (max-width: 1050px) {
        .tr-head > td:first-of-type {
            width: 150px;
        }
    }
    @media only screen and (max-width: 1000px) {
        .title-portfolio > div > p {
            font-size: 14px;
        }
        .title-portfolio {
            row-gap: 40px;
            min-height: 80px;
            height: auto;
        }
        .title-portfolio-numbers {
            font-size: initial;
        }
        .title-portfolio > div > p:first-of-type {
            font-size: initial;
        }
        .portfolio-table  > tr > td {
            font-size: 14px;
        }
        .portfolio-table{
            font-size: initial;
        }
        .portfolio-td-name > div > img {
            width: auto;
            display: none;
        }
        .table-currency-name , .table-currency-price , .portfolio-thead-unit{
            font-size: 12px;
        }
        .currency-percentChange-negative , .currency-percentChange-positive {
            font-size: 13px;
        }
        .portfolio-list , .portfolio-main {
            height: auto;
        }
    }
    @media only screen and (max-width: 688px) {
        .order1 {
        order: 1;
    }
    .order2 {
        order:2
    }
    .order3 {
        order: 3;
    }
    .order4 {
        order: 4;
    }
    .order5 {
        order: 5;
    }
    }
    @media only screen and (max-width: 600px) {
        .portfolio-table {
            min-width: 600px;
            overflow: scroll;
        }
    }
</style>
