<template>
    <div class="loginform">
        <p class="form-head">فراموشی رمز عبور</p>
        <hr>
        <form  action=""  id="loginform">
        <label class="input">
            <button class="show-pass" @click="showPassword1">
                <img src="@/assets/showpass.png" >
                </button>
        <input :class="{ 'input__field': activePass , 'input__error': errorPass}" @input="toActive" :type="type1"  v-model="pass1"/>
        <span class="input__label">رمز عبور جدید</span>
        <p id="error" v-if="pass1Error.length" >{{pass1Error[0]}}</p>
        </label>
        <label class="input">
            <button class="show-pass" @click="showPassword2">
                <img src="@/assets/showpass.png" >
                </button>
        <input :class="{ 'input__field': activePass , 'input__error': errorPass}" @input="toActive" :type="type2"  v-model="pass2"/>
        <span class="input__label">تکرار رمز عبور </span>
        <p id="error" v-if="pass2Error.length" >{{pass2Error[0]}}</p>
        </label>
        <p id="error" v-if="notSameErroe.length" >{{notSameErroe[0]}}</p>
        <!-- <router-link to="/profile"><Login id="login-form" v-on:click="checkForm"/></router-link> -->
        <button class="login" type="submit" v-on:click="checkForm" >تغییر رمز عبور</button>
        </form>
    </div>
    
</template>

<script>
import router from "../router/index.js"

export default {
  components: {  },
  name: 'LoginForm',
  data:function () {
      return{
                pass1:null,
                pass1Error:[],
                pass2:null,
                pass2Error:[],
                notSameErroe:[],
                    activeEmain: true,
                    errorEmail: false,
                    activePass:true,
                    errorPass:false,
                    type1: 'password',
                    type2: 'password',
                    btnText: 'Show Password'
      }
  },
   methods:{
    checkForm (e) {
      if(this.pass1 && this.pass2 && this.pass1===this.pass2) {
          router.push("/login")
      }
       if(this.pass1!==this.pass2){
           this.notSameErroe.push("رمز عبور جدید و تکرار آن باید یکسان باشند")
       }
      if(!this.pass1){
          this.pass1Error.push("نام کاربری وارد نشده است");
          this.activeEmain=false;
          this.errorEmail=true;
      } 
      if(!this.pass2) {
          this.pass2Error.push("زمز عبور وارد نشده است");
          this.activePass=false;
          this.errorPass=true;
      } 
      
      e.preventDefault();
    // console.log("bb")
    },
    toActive() {
        this.activeEmain=true;
          this.errorEmail=false;
          this.activePass=true;
          this.errorPass=false;
          this.userError=[];
          this.passError=[];
        this.codeError=[];
        this.notSameErroe=[];
    },
    showPassword1(e) {
			if(this.type1 === 'password') {
				this.type1 = 'text'
				this.btnText = 'Hide Password'
			} else {
				this.type1 = 'password'
				this.btnText = 'Show Password'
			}
            e.preventDefault();
		},
        showPassword2(e) {
			if(this.type2 === 'password') {
				this.type2 = 'text'
				this.btnText = 'Hide Password'
			} else {
				this.type2 = 'password'
				this.btnText = 'Show Password'
			}
             e.preventDefault();
		}
  }
}
</script>

<style scoped>
p {
    margin: 0;
}
    .loginform {
        display: grid;
        row-gap: 10px;
        margin-top: 20px;
    }
    .form-head {
        font-weight: 500;
        font-size: 24px;
        margin: 0;
        /* padding-bottom: 20px; */
        /* border-bottom: 1px solid #00ACC1; */
    }
    hr {
        background-image: linear-gradient(90deg, white, #00ACC1, white);
        border: 0;
        height: 1.5px;
    }
    #loginform {
        text-align: right;
        display: grid;
        flex-direction: column;
        row-gap: 10px;
      width: 90%;
        margin: auto
    }
    #numberform {
        display: inline-block;
        vertical-align: bottom;
    }
    #putnumber {
        display: inline-block;
    }
    #login-form {
        margin: auto;
    }
    #google {
        background-color: white;
        border: 1px solid #00ACC1;
        box-sizing: border-box;
        border-radius: 25px;
        font-weight: bold;
        height:45px
    }
    #goole-p {
        display: inline-block;
    }
    #or {
        font-weight: bold;
        /* margin: 10px; */
        text-align: center;
    }
    #google-image {
        vertical-align: middle;
        margin-left: 10px;
    }
    #sub-p {
        text-align: right;
    }
    a{
        text-decoration: none;
        color:#00ACC1;
    }
    #forget-p {
        text-align: left;
        margin: 0;
        position: absolute;
        bottom: -10px;
        left: 0;
        font-size: 12px;
        /* transform: translateY(30px); */
    }
    .input__label__image {
        position: absolute;
        padding: 0 5px 0 10px;
        white-space: nowrap;
        transform-origin: 0 0;
        background-color:white;
        transition: transform 120ms ease-in;
        font-weight: 500;
        font-size: 16px;
        right: 20px;
        top: 0; 
        transform: translateY(-60%);
    }
    .input__field__image {
        height: 45px;
        border: 1px solid #00ACC1;
        box-sizing: border-box;
        border-radius:25px;
        vertical-align: text-top;
        font-size: 16px;
        display: flex;
    flex-grow: 1;
    }
    #code-number {
        /* margin: 20px; */
        direction: rtl;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 400px;
    }
    #google {
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input__field , .input__field__image {
        font-size: 16px;
    }
    .login {
        /* width: 100%; */
        height: 45px;
        background: #00ACC1;
        border-radius: 79px;
        border: 0;
        color: white;
        margin: 0 5px 0 0;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        border-radius: 35px;
        font-weight: 700;
    }
    #error{
        color: red;
        font-weight: 500;
        font-size: 14px;
        opacity: 0.8;
        margin-bottom:5px;
    }
    .input__error {
        font-size: 16px;
        margin: 10px 0 10px 0;
        border: 1px solid red;
        box-sizing: border-box;
        display: block;
        width: 100%;
        height: 45px;
        padding: 10px;
        border-radius:25px;
        position: relative;
    }
    .show-pass {
        position: absolute;
        top: 20px;
        left: 0;
        background: transparent;
        border: 0;
    }
</style>