var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"currency-list"},[_c('div',{staticClass:"currency-list-container"},[_c('vue-trading-view',{staticStyle:{"height":"550px"},attrs:{"options":{
                            height:'100%',
                            autosize:true,
                                    
                                    timezone: 'Asia/Tehran',
                                    style: '1',
                                    
                                    locale: 'fa_IR',
                                    enable_publishing: true,
                                    withdateranges: true,
                                    range: 'ytd',
                                    hide_side_toolbar: false,
                                    allow_symbol_change: true,
                                    enabled_features: [
                                        'header_fullscreen_button'
                                        ],
                                    disabled_features: [
                                        'border_around_the_chart',
                                        'remove_library_container_border' ],
                                    loading_screen:{
                                        backgroundColor:'#00ff00',
                                        foregroundColor: '#000000',
                                        },
                                    overrides: {
                                        background: '#256'
                                    }
                        }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }