<template>
    <div class="loginform">
        <p class="form-head">ثبت نام</p>
        <hr>
        <div class="sub-form-container">
        <form action="" id="loginform">
        <label class="input">
        <input class="input__field" type="text" />
        <span class="input__label">  آدرس ایمیل</span>
        </label>
        <label class="input">
        <input class="input__field" type="text" />
        <span class="input__label">  رمز عبور</span>
        </label>
        <label class="input">
        <input class="input__field" type="text" />
        <span class="input__label">  تکرار رمز عبور</span>
        </label>
        <label class="input">
        <input class="input__field" type="text" />
        <span class="input__label"> کد دعوت <span class="sub-lable">(اختیاری)</span></span>
        </label>
        <div id="checkinput">
        <div id="input"><input type="checkbox" id="policy"></div>
        <div id="label"><label for="policy"><a href=""> قوانین و شرایط </a>باینکس را مطالعه و میپذیرم</label></div>
        
        </div>
        
        <!-- <fieldset id="putnumber">
        <legend>  کد عددی </legend>
        <input type="number">
        </fieldset> -->
        <label class="input code-number-label">
        <input class="input__field__image" type="text" />
        <span class="input__label__image">کد عددی</span>
        <img src="../assets/OIP1.png" id="numberform">
        </label>
        <button class="subscrib">ثبت نام</button>
        <!-- <Subscribe id="login-form"/> -->
        </form>
        <p id="or">یا</p>
        <a href="/history" @click.prevent="$router.push('/login-email')"><button id="google"><p id="goole-p"><img id="google-image" src="../assets/google.png" alt=""> ورود با حساب گوگل </p></button></a>
        <p id="sub-p">حساب کاربری دارید؟<router-link to="/login"><a href="#"> وارد شوید </a></router-link></p>
    </div>
    </div>
</template>

<script>
// import Subscribe from './Subscribe.vue'
export default {
  components: {  },
  name: 'SubscribForm'
}
</script>

<style scoped>
.sub-form-container {
    width: 90%;
    margin: auto;
}
    .loginform {
        display:grid;
        /* margin: 20px; */
        margin-top: 0;
        /* row-gap: 10px; */
    }
    .form-head {
        font-weight: 600;
        font-size: 24px;
        /* border-bottom: 1px solid #00ACC1; */
        /* padding-bottom: 10px; */
        margin: 0;
    }
    /* hr {
        width: 100%;
        background-image: linear-gradient(90deg, white, #00ACC1, white);
        border: 0;
        height: 1px;
        opacity: 0.5;
    } */
    .input {
        /* margin: 15px; */
        /* width: 90%;
    margin: auto; */

    }
    #loginform {
        text-align: right;
        display: grid;
        row-gap: 5px;
        /* height: 45px; */
    }
    .code-number-label {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    fieldset {
        border: 1px solid #00ACC1;
        box-sizing: border-box;
        border-radius: 25px;
        padding: 5px;
    }
    /* input {
        border: 0;
        width: 100%;
        font-size: 20px;
        padding: 0;
    } */
    input[type="text"], input[type="password"],input[type="number"], textarea, select { 
        outline: none;
    }
    #numberform {
        display: inline-block;
        vertical-align: bottom;
    }
    #putnumber {
        display: inline-block;
    }
    /* #login-form {
        width: 100%;
        margin: auto;
    } */
    .subscrib {
    width: 100%;
    height: 45px;
    background:  #058C2B;
    border-radius: 79px;
    border: 0;
    color: white;
    margin: 0 5px 0 0;
    /* box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3); */
    border-radius: 35px;
    font-weight: 700;
    }
    #google {
        background-color: white;
        border: 1px solid #00ACC1;
        box-sizing: border-box;
        border-radius: 25px;
        width: 100%;
        font-weight: bold;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #goole-p {
        display: inline-block;
    }
    #or {
        font-weight: bold;
        margin: 10px;
    }
    #google-image {
        vertical-align: middle;
        margin-left: 10px;
    }
    #sub-p {
        text-align: right;
    }
    a{
        text-decoration: none;
        color:#00ACC1;
    }
    #forget-p {
        text-align: left;
        margin: 0;
    }
    #input , #label {
        display: inline-block;
        font-size: 14px;
    }
    #checkinput {
        margin-top: 10px;
        display: flex;
    }
    /* #labelcircle {
        width: 20px;
        height: 20px;
    } */
    #policy {
        width: 20px;
        height: 20px;
    }

    .input__label__image {
        position: absolute;
        padding: 0 5px 0 10px;
        white-space: nowrap;
        transform-origin: 0 0;
        background-color:white;
        transition: transform 120ms ease-in;
        font-weight: 600;
        font-size: 16px;
        right: 20px;
        top: -5px; 
        /* transform: translateY(-60%); */
        
    }
    .input__field__image {
        /* height: 100%; */
        height: 45px;
        border: 1px solid #00ACC1;
        box-sizing: border-box;
        border-radius:25px;
        vertical-align: text-top;
        font-size: 16px;
        display: flex;
    flex-grow: 1;
    padding: 10px;
    margin:  10px 0 10px 0;
    }
    .sub-lable {
        font-weight: 500;
        font-size: 14px;
        color: #0F0F0F;
        opacity: 0.8;
    }
</style>