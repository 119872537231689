<template>
<div>
    <div id="contact">
        <Navbar/>
    <div class="container-contact">
        <p id="contact-title">ارتباط با مدیرت</p>
        <div id="contact-container">
        <div id="form-contact">
            <div class="form-container">
                <form id="form">
                <label class="input">
                <input class="input__field" type="text" />
                <span class="input__label">نام و  نام خانوادگی</span>
                </label>
                <label class="input">
                <input class="input__field" type="text" />
                <span class="input__label">آدرس ایمیل</span>
                </label>
                <label class="input-textarea">
                <textarea rows="4" cols="9" type="text" />
                <span class="span-textarea"> توضیحات</span>
                </label>
                <button id="send-message">ارسال پیام</button>
                </form>
        </div>
        </div>
        <div id="map">
            <img id="map-image" src="@/assets/map.png" alt="">
            <div id="contact-items">
                <div id="contact-info">
                    <div id="contact-text"><img id="contact-image" src="@/assets/phone.png" alt=""> 021888888 </div>
                    <div id="contact-text"><img id="contact-image" src="@/assets/telegram.png" alt="">binex.crypto</div>
                </div>
                <div id="contact-info">
                    <div id="contact-text"><img id="contact-image" src="@/assets/gmail.png" alt="">blablabla@blablblabla.com</div>
                    <div id="contact-text"><img id="contact-image" src="@/assets/aparat.png" alt="">binex.crypto</div>
                </div>
                <div id="contact-info">
                    <div id="contact-text"><img id="contact-image" src="@/assets/insta.png" alt="">binex.crypto</div>
                    <div id="contact-text"><img id="contact-image" src="@/assets/tweet.png" alt="">binex.crypto</div>
                </div>
                <div id="contact-info">
                    <div id="contact-text"><img id="contact-image" src="@/assets/googleLocation.png" alt="">تهران میدان جهاد خیابان سربداران</div>
                    
                </div>
            </div>
            
        </div>
        </div>
    </div>
    
    </div>
    <Footer/>
    </div>
</template>
<script>
import Footer from './firstPage/Footer.vue'
import Navbar from './Navbar.vue'
export default {
  components: {Footer ,Navbar  },
  name: 'Contact',
}
</script>

<style scope>
.container-contact {
    /* width: 90%; */
    max-width: 1285px;
    margin: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    /* margin: 0 20px; */
    box-sizing: border-box;
    padding: 0 20px;
}
#contact {
    background-color: white;
    /* height: 900px; */
    display: flex;
    flex-direction: column;
}
    #contact-container {
        display: flex;
        justify-content: space-around;
        /* flex-wrap: wrap; */
        column-gap: 40px;
        row-gap: 40px;
        margin-bottom: 100px;
        flex-wrap: wrap;
        margin-right: 25px;
    }
    .form-container {
        margin: 40px 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    max-width: 394px;
}
    #form-contact {
        max-width: 481px;
        /* height: 568px; */
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        border-radius: 25px;
        display: flex;
        flex-grow: 1;
        width: 4.8vw;
        min-width: 300px;
        justify-content: center;
    }
    #form {
        width: 100%;
        display: flex;
    flex-direction: column;
    row-gap: 20px;
    height: 100%;
    }
    .input__field {
        margin: 10px 0 10px 0;
    }
    #map {
        /* width: 720px; */
        width: 7.2vw;
    flex-grow: 1;
    min-width: 300px;
    }
    #map-image {
        width: 720px;
        max-width: 100%;
    }
    #contact-title {
        font-weight: bold;
        font-size: 20px;
        margin: 30px 0;
    }
    #contact-items {
        display: grid;
        justify-content: normal;
        row-gap: 25px;
    }
    #contact-image {
        width: 30px;
        height: 30px;
        margin: 0 10px;
    }
    
    .input__label__textarea {
        position: absolute;
        top: 0;
        right: 20px;
        padding: 0 5px 0 10px;
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        background-color:white;
        transition: transform 120ms ease-in;
        font-weight: 500;
        font-size: 16px;
    }
    #send-message {
        background: #00ACC1;
        border-radius: 35px;
        border: 0;
        color: white;
        height: 45px;
        width: 100%;
    }
    #contact-info {
        display: flex;
        justify-content: space-between;
    width: 80%;
    margin: auto;
        align-items: center;
        flex-wrap: wrap;
            row-gap: 25px;
    }
    #contact-text {
        display: flex;
        align-items: center;
        font-weight: bold;
font-size: 16px;
    }
    .input-textarea {
        height: auto;
        position: relative;
        flex-grow: 1;
    }
</style>
<style lang="scss" scoped>
textarea {
        resize: none;
        // height: 214px;
        height: 100%;
        /* margin: 20px 0 20px 0; */
    border: 1px solid #00ACC1;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 10px;
    border-radius:25px;
    }
    .span-textarea {
        position: absolute;
            top: -13px;
            right: 20px;
            padding: 0 5px 0 10px;
            white-space: nowrap;
            transform: translate(0, 0);
            transform-origin: 0 0;
            background-color:white;
            transition: transform 120ms ease-in;
            font-weight: 600;
            font-size: 16px;
    }
    @media only screen and (max-width:700px) {
        #contact-container {
            margin-right: 0;
    }
    }
</style>
