<template>
    <div class="steps-container">
    <div class="step-container1">
      <div  @click="step5Detail=!step5Detail" class="step5">
         <p v-if="step5Detail">
           سطح پنج
         </p>
         <p v-if="!step5Detail">
           200000000 توامن 
           <br><span>کارمزد 0.2</span>
         </p>
      <!-- <svg class="step-svg" height="50" width="50">
            <polygon class="step-polygon" points="50,0 0,25 50,50"  />
        </svg> -->
      </div>
    </div>
  
    <div @click="step4Detail=!step4Detail" class="step-container2">
      <div class="step4">
        <p v-if="step4Detail">
          سطح چهار
        </p>
        <p v-if="!step4Detail">
          100000000 تومان 
          <br><span>کارمزد0.25</span>
        </p>
      <svg class="step-svg" height="60" width="50">
            <polygon class="step4-polygon" points="50,0 0,30 50,60"  />
        </svg>
      </div>
    </div>
    <div @click="step3Detail=!step3Detail" class="step-container1">
      <div class="step3">
        <p v-if="step3Detail">سطح سه</p>
        <p v-if="!step3Detail">
          50000000 تومان
          <br><span>
            34000000 میزان معامله شما
          </span>
        </p>
      <svg class="step-svg" height="60" width="50">
            <polygon class="step3-polygon" points="50,0 0,30 50,60"  />
        </svg>
      </div>
    </div>
     <div @click="step2Detail=!step2Detail" class="step-container2">
      <div class="step2">
          <p v-if="step2Detail">سطح دو</p>
          <p v-if="!step2Detail">
            تومان 20000000
            <br/>0.35 کارمزد<span></span>
          </p>

      <svg class="step-svg" height="60" width="50">
            <polygon class="step2-polygon" points="50,0 0,30 50,60"  />
        </svg>
      </div>
    </div>
    <div @click="step1Detail=!step1Detail" class="step-container2">
      <div class="step1">
        <p  v-if="step1Detail">سطح یک</p>
        <p  v-if="!step1Detail">
          0 تومان
          <br/><span>کارمزد:0.4</span>
        </p>
      <svg class="step-svg" height="60" width="50">
            <polygon class="step1-polygon" points="50,0 0,30 50,60"  />
        </svg>
      </div>
    </div>
    </div>
</template>

<script>
  export default {
  components: {  },
  name: 'AccountLevels',
  data : function() {
    return {
      step1Detail:true,
      step2Detail:true,
      step3Detail:true,
      step4Detail:true,
      step5Detail:true
    }
}

  }
</script>

<style scoped>
    body {
  text-align:center;
  direction: rtl;
}
.steps-container {
  display:flex;
  justify-content: center;
  direction: ltr;
  /* width: 1000px; */
  width: 100%;
  min-width: 1200px;
  border-radius: 25px;
  overflow: hidden;
}
.step-container1 {
  /* width:1200px; */
  /* height:150px; */
  display:flex;
  justify-content:center;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
  justify-content: flex-end;
  width: 25%;
  cursor: pointer;
  text-align: center;
}
.step-container2 {
  /* width:1200px; */
  /* height:150px; */
  display:flex;
  justify-content:center;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.3));
  justify-content: flex-end;
  width: 25%;
  cursor: pointer;
  text-align: center;
}
/* .step1 {
  
height: 60px;
  background:#00ACC1;
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
font-size: 16px;
width: 100%;
  
} */
/* .step2 {
  
height: 60px;
  background:#00ACC1;
  position:relative;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
font-size: 16px;
width: 100%;
} */
/* .step4 {

height: 60px;
  background:#00ACC1;
  position:relative;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
font-size: 16px;
width: 100%;
} */

.step-svg {
  position:absolute;
  left:-50px;
  
}
.step-polygon {
  fill:#00ACC1;
}
.step1 {
    height: 60px;
  background:white;
  position:relative;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00ACC1;
  font-weight: bold;
font-size: 16px;
width: 100%;
}
.step1-polygon {
    fill: white;
}
.step2 {
    height: 60px;
  background: #90DFE8;
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #00ACC1;
  font-weight: bold;
font-size: 16px;
width: 100%;
}
.step2-polygon {
    fill: #90DFE8;
}
.step3 {
    height: 60px;
  background: #058C2B;
  position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
font-size: 16px;
width: 100%;
}
.step3-polygon {
    fill:#058C2B ;
}
.step4 {
    height: 60px;
  background:#00c8e2;
  position:relative;
  /* border-top-right-radius: 25px;
  border-bottom-right-radius: 25px; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
font-size: 16px;
width: 100%;
}
.step4-polygon {
    fill:#00c8e2 ;
}
.step5 {
    height: 60px;
  background:#00ACC1;
  position:relative;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
font-size: 16px;
width: 100%;
}
</style>