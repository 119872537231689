<template>
<div id="about">
    <Navbar/>
<div id="container">
    <div id="text">
        <p id="title">درباره ی باینکس</p>
        <p id="text">اکس اونیکس به عنوان مرجعی تخصصی در حوزه معاملات آنلاین و پرداخت‌های مجازی، با  هدف دسترسی ایمن و آسان مخاطبان به بازارهای جهانی، در سال ۲۰۱۷ فعالیت خود را آغاز کرد. </p>
        <p id="text"> خرید و فروش پی اس ووچر ، پرفکت مانی ، ووچر پرفکت مانی ، بیت کوین ، اتریوم ، ریپل ، ترون ، دوج کوین ، تتر ، لایت کوین و سایر رمز ارز ها قسمتی از خدمات قابل ارائه ما به شماست.</p>
        <p id="text">تیم جوان و متخصص ما، مسئولیت خود را آشنایی شما با پرداخت‌های آنلاین و ایجاد فضایی امن برای معاملات می‌داند.</p>
        <p id="text">هدف ما این است که با توضیح دقیق و مشاوره مفید در زمینه‌ی پرداخت‌های آنلاین و دیگر خدمات، تمامی جوانب را به شما نشان دهیم تا بتوانید با خیالی آسوده در این راه گام برداری</p>
    </div>
</div>
<Footer/>
</div>
</template>

<script>
import Footer from './firstPage/Footer.vue'
import Navbar from './Navbar.vue'
export default {
  name: 'About',
  components :{ Footer , Navbar }
}
</script>

<style scoped>
    #about {
        height: 100%;
        /* width: 95%; */
        /* margin: auto; */
    }
    #title ,#text {
        text-align: right;
    }
    #title {
        font-weight: bold;
        font-size: 20px;
        line-height: 31px;
    }
    #text {
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
    }
    #container {
        width: 95%;
        margin: auto;
        height: 100%;
    }
</style>
