<template>
<!-- <div id="container"> -->
    <div id="Athutication">
        <!-- <AuthenticationNavbar/> -->
        <ProfileNavbar/>
        <!-- <button class="menue" @click="showSidebar">
             
        </button> -->
        <div id="authen-container">
            <Sidebar class="sidebar"/>
            <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
                <div id="authen-form1">
                    <div id="container">
                <div id="line1-athuntication">
                    <p id="line1-athuntication-title">احراز هویت</p>
                </div>
                <div id="line2-athuntication">
                    <AuthenProcess2/>
                </div>
                
                <div id="line3-athuntication">
                    
                    <div class="line3-athuntication-child">

                    
                    <div id="line3-title">
                        <p id="line3-title-p">اطلاعات بانکی</p>
                    </div>
                    <perfect-scrollbar>
                    <div id="line3-form">
                    <div id="line3-form-group">
                    <label class="input">
                    <input class="input__field bank-name" type="text"  v-model="bankIn" />
                    <span class="input__label ">   نام بانک</span>
                    </label>
                    <label class="input">
                    <input class="input__field" type="text" v-model="credictIn" />
                    <span class="input__label credit-cart">   شماره کارت</span>
                    </label>
                    <label class="input">
                    <input class="input__field" type="text"  v-model="shabaIn"/>
                    <span class="input__label shaba">   شماره شبا</span>
                    <router-link to="/shaba"><p id="shaba-link">استعلام شماره شبا</p></router-link>
                    </label>
                    <button id="add-bank" v-on:click="onSubmit"  >افزودن حساب</button>
                </div>
                    
                <div>
                    <table id="bank-info">
                        <thead id="bank-info-head">
                            <tr>
                                <td class="bank-name-td">
                                    نام بانک
                                </td>
                                <td>
                                    شماره کارت
                                </td>
                                <td class="shaba-td">
                                    شماره شبا 
                                </td>
                                <td>
                                    وضعیت
                                </td>
                                <td>
                                    
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(entry, i) in allScores" :key="i">
                                <td class="bank-name-td">
                                    {{entry.bank}}
                                </td>
                                <td>
                                    {{entry.credict}}
                                </td>
                                <td class="shaba-td">
                                    {{entry.shaba}}
                                </td>
                                <td>
                                    <img src="@/assets/bankTick.png" >
                                </td>
                                <td>
                                    <button id="trash-button" ><img src="@/assets/trash.png"></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                        
                <div id="line3-buttons">
                    <router-link to="/mobile-confirm"><button id="pre">مرحله قبل </button></router-link>
                    <router-link to="/upload-image"><button id="next">مرحله بعد</button></router-link>
                </div>
                </div>
                </perfect-scrollbar>
                </div>
                
                </div>
                </div>
                </div>
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
import AuthenProcess2 from './AuthenProcess2.vue'
// import AuthenticationNavbar from "./AuthenticationNavbar.vue"
import Sidebar from './Sidebar.vue'
import $ from "jquery"
import ProfileNavbar from './ProfileNavbar.vue'
export default {
    components:{ ProfileNavbar, AuthenProcess2 ,Sidebar },
  name: 'AuthenticationMob',
  data:function() {
      return {
          sidebarResponsive:false,
           bank: "",
           credict: "",
           shaba:"",
           
            allScores: [
                {
                    bank:"صادرات",
                    credict:60376974553399,
                    shaba:1321313213213213213213
                },
                {
                    bank:"صادرات",
                    credict:60376974553399,
                    shaba:1321313213213213213213
                }
            ],
            rows: [{row: ""}],
            
      }
       
   },
   methods: {
        showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
    onSubmit() {
        // if(this.bankIn==="" && this.credictIn==="" && this.shabaIn==="") {
            
            this.allScores.push({ bank: this.bankIn, credict: this.credictIn , shaba:this.shabaIn  });
        // }
        console.log(this.rows.length)
      
      this.clearForm();
    },
    clearForm() {
      this.bank = "";
      this.credict = "";
      this.shaba="";
    },
    addRow(){
            this.rows.push({row: ''});
        },
        removeRow: function(index) {
            this.rows.splice(index, 1);
        }
   },
//    $("#tbUser").on('click', '.btnDelete', function () {
//     $(this).closest('tr').remove();
// });  
mounted() {
    $("#trash-button").on('click', function () {
    $(this).closest('tr').remove();
});  
},
}
</script>

<style scoped>
    #Athutication {
        /* display: grid; */
        justify-items: right;
        background-color: white;
    }
    #authen-form1 {
        display: grid;
        justify-items: center;
        width: 100%;
        height: 98%;
    }
    #authen-container {
        display: flex;
        direction: rtl;
        padding: 0 10px;
        /* column-gap: 10px; */
    }
    #line2-athuntication {
        /* display: flex;
        justify-items: right; */
        /* margin-bottom: 40px; */
        height: 120px;
        align-items: center;
    }
    #line1-athuntication-title {
        font-weight: bold;
        font-size: 18px;
        text-align: right;
        margin: 0;
        
    }
    #athutication-nav {
        width: 360px;
        height: 50px;
        border-radius: 25px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
    }
    .done {
        background: #00ACC1;
        color: white;
    }
    #line3-athuntication {
        /* width: 1273px; */
        /* height: 478px; */
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        display: grid;
        /* padding: 0 10px 20px 10px; */
        row-gap: 40px;
        height: 67vh;
        flex-grow: 1;
        /* overflow: scroll; */
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    #line3-athuntication::-webkit-scrollbar {
        display: none;
    }
    .line3-athuntication-child {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        height: 100%;
        overflow: scroll;
        width: 100%;
        padding: 0 10px 20px 10px;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .line3-athuntication-child::-webkit-scrollbar {
        display: none;
    }
    #line3-form {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        row-gap: 40px;
        padding: 0 10px;
    }
    #line3-form-group {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        column-gap: 40px;
        /* flex-wrap: wrap; */
        row-gap: 20px;
        align-items: center;
    }
    #line3-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 20px;
    }
    #line3-title {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    #line3-title-p {
        font-weight: bold;
        font-size: 18px;
        padding: 5px;
        text-align: center;
        margin: 5px;
    }
    #pre {
        border: 2px solid #00ACC1;
        box-sizing: border-box;
        border-radius: 35px;
        width: 172px;
        font-weight: bold;
        font-size: 16px;
        color: #00ACC1;
        background-color: white;
        height: 45px;
    }
    #next {
        width: 172px;
        background: #058C2B;
        border-radius: 35px;
        font-weight: bold;
        font-size: 16px;
        color: white;
        height: 45px;
        border: 0;
    }
    .address {
        width: 100%;
    }
    .input {
        min-width: 25%;
        display: flex;
    flex-grow: 1;
    }
    .input__field {
        /* width: 394px; */
        width: 100%;
    }
    .input__label {
        font-weight: 600;
        font-size: 16px;
    }
    #code-confirm {
        justify-self: center;
    }

    .input__label__image {
        position: absolute;
        padding: 0 5px 0 10px;
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        background-color:white;
        transition: transform 120ms ease-in;
        font-weight: 500;
        font-size: 16px;
        top: 0;
        right: 20px;
        font-weight: 600;
        font-size: 16px;
    }
    .input__field__image {
        width: 221px;
        height: 45px;
        margin: 10px 0 10px 0;
        border: 1px solid #00ACC1;
        box-sizing: border-box;
        display:inline-block;
        padding: 10px;
        border-radius:25px;
        vertical-align: text-top;
        }
        #numberform {
        display: inline-block;
        vertical-align: bottom;
    }
    #container {
        width: 100%;
        /* overflow: scroll; */
        padding: 0 10px;
        height: 98%;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;
        display: flex;
        flex-direction: column;
        /* row-gap: 30px; */
    }
    #container::-webkit-scrollbar {
        display: none;
    }
    #get-code {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #get-code-button {
        width: 394px;
        height: 45px;
        background: #00ACC1;
        border-radius: 35px;
        border: 0;
        color: white;
    }
    #mobile-title {
        text-align: center;
        font-weight: 600;
        font-size: 16px;
    }
    #add-bank {
        width: 172px;
        height: 45px;
        border: 0;
        background: #00ACC1;
        border-radius: 35px;
        color: white;
        margin: 10px 0;
    }
    #bank-info-head {
        border-bottom: 1px solid rgba(15, 15, 15, 0.5);
        height: 60px;

    }
    .bank-name {
        /* width: 200px; */
    }
    #bank-info {
        width: 98%;
        margin: 10px;
        text-align: center;
    }
    thead {
        font-weight: bold;
        font-size: 16px;
    }
    thead td:first-child {
        text-align: right;
    }
    thead td:last-child {
        text-align: left;
    }
    table {
        border-collapse: collapse;
    }
    tbody > tr {
        border-bottom: 1px solid rgba(15, 15, 15, 0.2);
        height: 60px;
    }
    tbody > tr td:first-child {
        text-align: right;
    }
    tbody > tr td:last-child {
        text-align: left;
    }
    #trash-button {
        background: transparent;
        border: 0;
    }
    #shaba-link {
        font-size: 14px;
        margin: 0;
        position: absolute;
        top: 60px;
        right: 10px;
        color: #141292;
        border-bottom: 1px solid #141292;
    }
    @media only screen and (max-width:1000px) {
        #line3-form-group {
            flex-wrap: wrap;
        }
    }
    @media only screen and (max-width: 600px) {
        .shaba-td , .bank-name-td{
            display: none;
        }
    }
</style>