<template>
<div class="historyRialy-container">
    <!-- <div id="profile-navbar"> -->
        <!-- <ProfileNavbar/> -->
        <!-- <Authentication/> -->
        <!-- <Sidebar class="sidebar"/>
        
        <div class="history-container">
            <div class="history-items">
                <div class="history-item active">معاملات </div>
                <div class="history-item">تراکنش ریالی</div>
                <div class="history-item">تراکنش رمز ارز </div>
                <div class="history-item">لاگین</div>
            </div> -->
            <!-- <div class="describe-history-items">
                <div class="history-items-container"> -->
                <div class="filter-history-row">
                    <div class="history-sell-buy">
                        <button class="history-buys " v-on:click="beActiveBuys" :class="{'deActive-input':deActiveBuys , 'active-input':activeBuys}" >
                            واریز
                        </button>
                        <button class="history-sells " v-on:click="beActiveSells" :class="{'deActive-output':activeBuys , 'active-output':deActiveBuys}"  >
                            برداشت
                        </button>
                    </div>
                </div>
                    <div class="historyRialy-tables-container">
                        <table class="historyTrades-table" v-if="activeBuys">
                            <!-- <thead>
                                <tr>
                                    <td>بازار</td>
                                    <td>مقدار</td>
                                    <td>قیمت واحد </td>
                                    <td>قیمت کل</td>
                                    <td>کارمزد</td>
                                    <td>دریافتی شما</td>
                                    <td>تاریخ معامله</td>
                                    <td>وضعیت</td>
                                </tr>
                            </thead> -->
                            <!-- <tbody> -->
                                <tr>
                                    <td>نوع واریز</td>
                                    <td>مقدار واریز (تومان)</td>
                                    <td class="shaba-td">شماره شبا</td>
                                    <td class="idPay-td">شناسه پرداخت</td>
                                    <td class="date-td">تاریخ درخواست</td>
                                    <td> وضعیت</td>
                                </tr>
                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-input">0.77,871,827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td  class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>
                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-input">0.77,871,827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td  class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>
                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-input">0.77,871,827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td  class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>

                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-input">0.77,871,827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td  class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>
                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-input">0.77,871,827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td  class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>
                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-input">0.77,871,827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td  class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>
                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-input">0.77,871,827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td  class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>
                            <!-- </tbody> -->
                        </table>
                        <table class="historyTrades-table" v-if="deActiveBuys">
                            <!-- <thead>
                                <tr>
                                    <td>بازار</td>
                                    <td>مقدار</td>
                                    <td>قیمت واحد </td>
                                    <td>قیمت کل</td>
                                    <td>کارمزد</td>
                                    <td>دریافتی شما</td>
                                    <td>تاریخ معامله</td>
                                    <td>وضعیت</td>
                                </tr>
                            </thead> -->
                            <!-- <tbody> -->
                                <tr>
                                    <td>نوع برداشت</td>
                                    <td>مقدار برداشت (تومان)</td>
                                    <td class="shaba-td">شماره شبا</td>
                                    <td class="idPay-td">شناسه پرداخت</td>
                                    <td class="date-td">تاریخ درخواست</td>
                                    <td> وضعیت</td>
                                </tr>
                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-output">0.77+871827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>
                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-output">0.77+871827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>
                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-output">0.77+871827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>

                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-output">0.77+871827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>
                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-output">0.77+871827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>
                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-output">0.77+871827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>
                                <tr>
                                    <td>اتریوم</td>
                                    <td class="td-output">0.77+871827 ET</td>
                                    <td class="shaba-td">6464654654654 </td>
                                    <td class="idPay-td">6464654654654 </td>
                                    <td class="date-td">xxxx/xx/xx</td>
                                    <td><img src="@/assets/tick-green.png" alt=""></td>
                                </tr>
                            <!-- </tbody> -->
                        </table>
                    </div>

</div>
</template>
<script>
// import Authentication from './Authentication.vue'
// import ProfileNavbar from "./ProfileNavbar.vue"
// import Sidebar from './Sidebar.vue'

export default {
  components: {},
  name: 'MainProfile',
  data:function() {
      return {
          deActiveBuys:false,
          activeBuys:true,
      }
  },
  methods : {
      beActiveBuys() {
          if (this.deActiveBuys) {
              this.deActiveBuys=false
              this.activeBuys=true
          }
          else 
              return true
      },
      beActiveSells () {
          if (this.activeBuys) {
              this.deActiveBuys=true
              this.activeBuys=false
          }
          else 
              return true
      },
  }
}
</script>

<style scoped>
.historyRialy-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.deActive-input , .deActive-output {
    background: transparent;
}
.historyTrades-table {
    width: 100%;
    border-collapse: collapse;
}
.historyTrades-table > tr {
    border-bottom: 1px solid #a5a5a5;
    height: 50px;
}
.describe-row-list {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.history-items {
    display: flex;
    flex-direction: row;
   
}
.active-input {
    background-color: #058C2B;
    color: white;
}
.active-output {
    background-color: red;
    color: white;
    border-radius: 25px;
}
 
.history-item {
    /* padding: 0 15px; */
    background-color: rgba(243, 243, 243 , 0.1);
    /* background: rgba(15, 15, 15, 0.1); */
     /* height: 35px; */
     width: 110px;
     display: flex;
    justify-content: center;
    align-items: center;
}
.active {
    background: white;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 35%);
}
.describe-history-items {
    display: flex;
    flex-grow: 1;
    height: 600px;
    /* box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35); */
    border-radius: 2px;
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
    background: white;
}
.history-items-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    row-gap: 30px;
}
.history-markets {
    width: 120px;
    height: 39px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 25px;
    background: transparent;
}
.filter-history-row {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    margin-top: 20px;
}
.history-sell-buy {
    width: 200px;
    height: 39px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 25px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.history-buys {
    width: 100px;
    height: 35px;
    /* color: white; */
    border-radius: 25px;
    /* background-color: #E0342B; */
    /* background: transparent; */
    display: flex;
    justify-content: center;
    align-items: center;
}
.history-sells {
    width: 100px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: transparent; */
}

.describe-row-header {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    font-weight: bold;
    font-size: 14px;
}
    .wallet-header {
        font-weight: bold;
        font-size: 20px;
    }
    .wallet-title-p , .amount-p {
        font-weight: bold;
        font-size: 16px;
    }
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;

    }
    .history-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        align-self: baseline;
    }
    .td-input {
        color: #058C2B;
    }
    .td-output {
        color: red;
    }
    .historyRialy-tables-container {
        /* overflow-x: scroll; */
    }
    /* .sidebar {
            display: none;
        }
    @media only screen and (max-width: 900px) {
        .sidebar {
            display: none;
        }
    } */
</style>
<style scoped>
@media only screen and (max-width: 900px) {
    .shaba-td , .idPay-td , .date-td{
        display: none;
    }
}
</style>
