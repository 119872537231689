<template>
    <div id="mainfooter">
        <div class="footer-container">
                <div id="footer-row1">
                <div id="footer-col">
                    <ul id="footerul">
                        <h4 id="footerh">بازار</h4>
                        <li id="footerli"><img src="@/assets/tick.png"/> خرید و فروش بیتکوین </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش اتریوم </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش ریپل </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش دوج کوین </li><br/>
                        
                    </ul>
                </div>
                <div id="footer-col">
                <ul id="footerul"><h4 id="footerh">آموزش</h4>
                        <li id="footerli"><img src="@/assets/tick.png"/> خرید و فروش بیتکوین </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش اتریوم </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش ریپل </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش دوج کوین </li><br/>
                        
                    </ul>
                </div>
                <div id="footer-col">
                <ul id="footerul"><h4 id="footerh">امنیت</h4>
                        <li id="footerli"><img src="@/assets/tick.png"/> خرید و فروش بیتکوین </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش اتریوم </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش ریپل </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش دوج کوین </li><br/>
                        
                    </ul>
                </div>
                <div id="footer-col">
                <ul id="footerul"><h4 id="footerh">خدمات مشتریان</h4>
                        <li id="footerli"><img src="@/assets/tick.png"/> خرید و فروش بیتکوین </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش اتریوم </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش ریپل </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش دوج کوین </li><br/>
                        
                    </ul>
                </div>
                <div id="footer-col">
                <ul id="footerul"><h4 id="footerh">امنیت</h4>
                        <li id="footerli"><img src="@/assets/tick.png"/> خرید و فروش بیتکوین </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش اتریوم </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش ریپل </li>
                        <li><img src="@/assets/tick.png"/> خرید و فروش دوج کوین </li><br/>
                        
                    </ul>
                </div>
                </div>
                <div id="footer-row2">
                    <div id="footer-row2-right">
                        <div id="footerrow-right1">
                            <h4 id="footerh"> استفاده از مطالب آموزشی این سایت در حین گسترش  فضای آمزشی کشور قانونی و حلال است </h4>
                        </div>
                        <div id="footerrow-right1">
                            <img src="@/assets/insta.png" id="social"/>
                            <img src="@/assets/telegram.png" id="social"/>
                            <img src="@/assets/tweet.png" id="social"/>
                            <img src="@/assets/aparat.png" id="social"/>
                        </div>
                        <div id="footerrow-right1">
                            <h6><img src="@/assets/location.png"/> آدرس : خیابان رضوی کوچه معارف پورنجف غربی پلاک هفت</h6>
                        </div>
                    </div>
                    <div id="footer-row2-left">
                        <img src="@/assets/namad2.png" id="namad"/>
                        <img src="@/assets/namad.png" id="namad"/>
                    </div>
                </div>
                </div>
            </div>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
    #mainfooter {
    width: 100%;
    background-image: linear-gradient(180deg, rgba(204, 171, 109, 0) 0%, #ffe9c8 100%);
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    direction: rtl;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
   
}
.footer-container {
    width: 95%;
    margin: auto;
}
#upmainfooter {
    width: 100%;
    /* height: 564px; */
    display: flex;
    flex-wrap: wrap;

}
#footer-row1 {
    width: 100%;
    /* height: 60%; */
    vertical-align: top;
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
    column-gap: 70px;
    justify-content: space-between;
}
#footer-col {
    display: inline-block;
    box-sizing: border-box;
    /* width: 20%; */
    /* height: 100%; */
    vertical-align: top;
}
#footerul {
    text-align: right;
    list-style-type:none;
    font-weight: 500;
font-size: 14px;

}
#footerh {
    text-align: right;
    font-weight: bold;
}
#footerli {
    text-align: right;
}
#footer-row2 {
    width: 100%;
    /* height: 35%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}
#footer-row2-right {
    /* width: 60%; */
    /* height: 100%; */
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}
#footer-row2-left {
    /* width: 40%;
    height: 100%; */
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    vertical-align: top;
}
#namad {
    width: 150px;
    height: 150px;
    border-radius: 50%;
}
#footerrow-right1 {
    /* width: 100%; */
    /* height: 30%; */
    text-align: right;
    margin-right: 30px;
}
#social {
    margin: 0 20px 0 20px;
}
li {
    margin: 5px;
}
@media only screen and (max-width:1000px) {
    #footer-row1 {
        column-gap: 0;
    }
    #footerul {
        padding: 0;
    }
    
    #footerrow-right1 {
        margin: 0;
    }
    #footerrow-right1 > img {
        margin: 0 10px;
    }
}
/* @media only screen and (max-width:700px) {
    #footer-row1 {
        display: none;
    }
    #footer-row2 {
        display: grid;
    }
    #footer-row2-right {
        display: grid;
        width: auto;
    }
    #footer-row2-left {
        display: flex;
        justify-self: center;
        justify-content: space-around;
        
    }
    #footerrow-right1 {
        margin: 0;
        height: auto;
        display: flex;
        justify-content: center;
    }
} */
</style>
