<template>
<div>
    <div id="profile-navbar">
        <ProfileNavbar/>
        <div class="wallet-container">
            <Sidebar class="sidebar"/>
            
                <div class="wallets">
                    <p class="wallet-header">کیف پول</p>
                    <perfect-scrollbar>
                    <div class="wallet-row-container">
                    <div class="wallet-row">
                        <div class="wallet-title">
                            <img src="@/assets/rialWallet.png" >
                            <p class="wallet-title-p">ریالی</p>
                        </div>
                        <div class="wallet-amount">
                            <p class="amount-p">تومان 0 </p>
                        </div>
                        <div class="wallet-options">
                            <a href="/deposit/rial" @click.prevent="$router.push('/deposit/rial')">
                                <button class="wallet-deposit-button">واریز</button>
                            </a>
                            <a href="/withdrawal/rial" @click.prevent="$router.push('/withdrawal/rial')">
                                <button class="wallet-withdrawal-button">برداشت</button>
                            </a>
                        </div>
                    </div>
                    <div class="wallet-row" v-for="currency , index in currencyInformation" :key="index">
                        <div class="wallet-title">
                            <img v-bind:src=currency.image >
                            <p class="wallet-title-p">{{currency.name}}</p>
                        </div>
                        <div class="wallet-amount">
                            <p class="amount-p"> {{currency.bitPrice}}<span>{{currency.ENname}}</span></p>
                        </div>
                        <div class="wallet-options">
                            <a href="/deposit/rial" @click.prevent="$router.push('/trade')">
                                <button class="wallet-deal-button">معامله</button>
                            </a>
                            <a href="/deposit/bit" @click.prevent="$router.push('/deposit/bit')">
                                <button class="wallet-deposit-button">واریز</button>
                            </a>
                            <a href="/withdrawal/bit" @click.prevent="$router.push('/withdrawal/bit')">
                                <button class="wallet-withdrawal-button">برداشت</button>
                            </a>
                        </div>
                    </div>
                    </div>
                    </perfect-scrollbar>
                </div>
            
        </div>
    </div>
</div>
</template>
<script>
// import Authentication from './Authentication.vue'
import ProfileNavbar from "./ProfileNavbar.vue"
import Sidebar from './Sidebar.vue'
import Market from "../../library/Market.js"
export default {
  components: { ProfileNavbar, Sidebar },
  name: 'MainProfile',
  data : function () {
      return {
          currencyInformation:Market,
          sidebarResponsive:false,
      }
  },
  methods : {
      showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
  }
}
</script>

<style scoped>
#profile-navbar {
    justify-content: center;
}
.wallet-container {
    width: 100%;
    max-width: 1440px;
    margin: 0 10px;
    display: flex;
    flex-direction: row;
    /* column-gap: 10px; */
    height: calc(100vh - 70px);
}
.sidebar-responsive {
    position: absolute;
    z-index: 10;
}
    .wallet-header {
        font-weight: bold;
        font-size: 20px;
        margin-top: 0;
    }
    .wallet-title-p , .amount-p {
        font-weight: bold;
        font-size: 16px;
    }
    .amount-p > span {
        padding: 0 10px;
    }
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;

    }
    .wallet-row-container {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding: 10px;
    }

    .wallets {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        align-self: baseline;
        /* row-gap: 10px; */
        padding: 0 10px;
        height: 98%;
        justify-content: right;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .wallets::-webkit-scrollbar {
        display: none;
    }
    .wallet-row {
        display: flex;
        flex-grow: 1;
        justify-content: space-between;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        flex-wrap: wrap;
        padding: 0 10px;
        align-items: center;
        row-gap: 10px;
    }
    .wallet-title {
        display: flex;
        /* justify-content: center; */
        align-items: center;
        flex-grow: 1;
        column-gap: 10px;
    }
    .wallet-title > img {
        width: 34px;
        height: 34px;
    }
    .wallet-amount , .wallet-title , .wallet-options {
        min-width: 33%;
    }
    .wallet-amount {
        flex-grow: 1;
        justify-content: center;
        display: flex;
        min-width: 100px;
    }
    .wallet-options {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: center; */
        justify-content: flex-end;
        align-items: center;
        flex-grow: 1;
        gap: 5px;
    }
    .wallet-deal-button , .wallet-withdrawal-button , .wallet-deposit-button {
        width: 95px;
        height: 30px;
        color: white;
        border: 0;
        border-radius: 2px;
    }
    .wallet-deal-button {
        background-color: #00ACC1;
        font-weight: bold;
font-size: 14px;
    }
    .wallet-withdrawal-button {
        background-color: #E0342B;
        font-weight: bold;
font-size: 14px;
    }
    .wallet-deposit-button {
        background-color: #058C2B;
        font-weight: bold;
font-size: 14px;
    }
    @media only screen and (max-width: 700px) {
        .wallet-title  , .wallet-amount{
            width: 50%;
        }
        .wallet-amount {
            justify-content: flex-end;
        }
        .wallet-options {
            flex-direction: column;
            align-items: flex-end;
            margin-bottom: 10px;
        }
    }

    /* .sidebar {
            display: none;
        }
    @media only screen and (max-width: 900px) {
        .sidebar {
            display: none;
        }
    } */
</style>
