<template>
  <transition name="modal-fade">
        <div class="modal-backdrop">
          <div class="modal"
            role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <header
              class="modal-header"
              id="modalTitle"
            >
              <slot name="header">
                <p>شناسایی دو عاملی گوگل</p>
              </slot>
              <button
                type="button"
                class="btn-close"
                @click="closeModal"
                aria-label="Close modal"
              >
              <img src="@/assets/closeModal.png" alt="">
              </button>
            </header>
    
            <section
              class="modal-body"
              id="modalDescription"
            >
              <slot name="body">
                <div>
                  <p>لطفا بارکد زیر را توسط برنامه Authenticator Google گوشی خود اسکن نمایید</p>
                </div>
                <div class="QRcode">
                  <img src="@/assets/QRcode.png" alt="">
                </div>
                <div>
                  <p>پس از اسکن بارکد، مورد مربوط به آی تی  ارز در برنامه شما ایجاد خواهد شد. برای تایید صحت فرآیند، کدی که در اپ خود مشاهده می‌کنید را در کادر زیر وارد نمایید</p>
                </div>
                <div class="codeInput-container">
                    <CodeInput
                    :options="{
                      
                    }"/>
                </div>
              </slot>
              
            </section>
    
            <footer class="modal-footer">
              <!-- <slot name="footer">
                This is the default footer!
              </slot> -->
              <button
                type="button"
                class="btn-google-modal"
                @click="close"
                aria-label="Close modal"
              >
                تایید
              </button>
            </footer>
          </div>
        </div>
      </transition>
</template>

<script>
import CodeInput from "vue-verification-code-input";
  export default {
      components: {
        CodeInput},
    name: 'Modal',
    data() {
      return {

      }
    },
    methods: {
      closeModal() {
        this.$emit('close');
      },
    },
    created:function() {
      window.addEventListener("click",(event)=>{
            if(!event.target.closest('.modal , .security-btn')) {
              this.$emit('closeModaleEmit' , false)
            }
            else  {
              return true
            }
            
          } )
    }
  };
</script>

<style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
    
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 512px;
    min-height: 620px;
    padding: 10px 0;
  }

  .modal-header {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    /* border-bottom: 1px solid #eeeeee; */
    /* color: #4AAE9B; */
    display: flex;
    justify-content: center;
  }
  .modal-header  > p {
    font-weight: bold;
    font-size: 20px;
    margin: 0;
  }

  .modal-footer {
    /* border-top: 1px solid #eeeeee; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .modal-body {
    position: relative;
    padding: 10px 30px;
    display: flex;
    flex-direction: column;
    direction: rtl;
    column-gap: 20px;

  }
  
  .modal-body > slot > div > p {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
  }
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
    /* font-weight: bold; */
    /* color: #4AAE9B; */
    background: transparent;
  }
  .QRcode {
    display: flex;
    justify-content: center;
  }
  .btn-google-modal {
    color: white;
    background: #00ACC1;
    border-radius: 2px;
    border: 0;
    width: 146px;
    height: 40px;
    font-weight: bold;
font-size: 16px;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
</style>
<style >
.react-code-input {
  display: flex;
}
</style>
