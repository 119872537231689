<template>

    
            <div class="currency-list">
                <div class="currency-list-container">
                    <vue-trading-view
                        style="height: 550px"
                        :options="{
                            height:'100%',
                            autosize:true,
                                    
                                    timezone: 'Asia/Tehran',
                                    style: '1',
                                    
                                    locale: 'fa_IR',
                                    enable_publishing: true,
                                    withdateranges: true,
                                    range: 'ytd',
                                    hide_side_toolbar: false,
                                    allow_symbol_change: true,
                                    enabled_features: [
                                        'header_fullscreen_button'
                                        ],
                                    disabled_features: [
                                        'border_around_the_chart',
                                        'remove_library_container_border',
                                        ],
                                    loading_screen:{
                                        backgroundColor:'#00ff00',
                                        foregroundColor: '#000000',
                                        },
                                    overrides: {
                                        background: '#256'
                                    }
                        }"
                    />

                
                </div>
            </div>

<!-- </div> -->
</template>
<script>

import VueTradingView from 'vue-trading-view/src/vue-trading-view.vue'

export default {
  components: { VueTradingView },
  name: 'MainProfile',
  data : function() {
      return {
          showBuy:false,
          active:true,
          showSide:false,
          childBuy:false,
        //   currencyInformation:currency,
          currencyList: [
              {
                  name:"بیت کوین",
                  EnNmae:"BTN",
                  image:require('../../assets/bitcoin.png'),
                  price:2525,
                  percentChange:- 0.5,
                  amount:4654654654654,
                  p:'25%'
              },
              {
                  name:"بیت کوین",
                  EnNmae:"BTN",
                  image:"",
                  price:2525,
                  percentChange:+ 0.3,
                  amount:4654654654654,
              },
              {
                  name:"بیت کوین",
                  EnNmae:"BTN",
                  image:"",
                  price:2525,
                  percentChange:- 0.5,
                  amount:4654654654654,
              },
              {
                  name:"بیت کوین",
                  EnNmae:"BTN",
                  image:"",
                  price:2525,
                  percentChange:- 0.5,
                  amount:4654654654654,
              },
              {
                  name:"بیت کوین",
                  EnNmae:"BTN",
                  image:"",
                  price:2525,
                  percentChange:+ 0.3,
                  amount:4654654654654,
              },
              {
                  name:"بیت کوین",
                  EnNmae:"BTN",
                  image:"",
                  price:2525,
                  percentChange:+ 0.3,
                  amount:4654654654654,
              },
          ]
      }
      
  },
//   methods: {
//           showLimit () {
//               this.active=!this.active
//           }
//       },
//       showSide(){
//           this.showSide=!this.showSide
//       },
//       childShowBuy: function() {
//                  this.childBuy = true;
//                   },
        
}

</script>

<style scoped>
.mainProfile-container {
    width: 95%;
    display: flex;
    margin: auto;
    column-gap: 10px;
}
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;

    }
    .currency-information {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: 10px;
        flex-grow: 1;
        /* width: 98%; */
        /* flex-wrap: wrap; */
        width: 100%;
    }
    table  {
        width: 100%;
        border-collapse: collapse;
    }
    .currency-list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: top;
        flex-grow: 1;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        /* min-width: 650px; */
        /* min-width: 55%; */
        /* overflow-x: scroll; */
    }
    .currency-list-head-row {
        border-bottom: 1px solid black;
        font-weight: bold;
font-size: 14px;
    }
    .currency-list-container {
        width: 95%;
        margin: 0 auto;
        min-width: 400px;
    }
    .currency-list-row {
        /* display: flex; */
        flex-direction: row;
        flex-wrap: wrap;
        flex-grow: 1;
        justify-content: space-between;
        border-bottom: 1px solid gainsboro;
        align-items: center;
        padding: 5px;
        font-weight: bold;
font-size: 14px;
    }
    .currency-list-head-row , .currency-list-row , .currency-list-row > td {
        text-align: center;
    }
    .currency-list-row-image {
        display: flex;
        align-items: center;
    }
    .currency-list-row-image > img {
        width: 35px;
        height: 35px;
    }
    .currency-list-row-name > p {
        margin: 0;
    }
    .currency-list-row-title {
        display: flex;
        column-gap: 10px;
        /* justify-content: center; */
    }
    .currency-detail-title2 > div {
        /* width: 90px; */
height: 38px;
background: #058C2B;
border-radius: 44px;
color: white;
display: flex;
justify-content: center;
align-items: center;
font-weight: bold;
font-size: 16px;
width: 10vw;
    }
    .currency-list-head-row {
        /* display: flex; */
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba(15, 15, 15, 0.8);
    }
    .currency-detail {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;
        row-gap: 10px;
    }
    .currency-detail > div {
        filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.35));
        /* background: white; */
    }
    .currency-detail-title , .currency-detail-prices {
        background: white;
    }
    .detail-status {
        /* width: 245px; */
        height: 21px;
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        align-items: center;
    }
    .sell-status {
        direction: ltr;
    }
    .sell-status > div:first-of-type {
        /* padding: 0 15px; */
        margin-bottom: 10px;
    }
    .buy-status > div:first-of-type {
        /* padding: 0 10px; */
    }
    .detail-status > p {
        font-weight: bold;
        /* font-size: 14px; */
        font-size: 1vw;
        
    }
    .detail-status > p:first-of-type {
        padding: 0 10px;
    }
    .buy-status , .sell-status {
        /* min-width: 250px; */
        margin-bottom: 10px;
    }
    .status-title {
        font-weight: bold;
        /* font-size: 14px; */
        font-size: 1vw;
        margin: 0;
    }
    .status-title-total {
        padding: 0 10px;
    }
    .status-title+p {
        margin: 0;
        font-weight: normal;
        /* font-size: 12px; */
        font-size: 0.8vw;
    }
     .currency-list {
        height: 98%;
        border-radius: 2px;
        
    }
    .currency-detail {
        border-radius: 2px;
    }
    
    .currency-detail-title  {
        display: flex;
    justify-content: space-between;
    /* flex-grow: 1; */
    align-items: center;
    align-self: normal;
    padding: 0 15px;
    flex-wrap: wrap;
    min-height: 70px;
    gap: 10px;
    }
    .currency-detail-prices {
        display: flex;
        flex-direction: row;
        /* flex-wrap: wrap; */
    justify-content: space-between;
    /* flex-grow: 1; */
    align-items: center;
    align-self: stretch;
    align-self: normal;
    column-gap: 20px;
    row-gap: 20px;
    padding: 20px 0;
    }
     .currency-detail-prices > div {
         display: flex;
         flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    align-self: normal;
    row-gap: 5px;
     }
     .currency-detail-prices > div > div:first-of-type {
         display: flex;
         justify-content: space-between;
     }
     .currency-detail-prices > div > p {
         margin: 0;
     }
    .currency-detail-title1 {
        display: flex;
        align-items: center;
        column-gap:5px;
    }
    .currency-detail-title1 > div > p , .currency-detail-title3  > p:first-child {
        margin: 0;
        font-weight: bold;
        /* font-size: 18px; */
         font-size: 1vw;
    }
    .currency-detail-title1 > div > img {
        width: 3.5vw;
    }
    .currency-detail-title3 > p:nth-child(2) {
        font-weight: normal;
        /* font-size: 12px; */
        font-size: 0.9vw;
        margin: 0;
    }
    
    .currency-change-red {
        background: #E0342B;
        border-radius: 44px;
        width: 75px;
        height: 30px;
        color: white;
        font-weight: bold;
font-size: 14px;
display: flex;
justify-content: center;
align-items: center;
margin: auto;
    }
    .currency-change-green {
        background: #058C2B;
        border-radius: 44px;
        width: 75px;
        height: 30px;
        color: white;
        font-weight: bold;
font-size: 14px;
display: flex;
justify-content: center;
align-items: center;
margin: auto;
    }
    .currency-detail-inputs {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-self: normal;
    }
    .activeLimit {
        background: white;
        cursor: pointer;
        text-align: center;
        width: 80px;
    }
    .deActiveLimit {
        background: rgba(15, 15, 15, 0.1);
        cursor: pointer;
        text-align: center;
        width: 80px;
    }
    .activeLimit > p , .deActiveLimit > p {
        margin: 2px;
    }
    .currency-detail-inputs > div  {
        display: flex;
    }
    .currency-detail-inputs > div > div  {
        /* background: white; */
        /* width: 80px; */
        /* text-align: center; */
        font-weight: bold;
        font-size: 16px;
    }
    .currency-detail-inputs-deal {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        column-gap: 20px;
        padding: 0 20px 20px 20px;
        row-gap: 20px;
        background: white;
        flex-wrap: wrap;
    }
    .currency-detail-inputs-deal > div {
        display: flex;
        align-self: normal;
        flex-grow: 1;
        flex-direction: column;

    }
    .currency-detail-inputs-sell-limit > div > button{
        background: #E0342B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        width: 100%;
        height: 40px;
    }
    .currency-detail-inputs-sell-market > div > button  {
        background: #E0342B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        width: 100%;
        height: 40px;
    }
    .currency-detail-inputs-buy-limit > div > button , .currency-detail-inputs-buy-market > div > button{
        background: #058C2B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        width: 100%;
        height: 40px;
    }
    .currency-detail-inputs-sell-limit , .currency-detail-inputs-buy-limit , .currency-detail-inputs-sell-market , .currency-detail-inputs-buy-market {
        row-gap: 20px;
    }
    .currency-detail-inputs-sell-limit > div >label > input , .currency-detail-inputs-buy-limit > div >label > input , .currency-detail-inputs-sell-market > div >label > input , .currency-detail-inputs-buy-market > div >label > input{
        width: 100%;
        height: 35px;
        border-radius: 2px;
        
    }
    .currency-detail-inputs-buy-limit > div >label > input ,.currency-detail-inputs-buy-market > div >label > input {
        border: 1px solid rgba(5, 140, 43, 0.8);
    }
    .currency-detail-inputs-sell-limit > div >label > input , .currency-detail-inputs-sell-market > div >label > input {
        border:1px solid rgba(224, 52, 43, 0.8)
    }
    .currency-detail-inputs-sell-limit > div >label  , .currency-detail-inputs-buy-limit > div >label  , .currency-detail-inputs-sell-market > div >label  , .currency-detail-inputs-buy-market > div >label{
        position: relative;
    }
    .currency-detail-inputs-sell-limit > div >label >span  , .currency-detail-inputs-buy-limit > div >label > span , .currency-detail-inputs-sell-market > div >label >span  , .currency-detail-inputs-buy-market > div >label > span {
        position: absolute;
        top: -22px;
    right: 10px;
    background: white;
    padding: 0 5px;
    font-weight: bold;
font-size: 14px;
    }
    .max-price-buy > p , .min-price-sell >p , .cash-toman >p , .cash-bit>p {
        font-weight: normal;
        font-size: 12px;
        margin: 10px;
        display: inline-block;
        border-bottom: 1px solid black;
    }
    .line {
        max-width: 100%;
    }
    .red1 {
        background: linear-gradient(90deg , rgba(224, 52, 43, 0.3) 95% , white 95% ) ;
    }
    .red2 {
        background: linear-gradient(90deg , rgba(224, 52, 43, 0.3) 35% , white 35% ) ;
    }
    .red3 {
        background: linear-gradient(90deg , rgba(224, 52, 43, 0.3) 50% , white 50% ) ;
    }
    .red4 {
        background: linear-gradient(90deg , rgba(224, 52, 43, 0.3) 20% , white 20% ) ;
    }
    .red5 {
        background: linear-gradient(90deg , rgba(224, 52, 43, 0.3) 80% , white 80% ) ;
    }
    .green1 {
        background: linear-gradient(90deg  , white 20% , rgba(5, 140, 43, 0.3) 20% ) ;
    }
    .green2 {
        background: linear-gradient(90deg , white 35% , rgba(5, 140, 43, 0.3) 35%  ) ;
    }
    .green3 {
        background: linear-gradient(90deg  , white 50% , rgba(5, 140, 43, 0.3) 50%) ;
    }
    .green4 {
        background: linear-gradient(90deg  , white 20% , rgba(5, 140, 43, 0.3) 20%) ;
    }
    .green5 {
        background: linear-gradient(90deg  , white 5% , rgba(5, 140, 43, 0.3) 5%) ;
    }
    @media only screen and (max-width:1000px) {
        .currency-information {
            flex-wrap: wrap;
        }
    }
     @media only screen and (max-width:600px) {
        .currency-detail-title {
            column-gap: 50px;
        }
    }
</style>
<style >
   
</style>
