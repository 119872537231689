<template>
  <transition name="modal-fade">
        <div class="modal-backdrop">
          <div class="modal"
            role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription"
          >
            <header
              class="modal-header"
              id="modalTitle"
            >
              <!-- <slot name="header">
                <p>احراز هویت</p>
              </slot> -->
              <button
                type="button"
                class="btn-close"
                @click="close"
                aria-label="Close modal"
              >
              <img src="@/assets/closeModal.png" alt="">
              </button>
            </header>
    
            <section
              class="modal-body"
              id="modalDescription"
            >
              <slot name="body">
                  <div class="authen-warnning-img">
                      <img src="@/assets/ExitModal.png" alt="">
                  </div>
                  <div class="exit-message-modal">
                    <p>آیا از خروج خود اطمینان دارید؟</p>
                  </div>
                
              </slot>
              
            </section>
    
            <footer class="modal-footer">
              <!-- <slot name="footer">
                This is the default footer!
              </slot> -->
              <button
                type="button"
                class="cancel-exit-modal"
                @click="close"
                aria-label="Close modal"
              >
                 انصراف 
              </button>
              <router-link to="/login"><button
                type="button"
                class="confirm-exit-modal"
                
                aria-label="Close modal"
              >
                خروج
              </button>
              </router-link>
            </footer>
          </div>
        </div>
      </transition>
</template>

<script>

  export default {
    name: 'Modal',
    methods: {
      close() {
        this.$emit('closeModaleEmit');
      },



    },
    created:function() {
      window.addEventListener("click",(event)=>{
            if(!event.target.closest('.modal , .exit-btn')) {
              this.$emit('closeModaleEmit' , false)
              console.log('gggggggg')
            }
            else  {
              return true
            }
            
          } )
    }
  };
  
</script>

<style scoped>
  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 110;
    
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 520px;
min-height: 188px;
  }

  .modal-header {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    /* border-bottom: 1px solid #eeeeee; */
    /* color: #4AAE9B; */
    display: flex;
    justify-content: center;
  }

  .modal-footer {
    /* border-top: 1px solid #eeeeee; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    direction: rtl;
    column-gap: 30px;
  }

  .modal-body {
    position: relative;
    padding: 10px 30px;
    display: flex;
    flex-direction: row;
    direction: rtl;
    column-gap: 20px;

  }
  .authen-warnning-img {
      display: flex;
      align-items: center;
  }
  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 25px;
    padding: 10px;
    cursor: pointer;
    /* font-weight: bold; */
    /* color: #4AAE9B; */
    background: transparent;
  }

  
  .cancel-exit-modal , .confirm-exit-modal {
    border-radius: 2px;
    width: 146px;
    height: 40px;
    border: 0;
    font-weight: bold;
font-size: 16px;
  }
  .confirm-exit-modal {
    color: #00ACC1;
    border: 1px solid #00ACC1;
    box-sizing: border-box;
    background: transparent;
  }
  .cancel-exit-modal {
    background: #00ACC1;
    color: white;
  }
  .exit-message-modal > p {
    font-weight: 500;
    font-size: 24px;
  }
  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }
  @media only screen and (max-width: 600px) {
    .modal {

    width: 300px;
min-height: 200px;

  }
  .exit-message-modal > p {
    font-size: 20px;
  }
  .confirm-exit-modal , .cancel-exit-modal {
   width: 100px;
  }
   .authen-warnning-img > img {
     width: 40px;
   }
  }

</style>