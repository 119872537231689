<template>
<div>
    <div id="chaticon"><img src="@/assets/chaticon.png"></div>
    <div class="container-box">
        <div class="toman-teter">
            <div class="toman" v-on:click="toman" :class="{'active-toman':activeToman , 'deactive-toman':deactiveToman}">تومان</div>
            <div class="teter" v-on:click="teter" :class="{'active-teter':deactiveToman , 'deactive-teter':activeToman}">تتر</div>
        </div>
    <div id="mainbox">
                <table id="list-container">
                    <tr class="tr-heade">
                        <td >
                            <p id="col-heade">
                                نام ارز
                            </p>
                        </td>
                        <td >
                            <p id="col-heade">
                                آخرین قیمت({{price}})
                            </p>
                        </td>
                        <td >
                            <p id="col-heade">
                                درصد تغییرات
                            </p>
                        </td>
                        <td class="amount-td">
                            <p id="col-heade">
                                حجم شبانه روز گذشته
                            </p>
                        </td>
                        <td class="chart-td">
                            <p id="col-heade">
                                چارت
                            </p>
                        </td>
                        <td class="deal-td">
                            
                        </td>
                    </tr>
                    <tr id="row" v-for="(AllCrypto , index) in crypto" :key="index">
                        <td id="col">
                            <div id="image-col"><img :src=AllCrypto.image id="firstimage"/></div>
                            <div id="text-col"><p id="p-col">{{AllCrypto.name}}<br/><span class="ENname-box">BTN</span></p></div>
                        </td>
                        <td id="col" v-if="activeToman">
                            {{AllCrypto.toomanPrice.toLocaleString()}}
                        </td>
                        <td id="col" v-if="deactiveToman">
                            {{AllCrypto.teterPrice.toLocaleString()}}
                        </td>
                        <td id="col">
                            <div :class="{'change-positive' : AllCrypto.percentChange > 0 , 'change-negative' : AllCrypto.percentChange < 0}"
                                > %  {{ Math.abs(AllCrypto.percentChange) }} 
                                 <span class="change-sign" v-if=" AllCrypto.percentChange > 0"> + </span> 
                                 <span class="change-sign" v-if=" AllCrypto.percentChange < 0"> - </span> 
                                 </div>
                        </td>
                        <td id="col" class="amount-td">
                            <p id="p-col" >{{AllCrypto.amountDay.toLocaleString()}}</p>
                        </td>
                        <td id="col" class="chart-td">
                            <img src="@/assets/chart.png" id="chart"/>
                        </td>
                        <td id="col">
                            <DealButton/>
                        </td>
                    </tr>
                </table>
            </div>
            </div>
</div>
</template>

<script>
import DealButton from './DealButton.vue'
import AllCrypto from "../../library/AllCrypto.js"

export default {
  name: 'Box',
  components :{
    DealButton
     
      
  },
  data : function () {
      return {
          crypto:AllCrypto,
          activeToman:true,
          deactiveToman:false,
          price:"تومان",
        //   toman:[
        //       {
        //           price:22222
        //       }
        //   ]
          
      }
      
  },
  methods: {
      toman() {
          if(this.price==="تتر") {
              this.price="تومان"
              this.activeToman=true
              this.deactiveToman=false
          }
          
          else
          return true
      },
      teter () {
          if(this.price==="تومان") {
              this.price="تتر"
              this.activeToman=false
              this.deactiveToman=true
          }
          
          else
          return true
      }
  },
}
</script>

<style lang="scss" scoped>
#chaticon {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 1;
}
.container-box {
    width: 90%;
    margin: auto;
}
    .active-toman , .active-teter {
        background-color: #00ACC1;
        border-top-right-radius: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 70px;
    color: white;
    
    }
    .active-toman {
        margin: 7px 7px 0 0;
    }
    .active-teter {
        margin: 7px 0 0 7px;
    }
    .deactive-toman , .deactive-teter {
        background: white;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #mainbox {
    /* height: 798px; */
    background-color: white;
    /* margin: auto; */
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    /* margin: 50px; */
    direction: rtl;
    text-align: center;
    width:100%;
    /* filter: drop-shadow(2px 4px 6px black); */
    text-align: center;
    width: 100%;
    padding-top: 10px;
    /* border-top-right-radius: 50px; */
}
.toman-teter {
    width: 270px;
    height: 45px;
    display: flex;
    border-top-left-radius: 70px;
    border-top-right-radius: 70px;
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    background-color: white;
}
.toman , .teter {
    width: 50%;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}
#list-container {
    vertical-align: top;
    width: 97%;
    margin: 0 auto;
    border-collapse: collapse;
    table-layout: fixed;
    /* background-color: azure; */
}
/* #col , #text-col , #p-col ,#image-col {
    display: flex;
    align-items: center;
    justify-content: space-around;
} */
#row {
    width: 97%;
    height: 80px;
    margin: 0 auto;
    /* vertical-align: top; */
    border-bottom: 1px solid gainsboro;
}
.tr-heade {
    width: 97%;
    height: 40px;
    margin: 0 auto;
}
.tr-heade td {
    font-weight: bold;
    /* font-size: 16px; */
    font-size: clamp(12px , 1vw , 16px);
    color: rgba(15, 15, 15, 0.7);
}
.tr-heade td:first-child {
    text-align: right;
    padding-right: 20px;
}
.tr-heade p {
    margin: 0;
}
#row #col:first-child {
    /* display: flex;
    column-gap: 12px;
    margin-top: 12px; */
    column-gap: 10px;
    display: flex;
    height: 80px;
    text-align: right;
        div {
            display: flex;
            align-items: center;
        }
}
#col {
    /* width: 15%; */
    height: 100%;
    /* display: inline-block; */
    /* vertical-align: top !important; */
    max-width: 150px;
    font-weight: bold;
    font-size:clamp(12px , 1vw , 16px)
}
#col-head {
    font-weight: bold;
    color: rgba(15, 15, 15, 0.8);
}
#col-head:first-of-type {
    width: 100%;
    margin-right: 15px;
}
#text-col {
    height: 100%;
    /* width: 50%; */
}
#image-col {
    width: 40%;
    height: 100%;
}
#firstimage {
    width: 47px;
    height: 47px;
}
#p-col {
    font-weight: bold;
    display: inline-block;
    margin: 0;
    width: 100px;
    text-align: right;
}
.change-negative {
    background: #E0342B;
    color: white;
    width: 75px;
    border-radius: 44px;
    margin: auto;
    height: 40px;
    font-weight: bold;
/* font-size: 16px; */
font-size: clamp(12px , 1vw , 16px);
display: flex;
align-items: center;
justify-content: center;
}
.change-positive {
    background: #058C2B;
    color: white;
    width: 75px;
    border-radius: 44px;
    margin: auto;
    height: 40px;
    font-weight: bold;
/* font-size: 16px; */
font-size: clamp(12px , 1vw , 16px);
display: flex;
align-items: center;
justify-content: center;
}
.ENname-box {
    font-weight: 500;
/* font-size: 14px; */
font-size: clamp(12px , 1vw , 14px);
}
.change-sign {
    margin: 0 3px;
}
@media only screen and (max-width: 900px) {
    #row :nth-child(4), #row :nth-child(5) ,#row :nth-child(6) {
        display: none;
    }
    /* #image-col {
        display: none;
    } */
}
/* @media only screen and (max-width: 900px) {
    #list-container {
        width: 900px;
    }
    #mainbox {
        overflow: scroll;
    }
} */
@media only screen and (max-width: 600px) {
    #col-head {
        font-size: 12px;
    }
    #firstimage {
        width: 30px;
        height: 30px;
    }
    #p-col {
        font-size: 10px;
        width: auto;
    }
    #col {
        width: 100%;
    }
    .change-negative , .change-positive{
        font-size: 12px;
        height: 30px;
        width: 65px;
        font-weight: 500;
    }
    .ENname-box {
        font-size: 12px;
    }
    #row > #col:first-of-type {
        justify-content: right;
    }
    .toman {
        font-size: 12px;
    }
    #col-head {
        margin: 10px;
    }
    .chart-td , .amount-td , .deal-td {
        display: none;
    }
}
</style>
