<template >
<!-- <div>
    <ExitModal v-if="showModal" @close="showModal = false" /> -->


        <div id="porofile-navbar"  >
            <ExitModal v-if="showModal" @closeModaleEmit="showModal = false" />
        <div id="container" >
            <button class="ham"  @click.stop="toggleHamburger">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
            </button>
        <div id="buttons">
            <a href="/deposit/rial" @click.prevent="$router.push('/deposit/rial')">
                <button id="settle">
                واریز ریالی
                <img src="@/assets/settle.png" alt="">
                </button>
            </a>
            <a href="/withdrawal/rial" @click.prevent="$router.push('/withdrawal/rial')">
                <button id="remove">
                    برداشت ریالی
                    <img src="@/assets/remove.png" alt="">
                </button>
            </a>
        </div>
        <img id="logo-profile" src="@/assets/LOGOSITE1.png">
        <div id="name-bell">
            <router-link to="/notification" class="image-bell-link">
                <img src="@/assets/bell.png" alt="" class="image-bell">
                <span>5</span>
            </router-link>
            <router-link to="/authentication"><button id="authen-button"><div id="authen-button">احراز هویت</div></button></router-link>
            <button id="selector" v-on:click="dropdowm=!dropdowm">
            <div id="name">
                <img src="@/assets/person-icon.png" >
                <p class="name-nav">مرجان خلیل پور</p>
                <img src="@/assets/selector.png" alt="">
            </div>
            </button>
        </div>
    </div>
    <transition name="fade">
    <div v-if="dropdowm" class="profileItem">
        <!-- v-bind:class="{show: !isActive}" -->
        <router-link to="/authentication"><button id="authen-button-res"><div id="authen-button-res">احراز هویت</div></button></router-link>
        <router-link to="/account"><p id="profileItem-p"><img src="@/assets/person-icon.svg" alt=""><button id="profileItem-button">حساب کاربری</button></p></router-link>
        <router-link to="/security"><p id="profileItem-p"><img src="@/assets/security.png" alt="" srcset=""><button id="profileItem-button">امنیت</button></p></router-link>
        
        <p id="profileItem-p" class="exit-btn"  @click="toshowModal"><img src="@/assets/exit.png" alt="" srcset=""><button id="profileItem-button" >خروج</button></p>
        
    </div>
    </transition>


<div class="overlay" >
    <!-- <div v-bind:class="{showRes: !isActiveRes}" class="profileItemRes">
        <p class="nameProfile-responsive">مرجان خلیل پور</p>
        <router-link to="/account"><p id="profileItem-p"><img src="@/assets/person-icon.svg" alt=""><button id="profileItem-button">حساب کاربری</button></p></router-link>
        <router-link to="/security"><p id="profileItem-p"><img src="@/assets/security.png" alt="" srcset=""><button id="profileItem-button">امنیت</button></p></router-link>
        <p id="profileItem-p" @click.stop="toshowModal"><img src="@/assets/exit.png" alt="" srcset=""><button id="profileItem-button" v-click-outside.stop="onclickOutSide">خروج</button></p>
        
    </div> -->
</div>

<nav class="sideNav " >
    <perfect-scrollbar>
    <button class="menuX"  @click.stop="toggleHamburger">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
     
    <div class="side-responsive-container" >
        <div class="icons-res-container">
            <div class="withdraw-deposit">
                <a href="/notification" @click.prevent="$router.push('/notification')">
                    <button class="bell-button">
                        <img src="@/assets/bell.png" alt="" class="image-bell-responsive">
                    </button>
                    </a>
            </div>
        </div>
        <SidebarMobile  />
    </div>
    </perfect-scrollbar>
  </nav>



    </div>
    <!-- </div> -->
</template>

<script>
import ExitModal from "../Modals/ExitModal.vue";
// import Sidebar from './Sidebar.vue';
import SidebarMobile from './SidebarMobile.vue';
export default {
    components:{ExitModal, SidebarMobile },
  name: 'ProfileNavebar',
  data:function() {
    return {
      isActive:false,
      isActiveRes:false,
      activeHam:false,
          hideHam:true,
          showModal:false,
          dropdowm:false
    }
  },
     methods: {
    detailProfile: function() {
      this.isActive = !this.isActive;
    },
    detailProfileRes: function() {
      this.isActiveRes = !this.isActiveRes;
    },
    shownav () {
          if ( this.activeHam===false) {
              this.activeHam=true;
                this.hideHam=false;
          }
          else {
              this.activeHam=false;
                this.hideHam=true;
          }
          
      },
      toshowModal() {
          this.showModal=true;
      },
      toggleHamburger () {
        const sideNav = document.querySelector(".sideNav")
        const overlay = document.querySelector(".overlay")
        overlay.classList.toggle("showOverlay")
        sideNav.classList.toggle("showNav")
        this.isActiveRes=false
            },
},
created:function() {
      window.addEventListener("click",(event)=>{
            if(!event.target.closest('#selector')) {
               this.dropdowm = false;
               console.log('ok')
            }
            if(!event.target.closest('.side-responsive-container'))  {
              const sideNav = document.querySelector(".sideNav")
        const overlay = document.querySelector(".overlay")
        overlay.classList.remove("showOverlay")
        sideNav.classList.remove("showNav")
        this.isActiveRes=false
        console.log('hellow')
            }
            
          } )
    }
// watch:{
//     closeAll() {
       
// //     }
// }

}
</script>

<style scoped lang="scss">
#profileItem-button {
    background-color: white;
    border: 0;
}
.show {
    display: none;
}
.showRes {
    display: none;
}
.profileItem {
    position: absolute;
    top: 50px;
    left: 2%;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    z-index: 90;
    height: max-content;
    overflow: hidden;
}
.profileItemRes {
    position: absolute;
    top: 50px;
    right: 140px;
    background-color: white;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
    border-radius: 2px;
    z-index: 90;
}
#profileItem-p {
        border-bottom: 1px solid black;
        display: flex;
        justify-content: center;
        width: 150px;
        margin: 0;
        padding: 10px;
       justify-content: flex-start;
    }
#container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 10px;
    max-width: 1440px;
}
    #porofile-navbar {
        text-align: center;
        width: 100%;
        height: 50px;
        background: #FFFFFF;
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
    }
    #settle , #remove {
        width: 120px;
        height: 34px;
        border-radius: 79px;
        border: 0;
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-around;
        /* margin-left: 10px; */
        font-weight: bold;
    }
    #buttons {
        display: flex;
        align-items: center;
        column-gap: 15px;
    }
    #settle {
        background: #058C2B;
    }
    #remove {
        background: #E0342B;
    }
    #name-bell {
        display: flex;
        column-gap: 15px;
        align-items:center;
    }
    #name , #selector {
        width: 173px;
        height: 34px;
        background: #00ACC1;
        border-radius: 79px;
        display: flex;
        justify-items: center;
        color: white;
        font-size: 14px;
        align-items: center;
        justify-content: space-around;
        /* margin-right: 10px; */
        font-weight: bold;
    }
    #authen-button {
        width: 134px;
        height: 34px;
        background: #FF9800;
        border-radius: 25px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        font-weight: bold;
        font-size: 14px;
    }
    #authen-button-res {
        width: 100%;
        height: 34px;
        background: #FF9800;
        border-radius: 25px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        font-weight: bold;
        font-size: 14px;
    }
    #selector {
        background-color: #00ACC1;
        border: 0;
    }
    .image-bell{
        width: 27px;
        height: 34px;
    }
    .image-bell-link {
        position: relative;
    }
    .image-bell-link > span {
        position: absolute;
        background: red;
        border-radius: 50%;
        color: white;
        right: 0;
        font-size: 12px;
        font-weight: bold;
        min-width: 15px;
    }



.ham {
    display: none;
  position: relative;
  width: 3rem;
  height: 3rem;
  margin-left: 0.5rem;
  background: none;

  border: none;
  cursor: pointer;
  color: black;
 
}
.menuX {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
  margin-left: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: black;
}

.sideNav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: white;
  width: 140px;
  height: 100vh;
  /* overflow: hidden; */
  overflow: scroll;
  /* color: white; */
  /* hide the menu above the screen by default */
  transform: translateX(100%);
  transition: transform 0.4s ease;
  display: flex;
  justify-content: center;
  background: #efefef;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.sideNav::-webkit-scrollbar {
    display: none;
}
.showNav {
  transform: translateX(0);
}

.sideNav ul {
  width: 100%;
  height: 100%;
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: 4rem;
  padding-right: 0;
  row-gap: 9px;
}

.sideNav ul li a {
  display: block;
  color: black;
  /* padding-bottom: 10px; */
  /* margin-top: 1rem; */
  /* border-bottom: 2px solid black; */
  text-decoration: none;
  font-size: 1rem;
}

.sideNav ul li a:hover, .sideNav ul li a:focus {
  border-bottom: 2px solid white;
}

.overlay {
  position: fixed;
  display: none;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100vh;
}

.showOverlay {
  display: block;
}
.side-responsive-container {
    position: absolute;
    top: 50px;
}
.deposit-responsive{
    fill: black;
}
.withdraw-deposit {
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.image-bell-responsive {
    width: 25px;
    height: 25px;
}
.icons-res-container {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-bottom: 20px;
}
.bell-button {
     background: #efefef;
  border: none;
  border-radius: .5rem;
  outline: none;
  transition: .2s ease-in-out;
  box-shadow: -6px -6px 14px rgba(255, 255, 255, .7),
              -6px -6px 10px rgba(255, 255, 255, .5),
              6px 6px 8px rgba(255, 255, 255, .075),
              6px 6px 10px rgba(0, 0, 0, .15);
    width: 50px;
    height: 50px;
}
.nameProfile-responsive {
    color: white;
    background: #00ACC1;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    margin: 5px;
}
.fade-enter-active, .fade-leave-active {
  transition: height 0.7s ease-in;
  background: white;
  height: 150px;
}
  .fade-enter, .fade-leave-to {
  height: 0;
}
    @media only screen and (min-width: 900px) {
    #ul-div-ham , #button-div-ham {
        display: none;
    }
    .overlay , .sideNav {
        display: none;
    }
    #authen-button-res {
        display: none;
    }
    }
@media only screen and (max-width: 900px) {
    .ham-icon {
        display: inline-block;
    }
    /* #logo-profile ,#authen-button {
        display: none;
    } */
    #buttons , #authen-button ,.name-nav,  .image-bell-link{
        display: none;
    }
    #selector {
        width: 70px;
    }
     #ul-div , #button-div {
        display: none;
    }
    #navbar {
        height: auto;
    }
    /* #container {
        justify-content: center;
    } */
    .ham {
        display: inline-block;
    }
    #authen-button {
        width: 100px;
    }
    /* #name-bell {
        display: none;
    } */
}
@media only screen and (min-width: 900px) {
    .overlay , .sideNav {
        display: none;
    }
}
@media only screen and (max-width: 900px) {
    .ham {
        display: inline-block;
    }
    .profileItem {
        left: 10px;
    }
    // .profileItem {
    //     display: none;
    // }
    /* #name-bell {
        display: none;
    } */


}
</style>