<template>
    <button id="deal">معامله</button>
</template>

<script>
export default {
  name: 'DealButton'
}
</script>

<style scoped>
    #deal {
    background-color: #00ACC1;
    border-radius: 40px;
    color: white;
    width: 116px;
    height: 40px;
    border:0;
    font-family: shabnam;
}
</style>
