<template>
<div>
    <div id="profile-navbar">
        <ProfileNavbar/>
        <!-- <button class="menue" @click="showSidebar"></button> -->
        <!-- <Authentication/> -->
        <!-- <div class="mainProfile-container"> -->
            <div class="mainProfile-container">
        <Sidebar class="sidebar"/>
        <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
        <div class="depositBit-container">
            <div class="depositBit-title">
                <div class="depositBit-title-container">
                    <div class="name-logo-container">
                        <div>
                            <img src="@/assets/bitcoin.png" alt="">
                        </div>
                        <div>
                            <p >واریز بیت کوین</p>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="depositBit-net">
                    <div class="net-title">
                        شبکه :
                    </div>
                    <div class="depositBit-net-container">
                        <div class="depositBit-net-detail">
                            
                            <div class="depositBit-net-address">
                                <div>
                                    <div class="net-code">ERC-20</div>
                                    <p class="warning-text">لطفا توجه فرمایید که کیف پول مبدا، شبکه ی  انتخابی شما را پشتیبانی کند</p>
                                </div>
                                <div>
                                    <img src="@/assets/QRcode.png" alt="">
                                </div>
                                <div class="address-container">
                                    <input id="addressDeposit" type="text" :value="address">
                                    <button class="copy-address-depositBit" @click="copyToClip">کپی آدرس</button>
                                    <div class="copyAlert"  v-if="test">آدرس کپی شد</div>
                                </div>
                            </div>
                        </div>
                        <div class="depositBit-net-text">
                            <p>
                                آدرس کیف پول شما در کادر زیر قابل  مشاهده است برای واریز کردن ارزهای دیجیتال به این کیف میتوانید از این آدرس استفاده کنید. دقت داشته باشید باتوجه به وضعیت شبکه ، واریزی  شما با تاخیر تایید و ثبت می شود
                            </p>
                            <p>
                                دقت داشته باشید واریز هر نوع ارز دیگر به  این آدرس به جز اتریوم موجب از دست رفتن دارایی شما می شود . توجه کنید که از طریق قرارداد هوشمند (smart contract) ، اتریوم ارسال نکنید . در حال حاضر تنها اتریوم پشتیبانی می شود . از ارسال توکن به این آدرس خودداری کنید. 
                            </p>
                            <p>
                                توجه فرمایید در صورتی که از Binance برداشت می کنید حتما Network را ERC20 انتخاب کنید. در غیر این صورت دارایی  شما از دست خواهد رفت
                            </p>
                        </div>
                    </div>
            </div>
            <div class="depositBit-history">
                <div>
                     تاریخچه ی واریز بیت کوین
                </div>
                <div class="history-deposit">
                    <table class="history-deposit-table">
                        <thead>
                            <tr>
                                <td>نوع واریز</td>
                                <td>مقدار دزخواستی</td>
                                <td class="transaction-id">شناسه تراکنش</td>
                                <td class="request-date">تاریخ درخواست</td>
                                <td>وضعیت</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td> اتریوم</td>
                                <td class="deposit-amount">64,654,654,654</td>
                                <td class="transaction-id">o4544e44h</td>
                                <td class="request-date">xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
<!-- </div> -->
</div>
    </div>
    
</div>
</template>
<script>

// import Authentication from './Authentication.vue'
import ProfileNavbar from "./ProfileNavbar.vue"
import Sidebar from './Sidebar.vue'

export default {
  components: { ProfileNavbar, Sidebar },
  name: 'MainProfile',
  data : function() {
      return {
          showBuy:false,
          sidebarResponsive:false,
          test:null,
          address: '0x33a2270bab203640854c0f6d8ef82'
      }
  },
  methods:{
      showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
        copyToClip() {
            navigator.clipboard.writeText(this.address);
            this.test = true;
            setTimeout(() => {
                this.test = false;
            },1000)
            
            }
        }
  }

</script>

<style scoped>
.mainProfile-container {
    width: 95%;
    display: flex;
    margin: auto;
    /* column-gap: 10px; */
    height: calc(100vh - 70px);
}
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;
    }
    .depositBit-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding: 0 10px;
        height: 98%;
        /* overflow: scroll; */
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .depositBit-container::-webkit-scrollbar {
        display: none;
    }
    .depositBit-title , .depositBit-net , .depositBit-history {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        width: 100%;
        display: flex;
    }
    
    .net-title {
        width: 95%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 16px;
    }
    .depositBit-title {
        min-height: 70px;
        align-items: center;
        column-gap: 20px;
        font-weight: bold;
        font-size: 20px;
    }
    .depositBit-title-container {
        width: 95%;
        margin: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        column-gap: 20px;
        justify-content: space-between;
    }
    .depositBit-net {
        flex-direction: column;
        min-height: 473px;
        justify-content: space-around;
        flex-wrap: wrap;
    }
    .depositBit-net-container {
        display: flex;
        flex-wrap: wrap;
        width: 90%;
        margin: 10px auto;
        column-gap: 50px;
        margin-top: 0;
        flex-grow: 1;
    }
    .depositBit-net-detail {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        text-align: center;
        width: 36%;
    }
    .net-code {
        width: 107px;
        height: 35px;
        background: #00ACC1;
        border: 1.25px solid #141292;
        box-sizing: border-box;
        border-radius: 2px;
        color: white;
        align-items: center;
        display: flex;
        justify-content: center;
        font-weight: bold;
        font-size: 16px;
    }
    .copy-address-depositBit {
        background: #058C2B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        max-width: 400px;
        height: 40px;
        width: 100%;
    }
    .depositBit-net-text {
        font-weight: 600;
        font-size: 14px;
        width: 54%;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    .depositBit-history {
        min-height: 490px;
        flex-direction: column;
        row-gap: 20px;
    }
    .depositBit-history > div:first-of-type {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        padding: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    .history-deposit-table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
    }
    .history-deposit-table > thead > tr {
        border-bottom: 1px solid black;
        font-weight: bold;
font-size: 14px;
color: rgba(15, 15, 15, 0.6);
    }
    .history-deposit-table > tbody > tr {
        border-bottom: 1px solid gainsboro;
        font-weight: bold;
        font-size: 14px;
        height: 40px;
    }
    .deposit-amount {
        color: #058C2B;
    }
    #addressDeposit {
        display: flex;
        text-align: center;
        width: 100%;
        border: 0;
    }
    .address-container {
        position: relative;
    }
    .copyAlert {
        border: 2px solid #058C2B;
        background: #83e49f;
        width: 100%;
        text-align: center;
        width: 100px;
        position: absolute;
        top: -8px;
        right: 124px;
    }
    .name-logo-container {
        display: flex;
        align-items: center;
        column-gap: 20px;
        justify-content: center;
        flex-wrap: wrap;
    }
    .warning-text {
        font-weight: 500;
        font-size: 14px;
    }
    .depositBit-net-address {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: space-between;
    }
    @media only screen and (max-width:1000px) {
        .mainProfile-container {
            height: unset;
        }
    }
    @media screen and (max-width: 600px) {
        .transaction-id , .request-date {
            display: none;
        }
    }
</style>
