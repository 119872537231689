<template>
<!-- <div id="container"> -->
    <div id="Athutication">
        <ProfileNavbar/>
        <!-- <AuthenticationNavbar/> -->
        <!-- <button class="menue" @click="showSidebar">
             
        </button> -->
        <div id="authen-container">
            <Sidebar class="sidebar"/>
            <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/>
                <div id="authen-form1">
                    <div id="container" class="authen-container">
                <div id="line1-athuntication">
                    <p id="line1-athuntication-title">احراز هویت</p>
                </div>
                
                <div id="line2-athuntication">
                    <AuthenProcess3/>
                </div>
                <div id="line3-athuntication">
                    <div class="line3-athuntication-child">

                    
                    <div id="line3-title">
                        <p id="line3-title-p"> تایید هویت و ارسال عکس</p>
                    </div>
                    <perfect-scrollbar>
                    <div id="line3-upload">
                <div class="upload-text">
                    <p>
                        لطفا متن زیر را با دقت مطالعه کنید و در  صورت درستی اطلاعات تیک پذیرش اطلاعات را بزنید. */
                    </p>
                    <p>
                        اینجانب مرجان خلیل پور به کد مل  )۸۴۹۵۸۵۹۶۸۵۹( ضمن مطالعه و تایید قوانین استفاده از خدمات ویستا، متعهد می گردم که حساب کاربری و مدارک خود را به منظور خرید و فروش بیت کوین و سایر ارزهای دیجیتال در اختیار سایر افراد قرار ندهم و به درخواست شخص ثالث از جمله افراد و گروه های شبکه های اجتماعی و غیره اقدام به ایجاد حساب کاربری و خرید و فروش نکنم. در صورت تخلف، مسئولیت آن را اعم از مالی، قضایی و حقوقی به صورت کامل بر عهده می گیرم. جهت احراز هویت در سایت باینکس نام و نام خانوادگی امضا و تاریخ */
                    </p>
                </div>
                <div class="div-upload">
                    <div class="image-place"><img src="@/assets/upload-image.png"></div>
                    <picture-input @change="onChange()" accept="image/jpeg,image/png"></picture-input>
                    <input type="image" src="@/assets/upload-image.png" alt=""/>
                    <!-- <label for="file-upload" class="custom-file-upload">
                        <i class="fa fa-cloud-upload"></i> Custom Upload
                    </label> -->
                    <!-- <button class="upload-button"><input type="file" accept="image/*"></button> -->
                    <!-- <input type="file" accept="image/*"> -->
                    <label for="file-upload" class="custom-file-upload">
                        آپلود متن دست نویس
                    </label>
                    <input id="file-upload" type="file"/>
                </div>
                </div>
                    <!-- <label class="input">
                    <textarea rows="3" class="input__field address" type="text" />
                    <span class="input__label">  آدرس</span>
                    </label> -->
                <div id="line3-buttons">
                    <router-link to="/bank-info"><button id="pre">مرحله قبل </button></router-link>
                    <router-link to="/selfie"><button id="next">مرحله بعد</button></router-link>
                </div>
                </perfect-scrollbar>
                </div>
                </div>
                </div>
                </div>
        </div>
    </div>
    <!-- </div> -->
</template>

<script>
import AuthenProcess3 from './AuthenProcess3.vue'
import ProfileNavbar from './ProfileNavbar.vue'
// import AuthenticationNavbar from "./AuthenticationNavbar.vue"
import Sidebar from './Sidebar.vue'
export default {
    components:{  Sidebar, AuthenProcess3, ProfileNavbar },
  name: 'Authentication',
  data : function() {
      return {
          sidebarResponsive:false
      }
  },
  methods: {
      showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
  },
}
</script>

<style scoped>
input[type="file"] {
    display: none;
}
.custom-file-upload {
    width: 240px;
    /* width: 295px; */
    height: 45px;
    background-color: #00ACC1;
    color: white;
    /* padding: 0 70px; */
    border-radius: 20px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}
/* .upload-button {
    width: 200px;
    height: 45px;
    background-color: #00ACC1;
} */
#line3-upload {
    display: flex;
    /* flex-wrap: wrap; */
    column-gap: 50px;
    
}
#line3-upload , #line3-buttons {
    padding: 0 10px;
}
.upload-text {
    box-sizing: border-box;
    /* width: 60%; */
    max-width: 800px;
}
.div-upload {
    box-sizing: border-box;
    /* width: 50%; */
    /* max-width: 500px; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
}
    .authen-container{
        /* display: grid;
        justify-content: initial;
        height: 98%;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;*/
        padding: 0 10px; 
        display: flex;
        flex-direction: column;
        /* row-gap: 30px; */
    }
    .authen-container::-webkit-scrollbar {
        display: none;
    }
    #Athutication {
        /* display: grid; */
        justify-items: right;
    }
    #authen-form1 {
        display: grid;
        justify-items: center;
        width: 100%;
        height: 98%;
    }
    .input__field {
        /* width: 295px; */
        max-width: 295px;
        /* min-width: 150px;  */
    }
    #authen-container {
        display: flex;
        direction: rtl;
        padding: 0 10px;
    }
    #line2-athuntication {
        /* display: flex; */
        justify-items: right;
        align-items: center;
        height: 120px;
        /* margin-bottom: 40px; */
        /* height: -webkit-fill-available; */
    }
    #line1-athuntication-title {
        font-weight: bold;
        font-size: 18px;
        text-align: right;
        margin: 0;
    }
    #athutication-nav {
        /* width: 360px; */
        height: 50px;
        border-radius: 25px;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        position: relative;
    }
    .done {
        background: #00ACC1;
        color: white;
    }
    #line3-athuntication {
        /* width: 1273px; */
        /* height: 478px; */
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        /* display: grid; */
        /* padding: 0 20px 20px 20px; */
        /* height: 100%; */
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        height: 64vh;
        flex-grow: 1;
        /* overflow: scroll; */
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    #line3-athuntication::-webkit-scrollbar {
        display: none;
    }
    .line3-athuntication-child {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        height: 100%;
        overflow: scroll;
        width: 100%;
        padding: 0 20px 20px 20px;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .line3-athuntication-child::-webkit-scrollbar {
        display: none;
    }
    #line3-form {
        display: grid;
        justify-content: normal;
        row-gap: 20px;
    }
    #lin3-form-group {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 50px;
        align-items: center;
    }
    #line3-buttons {
        display: flex;
        flex-wrap: wrap-reverse;
        justify-content: space-between;
        align-items: center;
        row-gap: 10px;
        margin-top: 30px;
    }
    #line3-title {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    #line3-title-p {
        font-weight: bold;
        font-size: 18px;
        padding: 5px;
        text-align: center;
        margin: 5px;
    }
    #pre {
        border: 2px solid #00ACC1;
        box-sizing: border-box;
        border-radius: 35px;
        width: 172px;
        font-weight: bold;
        font-size: 16px;
        color: #00ACC1;
        background-color: white;
        height: 45px;
    }
    #next {
        width: 172px;
        background: #058C2B;
        border-radius: 35px;
        font-weight: bold;
        font-size: 16px;
        color: white;
        height: 45px;
        border: 0;
    }
    .address {
        width: 100%;
    }
    .input {
        display: flex;
        flex-grow: 1;
    }
    .input__label {
        font-weight: 600;
        font-size: 16px;
    }
    textarea {
        resize: none;
        /* height: 68px; */
    }
    #container {
        width: 100%;
        /* display: inline-block; */
    }
    #steps {
  font-size:0
}
.triangle {
  width: 0px; 
  height: 0px; 
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent; 
  
  border-right:50px solid #00ACC1;  
  display:inline-block;
  box-sizing: border-box;
  margin:0;
  margin-left:-49px;
 
}
.step1 {
  /* width:300px; */
  height:50px;
  background: #00ACC1;
  display:inline-block;
  border-top-right-radius:25px;
  border-bottom-right-radius:25px;
  vertical-align:top;
  /* color:white; */
  color: black;
  font-size:20px;
  display: inline-flex;
    justify-content: center;
    align-items: center;
}
.step2 {
  /* width:300px; */
  height:50px;
  background: #00ACC1;
  display:inline-block;
  vertical-align:top;
  /* color:white; */
  color: black;
  font-size:20px;
  display: inline-flex;
    justify-content: center;
    align-items: center;
}
.step4 {
  /* width:300px; */
  height:50px;
  background: #00ACC1;
  display:inline-block;
  border-top-left-radius:25px;
  border-bottom-left-radius:25px;
  vertical-align:top;
  /* color:white; */
  color:black;
  font-size:20px;
  display: inline-flex;
    justify-content: center;
    align-items: center;
}
.inner-triangle {
  width: 0px; 
  height: 0px; 
  border-top: 25px solid #00ACC1;
  border-bottom: 25px solid #00ACC1; 
  vertical-align: top;
  border-left:50px solid transparent;  
  transform: matrix(-1, 0, 0, 1, 0, 0);
  display:inline-block;
  box-sizing: border-box;
  margin:0;
}
.image-place {
    border: 1px dashed #00ACC1;
    box-sizing: border-box;
border-radius: 25px;
    padding: 20px;
    margin: 10px;
}
@media only screen and (max-width:1000px) {
    #line3-upload {
        flex-wrap: wrap;
    }
}

</style>
<style>

#line2-athuntication:first-child {
    min-width: 500px;
}
</style>