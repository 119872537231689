<template>
<div>
    <div id="profile-navbar">
        <ProfileNavbar/>
        <!-- <button class="menue" @click="showSidebar"></button> -->
        <!-- <Authentication/> -->
        <!-- <div class="mainProfile-container"> -->
            <div class="mainProfile-container">
        <Sidebar class="sidebar"/>
        <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
        <div class="withdrawalRial-container">
            <div class="withdrawalRial-title">
                <div class="withdrawalRial-title-container">
                    <div>
                        <img src="@/assets/rialWallet.png" alt="">
                        <div>
                            <p>برداشت ریالی</p>
                        </div>
                    </div>
                    
                    <div>
                        <div class="withdrawl-able">
                            قابل برداشت: 546,546,454 تومان
                        </div>
                    </div>
                </div>
            </div>
            <div class="withdrawalRial-form">
                <div class="withdrawalRial-form-container">
                    <div class="withdrawalRial-form-text">
                        <p>
                            جهت افزایش اعتبار کیف پول ریالی خود با  استفاده از کارت‌های بانکی عضو شبکه شتاب و از طریق درگاه پرداخت، حتما باید از کارت‌های بانکی به نام خودتان که در پروفایل‌تان ثبت و تایید شده  است، استفاده نمایید
                        </p>
                    </div>
                    <div class="withdrawalRial-form-inputs">
                        <label for="">
                            <input type="text">
                            <span> مبلغ برداشت <span class="unit">(تومان)</span></span>
                            <p>موجودی قابل برداشت شما: 0.07030108 ETH</p>
                            <p>یک میلیارد و سیصد میلیون تومان</p>
                        </label>
                        <label for="">
                            <select name="" id="">
                                <option value=""></option>
                            </select>
                            <span>انتخاب کارت</span>
                            <p>دقت کنید که وارد کردن آدرس اشتباه ممکن است باعث از دست رفتن دارایی شما شود.</p>
                        </label>
                    </div>
                    <div class="withdrawalRial-form-inputs">
                        <div >
                            <label for="" class="request-code">
                                <input type="text">
                                <span>کد تایید</span>
                                <button>درخواست کد</button>
                            </label>
                            
                        </div>
                        <div>
                            <label for="" class="security-code">
                                <input type="text" >
                                <span>کد امنیتی</span>
                                
                            </label>
                            <img src="@/assets/OIP1.png" alt="">
                        </div>
                        <button>
                            برداشت
                        </button>
                    </div>
                </div>
            </div>
            <!-- <perfect-scrollbar> -->
            <div class="withdrawalRial-history">
                <div>
                     تاریخچه ی برداشت ریالی
                </div>
                <perfect-scrollbar>
                <div class="history-deposit">
                    <table class="history-deposit-table">
                        <thead>
                            <tr>
                                <td class="type-pay">نوع پرداخت</td>
                                <td>مقدار برداشت (تومان)</td>
                                <td class="id-pay">شناسه پرداخت</td>
                                <td>تاریخ درخواست</td>
                                <td>وضعیت</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="type-pay">درگاه پرداخت</td>
                                <td class="withdrawal-amount">64654654654</td>
                                <td class="id-pay">o4544e44h</td>
                                <td>xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td class="type-pay">درگاه پرداخت</td>
                                <td class="withdrawal-amount">64654654654</td>
                                <td class="id-pay">o4544e44h</td>
                                <td>xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td class="type-pay">درگاه پرداخت</td>
                                <td class="withdrawal-amount">64654654654</td>
                                <td class="id-pay">o4544e44h</td>
                                <td>xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td class="type-pay">درگاه پرداخت</td>
                                <td class="withdrawal-amount">64654654654</td>
                                <td class="id-pay">o4544e44h</td>
                                <td>xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td class="type-pay">درگاه پرداخت</td>
                                <td class="withdrawal-amount">64654654654</td>
                                <td class="id-pay">o4544e44h</td>
                                <td>xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td class="type-pay">درگاه پرداخت</td>
                                <td class="withdrawal-amount">64654654654</td>
                                <td class="id-pay">o4544e44h</td>
                                <td>xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>
                            <tr>
                                <td class="type-pay">درگاه پرداخت</td>
                                <td class="withdrawal-amount">64654654654</td>
                                <td class="id-pay">o4544e44h</td>
                                <td>xxxx/xx/xx-xx:xx</td>
                                <td>
                                    <img src="@/assets/tick-green.png" alt="">
                                </td>
                            </tr>

                        </tbody>
                    </table>
                    
                </div>
                </perfect-scrollbar>
            </div>
            <!-- </perfect-scrollbar> -->
        </div>
<!-- </div> -->
</div>
    </div>
    
</div>
</template>
<script>

// import Authentication from './Authentication.vue'
import ProfileNavbar from "./ProfileNavbar.vue"
import Sidebar from './Sidebar.vue'

export default {
  components: { ProfileNavbar, Sidebar },
  name: 'MainProfile',
  data : function() {
      return {
          showBuy:false,
          sidebarResponsive:false
      }
  },
  methods : {
      showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
  }
}
</script>

<style scoped>
.mainProfile-container {
    width: 100%;
    display: flex;
    margin: 0 10px;
    /* column-gap: 10px; */
    height: calc(100vh - 70px);
    max-width: 1440px;
}
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;

    }
    .withdrawalRial-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding: 0 10px;
        height: 98%;
        /* overflow: scroll; */
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .withdrawalRial-container::-webkit-scrollbar {
        display: none;
    }
    .withdrawalRial-title , .withdrawalRial-form , .withdrawalRial-history {
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
        border-radius: 2px;
        width: 100%;
        display: flex;
    }
    .withdrawalRial-title {
        min-height: 70px;
        align-items: center;
        column-gap: 20px;
        font-weight: bold;
        font-size: 20px;
        justify-content: space-between;
    }
    .withdrawalRial-title-container {
        width: 95%;
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .withdrawalRial-title > div > div {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }
    .withdrawalRial-form {
        /* flex-direction: column; */
        min-height: 285px;
        /* justify-content: space-around; */
    }
    .withdrawalRial-form-container {
        width: 95%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 30px;
        margin: auto;
    }
    .withdrawalRial-form-text > p {
        font-weight: 600;
        font-size: clamp(12px , 2vw , 16px)
    }
    .withdrawalRial-form-inputs {
        display: flex;
        /* align-items: center; */
        /* flex-wrap: wrap; */
        column-gap: 50px;
        /* flex-wrap: wrap; */
        row-gap: 20px;
        /* align-items: center; */

    }
    .withdrawalRial-form-inputs > div {
        display: flex;
        align-items: center;
        /* flex-wrap: wrap; */
        min-width: 30%;
    }
    .withdrawalRial-form-inputs >label {
        min-width: 30%;
    }
    .withdrawalRial-form-inputs >label , .withdrawalRial-form-inputs > div >label {
        position: relative;
    }
    .withdrawalRial-form-inputs >label > input , .withdrawalRial-form-inputs > div >label > input {
        border: 1.5px solid #00ACC1;
        box-sizing: border-box;
        /* width: 321px; */
        height: 35px;
        width: 100%;
    }
    .withdrawalRial-form-inputs >label > select {
        border: 1.5px solid #00ACC1;
        box-sizing: border-box;
        border-radius: 2px;
        /* width: 321px; */
        height: 35px;
        width: 100%;
    }
    .withdrawalRial-form-inputs >label {
        /* width: 321px; */
    }
    .withdrawalRial-form-inputs >label > span , .withdrawalRial-form-inputs > div > label > span {
        font-weight: bold;
        font-size: 14px;
        position: absolute;
        right: 10px;
        top: -13px;
        background: white;
        padding: 0 5px;
    }
    .withdrawalRial-form-inputs > label > p {
        font-weight: normal;
        font-size: 12px;
        margin: 0;
    }
    .withdrawalRial-form-inputs > div > label > button {
        background: #058C2B;
        color: white;
        font-weight: bold;
        font-size: clamp(12px , 1vw , 16px);
        height: 35px;
        width: 150px;
    }
    .request-code , .security-code {
        display: flex;
        /* flex-wrap: wrap; */
        width: 100%;
    }
    .withdrawalRial-form-inputs > button {
        background: #E0342B;
        border-radius: 2px;
        color: white;
        font-weight: bold;
        font-size: 16px;
        width: 250px;
        height: 40px;
        margin: auto;
    }
    .withdrawalRial-history {
        /* min-height: 490px; */
        flex-direction: column;
        row-gap: 20px;
        /* height: 31vh; */
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
    .withdrawalRial-history::-webkit-scrollbar {
        display: none;
    }
    .withdrawalRial-history > div:first-of-type {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        padding: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    .history-deposit {

    }
    .history-deposit-table {
        width: 100%;
        border-collapse: collapse;
        text-align: center;
    }
    .history-deposit-table > thead > tr {
        border-bottom: 1px solid black;
        font-weight: bold;
font-size: 14px;
color: rgba(15, 15, 15, 0.6);
    }
    .withdrawl-able {
        font-size: clamp(14px , 2vw , 20px);
    }
    .history-deposit-table > tbody > tr {
        border-bottom: 1px solid gainsboro;
        font-weight: bold;
        font-size: 14px;
        height: 40px;
    }
    .withdrawal-amount {
        color: #E0342B;
    }
    .unit {
        font-weight: normal;
        font-size: 12px;
        color: #0F0F0F;
    }
    @media screen and (max-width:1000px) {
        .withdrawalRial-form-inputs {
            flex-wrap: wrap;
            margin-bottom: 10px;
        }
        .mainProfile-container {
            height: unset;
        }
    }
    @media screen and (max-width: 600px) {
        .type-pay , .id-pay {
            display: none;
        }
        .withdrawalRial-title-container {
            flex-direction: column;
            padding: 10px 0;
        }
        .withdrawalRial-title-container > div {
            justify-content: center;
        }
    }
</style>
