<template>
    <div class="head">
                <h2 class="head-titles">خرید و فروش ارز های دیجیتال</h2>
                <h4 class="head-titles">باینکس پلتفرم بر خط خرید و فروش ارزهای دیجیتال در ایران
                    <hr class="head-line">
                </h4>
                
                <div class="button-div">
                <router-link id="login-button" to="/login">
                    <button class="login" type="submit" >ورود</button>
                </router-link>
                <router-link id="login-button" to="/subscribe">
                    <button class="subscrib">ثبت نام</button>
                </router-link>
                
                
                </div>
                <h3 class="head-titles">ما مسئول معاملات شما هستیم</h3>
            </div>
</template>

<script>


export default {
  name: 'Head',
  components :{
    
  }
}
</script>

<style scoped>
.head {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    margin: auto;
}
.button-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 20px;
    row-gap: 20px;

}
.head-titles {
    text-align: center;
}
    h2 {
    padding: 20px;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    margin-bottom: 0;
}
h4 {
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: rgba(15, 15, 15, 0.8);

}
/* #underline {
    border-bottom: 2px solid gainsboro;
} */
h3 {
    font-weight: 600;
    font-size: 24px;
}
.head-line {
    width: 100%;
    height: 1.5px;
    background-image: linear-gradient(90deg ,rgba(15, 15, 15, 0.1) , rgba(15, 15, 15, 0.8) , rgba(15, 15, 15, 0.1));
}
.subscrib {
    width: 172px;
    height: 45px;
    background:  #058C2B;
    border-radius: 79px;
    border: 0;
    color: white;
    margin: 0 5px 0 0;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 35px;
  font-weight: bold;
  font-size: 16px;
}
.login {
        width: 172px;
        height: 45px;
        background: #00ACC1;
        border-radius: 79px;
        border: 0;
        color: white;
        /* margin: 0 5px 0 0; */
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
        border-radius: 35px;
        font-weight: bold;
        font-size: 16px;
    }
    @media only screen and (max-width: 600px) {
        h2.head-titles  {
            font-size: 22px ;
        }
        h4.head-titles {
            font-size: 16px;
        }
        .subscrib , .login {
            width: 140px;
        }
        h3 {
            font-size: 20px;
        }
    }
</style>
