var currency=[
    
    {
        "name":"بیت کوین",
        "ENname":"BTN",
        "priceChange":5,
        "percentChange":+0.05,
        "price":21321321,
        "image":require('../assets/bitcoin (2).png'),
        "incomePercent":25,
        "incomeamount":12345678,
        "buyAmount":6556565,
        "value":1212121,
        "amountDay":2132121231,
        "bitPrice":0.025
        
    },
    {
        "name":"اتریوم",
        "ENname":"ETH",
        "priceChange":5,
        "percentChange":-0.02,
        "price":21321321,
        "image":require('../assets/ethereum.png'),
        "incomePercent":-12,
        "incomeamount":12345678,
        "buyAmount":6556565,
        "value":1212121,
        "amountDay":2132121231,
        "bitPrice":0.012
    },
    {
        "name":"بایننس کوین",
        "ENname":"BNB",
        "priceChange":5,
        "percentChange":-0.03,
        "price":21321321,
        "image": require ('../assets/binance.png'),
        "incomePercent":-14,
        "incomeamount":12345678,
        "buyAmount":6556565,
        "value":1212121,
        "amountDay":2132121231,
        "bitPrice":0.045
    },
    {
        "name":" ریپل",
        "ENname":"XRP",
        "priceChange":5,
        "percentChange":0.06,
        "price":21321321,
        "image":require('../assets/xrp.png'),
        "incomePercent":33,
        "incomeamount":12345678,
        "buyAmount":6556565,
        "value":1212121,
        "amountDay":2132121231,
        "bitPrice":0.09
    },
    {
        "name":" لایت کوین",
        "ENname":"LTC",
        "priceChange":5,
        "percentChange":-0.01,
        "price":21321321,
        "image":require('../assets/light (2).png'),
        "incomePercent":10,
        "incomeamount":12345678,
        "buyAmount":6556565,
        "value":1212121,
        "amountDay":2132121231,
        "bitPrice":0.063
    },
    {
        "name":" تتر",
        "ENname":"USDT",
        "priceChange":5,
        "percentChange":0.03,
        "price":21321321,
        "image":require('../assets/teter.png'),
        "incomePercent":-12,
        "incomeamount":12345678,
        "buyAmount":6556565,
        "value":1212121,
        "amountDay":2132121231,
        "bitPrice":0.078
    },
    {
        "name":" بیت کوین کش",
        "ENname":"BTH",
        "priceChange":5,
        "percentChange":-0.08,
        "price":21321321,
        "image":require('../assets/bitCash.png'),
        "incomePercent":-7,
        "incomeamount":12345678,
        "buyAmount":6556565,
        "value":1212121,
        "amountDay":2132121231,
        "bitPrice":0.08
    }
]
// var totalIncome = currency.reduce(function(_this, val) {
//     return _this + val.incomeamount
// }, 0);

export default currency