<template>
    <div class="container">
        <!-- <h3 id="top-title">ویژگی های باینکس</h3> -->
        <div id="mainfeature">
            <p class="mainfeature-title">ویژگی های باینکس</p>
                    <div class="box-container">
                    <div id="box">
                    <img src="@/assets/Vector.png" id="box-image"/>
                    <div class="container-option">
                    <div id="text-container">
                        <p id="box-title">پورتفوی</p>
                        <p id="box-text">
                            <a href="#" id="box-a">بیشتربدانید</a>درباینکس می توانید درصد سود زیان خود را ازهر معامله به صورت ریالی و تتری مشاهده کنی</p>
                        
                        </div>
                    </div>
                </div>
                <div id="box">
                    <img src="@/assets/Vector.png" id="box-image"/>
                    <div class="container-option">
                    <div id="text-container">
                        <p id="box-title">پورتفوی</p>
                        <p id="box-text">
                            <a href="#" id="box-a">بیشتربدانید</a>
                            باینکس می توانید درصد سود زیان خود را ازهر معامله به صورت ریالی و تتری مشاهده کنی</p>
                        
                        </div>
                    </div>
                </div>
                </div>
                <div class="box-container">
                <div id="box">
                    <img src="@/assets/Vector.png" id="box-image"/>
                    <div class="container-option">
                    <div id="text-container">
                        <p id="box-title">پورتفوی</p>
                        <p id="box-text">
                            <a href="#" id="box-a">بیشتربدانید</a>
                            درباینکس می توانید درصد سود زیان خود را ازهر معامله به صورت ریالی و تتری مشاهده کنی</p>
                        
                        </div>
                    </div>
                </div>
                <div id="box">
                    <img src="@/assets/Vector.png" id="box-image"/>
                    <div class="container-option">
                    <div id="text-container">
                        <p id="box-title">پورتفوی</p>
                        <p id="box-text">
                            <a href="#" id="box-a">بیشتربدانید</a>
                            درباینکس می توانید درصد سود زیان خود را ازهر معامله به صورت ریالی و تتری مشاهده کنی</p>
                        
                        </div>
                    </div>
                </div>
                </div>
                <div class="box-container">
                <div id="box">
                    <img src="@/assets/Vector.png" id="box-image"/>
                    <div class="container-option">
                    <div id="text-container">
                        <p id="box-title">پورتفوی</p>
                        <p id="box-text">
                            <a href="#" id="box-a">بیشتربدانید</a>
                            درباینکس می توانید درصد سود زیان خود را ازهر معامله به صورت ریالی و تتری مشاهده کنی</p>
                        
                        </div>
                    </div>
                </div>
                <div id="box">
                    <img src="@/assets/Vector.png" id="box-image"/>
                    <div class="container-option">
                    <div id="text-container">
                        <p id="box-title">پورتفوی</p>
                        <p id="box-text">
                            <a href="#" id="box-a">بیشتربدانید</a>
                            درباینکس می توانید درصد سود زیان خود را ازهر معامله به صورت ریالی و تتری مشاهده کنی</p>
                        
                        </div>
                    </div>
                </div>
                </div>
                <div id="bottom-box">
                    <div id="bottom-box-img"><img src="@/assets/robot.png" id="robot-img"/></div>
                    <div class="container-option">
                    <div id="robot-info">
                        <p id="box-title">ربات تلگرامی</p>
                        <p id="box-text">
                            <a href="#" id="box-a-bottom">بیشتربدانید</a>
                            باینکس امکانی را برای مشتریان خود فراهم کرده کرده است که با استفاده از ربات تلگرامی همراه تریدر فیلتر کوین به اطلاعات چارت هر رمز ارزی دسترسی داشته باشید</p>
                        
                        </div>
                    </div>
                    <div id="bottom-box-img" class="secondRobot"><img src="@/assets/robot.png" id="robot-img"/></div>
                </div>
            </div>
    </div>
</template>

<script>
export default {
  name: 'Options'
}
</script>

<style scoped>
    #mainfeature {
    width: 100%;
    margin-top: 40px;
    direction: rtl;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 40px;
    column-gap: 40px;
}
.mainfeature-title {
    font-weight: bold;
font-size: 20px;
margin: 0;
}
#top-title {
    text-align: right;
}
#box {
    max-width: 615px;
    height: 200px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    /* display: inline-flex; */
    justify-content: space-around;
    /* margin: 20px; */
    vertical-align: top;
    text-align: right;
    display: flex;
    flex-basis: 500px;
    flex-shrink: 1;
    flex-grow: 1;
    /* flex-wrap: wrap; */
    /* max-width: 615px; */
    position: relative;
    background: white;
}
#bottom-box {
    /* width: 1282;
    height: 200px; */
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    display: flex;
    /* max-width: 1282px; */
    /* flex-wrap: wrap; */
    justify-content: center;
    position: relative;
    background: white;
    flex-grow: 1;
}
#box-image {
    width: 150px;
    height: 150px;
    margin: 20px;
    display: inline-block;
}
#box-title , #box-text {
    display: inline-block;
    vertical-align: top;
    font-weight: bold;
}
#box-title {
    font-size: 18px;
}
#box-text {
    /* font-size: 16px; */
    /* position: relative; */
    font-size: clamp(14px , 1vw , 16px);
}
#text-container {
    /* width: 60%;
    height: 100%; */
    display: inline-block;
    vertical-align: top;
    /* position: relative; */
    max-width: 375px;
    display: flex;
    flex-direction: column;
}
#box-a {
    /* position: absolute;
    left: 20px;
    bottom: 20px; */
    color: #00ACC1;
    font-weight: bold;
    text-align: left;
    padding: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
}
hr {
    opacity: 30%;
}
#bottom-box-img {
    display: inline-block;
    /* width: 20%;
    height: 100%; */
    box-sizing: border-box;
    margin-top: 20px;
}
#robot-info {
    /* display: inline-block; */
    box-sizing: border-box;
    /* width: 60%; */
    /* height: 100%; */
    text-align: right;
    vertical-align: top;
    /* padding-right: 50px; */
    /* position: relative; */
    display: flex;
    flex-direction: column;
    position: relative;
}
#box-a-bottom {
    /* position: absolute;
    left: 20px;
    bottom: 20px; */
    /* bottom: 10px;
    bottom: 10px; */
    color: #00ACC1;
    font-weight: bold;
    text-align: left;
    position: absolute;
    left: 0;
    bottom: 0;
}
.container-option {
    padding: 10px;
    display: flex;
    justify-content: center;
}
.box-container {
    display: flex;
    column-gap: 40px;
    row-gap: 40px;
    justify-content: space-between;
    width: 100%;
}
@media only screen and (max-width: 1200px) {
    #mainfeature {
        justify-content: center;
    }
}
@media only screen and (max-width: 1000px) {
    .secondRobot {
        display: none !important;
    }
    .box-container {
        flex-wrap: wrap;
        justify-content: center;
    }
}
@media only screen and (max-width: 700px) {
    #box-image {
        width: 70px;
        height: 70px;
    }
    #box-title {
        font-size: 16px;
    }
    #box-text {
        font-size: 12px;
    }
    #box-a {
        font-size: 12px;
    }
    #robot-img{
        width: 80px;
    }
    #box-a-bottom {
        font-size: 12px;
    }
}
/* @media only screen and (max-width: 1440px) {
    #mainfeature {
        display: grid;
    }
    #box {
        height: auto;
        width: auto;
    }
}
@media only screen and (max-width: 700px){
    #box {
        display: grid;
    }
    #box-image {
        display: flex;
    justify-self: center;
    }
    #text-container {
        justify-self: center;
    }
    #bottom-box {
        display: grid;
        height: auto;
    }
    #robot-img {
        justify-self: center;
    }
    #bottom-box-img {
        display: grid;
        width: auto;
    }
    #robot-info {
        width: auto;
    }
    #top-title {
        justify-self: center;
    }
    #robot-info {
        padding: 0;
    }
    #box-a-bottom ,#box-a {
        position: unset;
        display: flex;
        justify-content: center;
    }
} */

</style>
