<template >
    
  <div id="navbar"  class="navbar">
      <div class="container-navbar">
      <div id="container-nav" >
          
<!-- <div class="ham-icon" v-bind:class="{'hamicon-show':hideHam , 'hamicon-hide':activeHam}" ><button class="ham-button" v-on:click="shownav"></button></div> -->
    
    <button class="ham"  @click="toggleHamburger">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
      </svg>
    </button>
    
    <div class="image-ul">
                    <router-link to="/" id="logo-link"><img src="../assets/LOGOSITE1.png" id="nav-image"/></router-link>
                <div id="ul-div">
                    <ul id="navlist">
                        <li :class="{'active-li':$route.path=='/login'}"><router-link to="/login"><a href="#">بازار</a></router-link></li>
                        <li ><a href="#">آموزش</a></li>
                        <li :class="{'active-li':$route.path=='/FAQ'}"><router-link to="/FAQ"><a href="#">سوالات متداول</a></router-link></li>
                        <li :class="{'active-li':$route.path=='/terms'}"><router-link to="/terms"><a href=""> قوانین و مقررات</a></router-link></li>
                        <li :class="{'active-li':$route.path=='/fees'}"><router-link to="/fees"><a href="#">کارمزد</a></router-link></li>
                        <li :class="{'active-li':$route.path=='/contact'}"><router-link to="/contact"><a href="#">ارتباط با ما</a></router-link></li>
                        <li :class="{'active-li':$route.path=='/about'}"><router-link to="/about"><a href="#">درباره ی باینکس</a></router-link></li>
                        <li><a href="#">بلاگ</a></li>
                    </ul>
                </div>
    </div>
                <div id="button-div">
                    <router-link id="login-button" to="/login">
                        <button class="login" type="submit" >ورود</button>
                    </router-link>
                    <router-link id="subscrib-button" to="/subscribe">
                        <button class="subscrib">ثبت نام</button>
                    </router-link>
                </div>
                
                </div>
  </div>
<div class="overlay" ></div>
<nav class="sideNav" >
    <button class="menuX"  @click="toggleHamburger">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
      </svg>
    </button>
     
    <ul class="ul-test">

                    <router-link id="login-button" to="/login">
                        <button class="login-responsive" type="submit" >ورود</button>
                    </router-link>
                    <router-link id="subscrib-button" to="/subscribe">
                        <button class="subscrib-responsive">ثبت نام</button>
                    </router-link>
                    


                        <li><router-link to="/login"><a href="#">بازار</a>
                            <hr class="hr-nav-responsive">
                        </router-link></li>
                        
                        <li><a href="#">آموزش</a>
                            <hr class="hr-nav-responsive">
                        </li>
                        <li><router-link to="/FAQ"><a href="#">سوالات متداول</a>
                            <hr class="hr-nav-responsive">
                        </router-link></li>
                        <li><router-link to="/terms"><a href=""> قوانین و مقررات</a>
                            <hr class="hr-nav-responsive">
                        </router-link></li>
                        <li><router-link to="/fees"><a href="#">کارمزد</a>
                            <hr class="hr-nav-responsive">
                        </router-link></li>
                        <li><router-link to="/about"><a href="#">درباره ی باینکس</a>
                            <hr class="hr-nav-responsive">
                        </router-link></li>
                        <li><router-link to="/contact"><a href="#">ارتباط با ما</a>
                            <hr class="hr-nav-responsive">
                        </router-link></li>
                        <li><a href="#">بلاگ</a></li>
    </ul>
  </nav>


</div>
<!-- </div> -->
</template>

<script>
// import Vue2ClickOutside from 'vue2-click-outside'
export default {
  name: 'Navbar',
  components :{

  },
  data : function() {
      return {
          activeHam:false,
          hideHam:true
      }
  },
//   directives: {
//       clickOutside: Vue2ClickOutside.directive
//     },
  methods : {
      shownav () {
          const hmaButton=document.getElementsByClassName("ham-button")[0]
          if ( this.activeHam===false) {
              this.activeHam=true;
                this.hideHam=false;
                hmaButton.classList.toggle("showClose")
          }
          else {
              this.activeHam=false;
                this.hideHam=true;
                hmaButton.classList.remove("showClose")
          }
          
      },
    //   hidenav () {
    //       this.activeHam=false;
    //       this.hideHam=true;
    //   }
    toggleHamburger () {
        const sideNav = document.querySelector(".sideNav")
const overlay = document.querySelector(".overlay")
overlay.classList.toggle("showOverlay")
    // sideNav.style.display='block'
  sideNav.classList.toggle("showNav")
    },
//     onclickOutSide() {
//       const sideNav = document.querySelector(".sideNav")
//         const overlay = document.querySelector(".overlay")
//         overlay.classList.remove("showOverlay")
//         // sideNav.style.display='none'
//         sideNav.classList.remove("showNav")

//   }
    
  },
  created:function() {
      window.addEventListener("click",(event)=>{
            if(!event.target.closest('.ham , .ul-test')) {
              const sideNav = document.querySelector(".sideNav")
                const overlay = document.querySelector(".overlay")
                overlay.classList.remove("showOverlay")
                // sideNav.style.display='none'
                sideNav.classList.remove("showNav")
            }
            else  {
              return true
            }
            
          } )
    }
  
}
</script>

<style scoped >
.ham-icon , .ham {
    display: none;
}
.active-li {
    border-bottom: 1px solid black;
}
.showHamer {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    background: white;
    z-index: 1;
    top: 60px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
}
.hiddenHamber {
    display: none;
}
.container-navbar {
    max-width: 1440px;
    flex-grow: 1;
    position: relative;
    box-sizing: border-box;
    padding: 10px 20px;
}
.ham-button {
    position: absolute;
    top: 10px;
    height: 35px;
    width: 35px;
     border: none;
  cursor: pointer;
  background-color: transparent;
  background-image: url("../assets/ham.svg");
  background-size: 100%;
}
.showClose {
  background-image: url("../assets/closeHam.svg");
}
#navlist-ham {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
    row-gap: 10px;
}
#container-nav {
    height: 100%;
    width:100%;
    display: flex;
    justify-content:space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    /* justify-content: center; */
}
#logo-link {
    /* width: 300px; */
    display: flex;
    justify-content: center;
}
.image-ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}
#ul-div {
    display: inline-block;
    text-align: right;
}
#navbar {
    direction: rtl;
    height: 60px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    background-color: white !important;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
}
#navlist , li ,a {
    text-decoration: none;
    display: inline-block;
    /* margin: 10px; */
    color: #0F0F0F;
    font-family: shabnam;
    font-size: clamp(12px , 1.2vw , 16px);
    font-weight: 700;
    
}

#navlist {
    /* margin: 5px 0 0 0; */
    display: flex;
    column-gap: 30px;
    align-items: center;
    margin: 0;
    padding: 0 30px 0 10px;
}
#nav-image {
    width: 9;
    max-width: 116px;
}
#subscrb {
width: 130px;
height: 40px;
background: #00ACC1;
border-radius: 79px;
}
#subscrib-button , #login-button {
    margin: 0;
}
#button-div {
    display: flex;
    column-gap: 10px;
    align-items: center;
}
#button-div-ham {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    column-gap: 20px;
}
.subscrib {
    max-width: 130px;
    height: 40px;
    background:  #058C2B;
    border-radius: 79px;
    border: 0;
    color: white;
    margin: 0 5px 0 0;
    border-radius: 35px;
  font-weight: bold;
  /* font-size: 16px; */
  font-size: clamp(14px , 1vw , 16px);
  width: 9vw;
}
.login {
        max-width: 130px;
        height: 40px;
        background: #00ACC1;
        border-radius: 79px;
        border: 0;
        color: white;
        /* margin: 0 5px 0 0; */
        border-radius: 35px;
        font-weight: bold;
        /* font-size: 16px; */
        font-size: clamp(14px , 1vw , 16px);
        width: 9vw;
    }
    .subscrib-responsive {
    width: 100px;
    height: 40px;
    background:  #058C2B;
    border-radius: 79px;
    border: 0;
    color: white;
    margin-bottom: 5px;

    border-radius: 35px;
  font-weight: 700;
}
.login-responsive {
        width: 100px;
        height: 40px;
        background: #00ACC1;
        border-radius: 79px;
        border: 0;
        color: white;
        /* margin: 0 5px 0 0; */
        border-radius: 35px;
        font-weight: 700;
    }


.hr-nav-responsive {
    height: 1px;
    width: 150px;
}

.ham {
  position: relative;
  width: 3rem;
  height: 3rem;
  margin-left: 0.5rem;
  background: none;

  border: none;
  cursor: pointer;
  color: black;
 
}
.menuX {
  position: absolute;
  top: 0;
  right: 0;
  width: 3rem;
  height: 3rem;
  margin-left: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: black;
}

.sideNav {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: white;
  width: 200px;
  height: 100vh;
  /* overflow: hidden; */
  overflow: scroll;
  color: white;
  /* hide the menu above the screen by default */
  transform: translateX(100%);
  transition: transform 0.4s ease;
  /* display: none; */
}

.showNav {
  transform: translateX(0);
}

.sideNav ul {
  width: 100%;
  height: 100%;
  list-style: none;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding-top: 4rem;
  padding-right: 0;
  row-gap: 12px;
  
}

.sideNav ul li a {
  display: block;
  color: black;
  text-decoration: none;
  font-size: 1rem;
  display: flex;
    flex-direction: column;
    align-items: center;
}

.sideNav ul li a:hover, .sideNav ul li a:focus {
  border-bottom: 2px solid white;
}

.overlay {
  position: fixed;
  display: none;
  z-index: 100;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100vh;
}

.showOverlay {
  display: block;
}


@media only screen and (max-width: 1300px) and (min-width: 901px) {
    #container-nav {
        justify-content: center;
    }
    /* #navbar {
        box-sizing: border-box;
        height: auto;
        padding: 10px;
    } */
}
@media only screen and (min-width: 900px) {
    #ul-div-ham , #button-div-ham {
        display: none;
    }
    /* .sideNav {
        display: none;
    } */
}
@media only screen and (max-width: 900px) {
    .ham-icon , .ham {
        display: inline-block;
    }
     #ul-div , #button-div {
        display: none;
    }
    /* .sideNav {
        display: block;
    } */
}

</style>
