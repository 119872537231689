<template>
<div>
    <div id="profile-navbar">
        <ProfileNavbar/>
        <!-- <button class="menue" @click="showSidebar"></button> -->
        <!-- <Authentication/> -->
          <div class="notification-container">
            <Sidebar class="sidebar"/>
              <!-- <Sidebar class="sidebar-responsive" v-if="this.sidebarResponsive"/> -->
            <div class="security-container">
                  <div class="security-row" v-on:click="myFilter">
                      <div class="security-row-container" >
                        <p class="security-row-title"><img src="@/assets/dote.png" alt="">برداشت ریالی</p>
                        <p class="security-row-date">xx/xx/xxxx</p>
                        <!-- v-bind:class="{rotate: isActive}" -->
                        <button class="security-toggle-image"><img src="@/assets/toggle (2).png"   v-bind:class="{rotate: isActive}"></button>
                      </div>
                    <!-- <transition name="fade"> -->
                      <!-- v-bind:class="{active: !isActive}" -->
                    <div id="answer" class="" v-if="isActive" >
                      <p class="security-row-describe">بر اساس قوانین موجود در بازارهای مالی دنیا  و نیز حمایت از قوانین مرتبط با پولشویی، احراز هویت مشتریان به سبب جلوگیری از هرگونه سوء استفاده احتمالی الزامی است. احراز هویت به افرادی که در سایت والکس ثبتنام می‌کنند کمک می‌کند تا با امنیت بیشتر و خیال راحت تری به معامله با افراد داخل پلتفرم بپردازند. موارد محدودی در این زمینه برای شروع به کار با سایت والکس در نظر گرفته شده است</p>
                    </div>
                    <!-- </transition> -->
                  </div>
                  <!-- <div class="security-row" v-on:click="myFilter">
                      <div class="security-row-container" >
                        <p class="security-row-title"><img src="@/assets/dote.png" alt="">برداشت ریالی</p>
                        <p class="security-row-date">xx/xx/xxxx</p>
                        <button class="security-toggle-image"><img src="@/assets/toggle (2).png" ></button>
                      </div>
                     
                    <div id="answer" class="" v-bind:class="{active: !isActive}">
                      <p class="security-row-describe">بر اساس قوانین موجود در بازارهای مالی دنیا  و نیز حمایت از قوانین مرتبط با پولشویی، احراز هویت مشتریان به سبب جلوگیری از هرگونه سوء استفاده احتمالی الزامی است. احراز هویت به افرادی که در سایت والکس ثبتنام می‌کنند کمک می‌کند تا با امنیت بیشتر و خیال راحت تری به معامله با افراد داخل پلتفرم بپردازند. موارد محدودی در این زمینه برای شروع به کار با سایت والکس در نظر گرفته شده است</p>
                    </div>
                  </div> -->
            </div> 
          </div>
    </div>
</div>
</template>
<script>
// import Authentication from './Authentication.vue'
import ProfileNavbar from "./ProfileNavbar.vue"
import Sidebar from './Sidebar.vue'

export default {
  components: { ProfileNavbar, Sidebar },
  name: 'Security',
  data:function() {
    return {
      isActive:false,
      sidebarResponsive:false,
    }
  },
     methods: {
    myFilter() {
      this.isActive = !this.isActive;
    },
    // showNotif (event) {
    //     var target=event.target || event.srcElement;
    //     // var notifList=document.querySelectorAll(".security-row");
    //     // var item=document.getElementsByClassName("history-items-container")[target.id];
    //     // var items=document.querySelectorAll(".history-items-container-active");
    //     var items=document.querySelectorAll("#answer");
    //     console.log(items)
    //     items.forEach(function(button) {
    //         if (button === target && !button.classList.contains("active") ) 
    //             // return button.classList.add("active")
    //             button.classList.add("active")
    //             //  items.forEach(function(itemActive){itemActive.classList.remove("history-items-container-active")})
    //             //  item.classList.add("history-items-container-active")
    //         // return button.classList.remove("active")
    //     })
    //   },
     showSidebar () {
            this.sidebarResponsive=!this.sidebarResponsive
        },
}
}
</script>

<style>
.notification-container {
  width: 100%;
  margin: 0 10px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}
    #profile-navbar {
        direction: rtl;
        display: flex;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 10px;

    }
    .security-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        align-self: baseline;
        row-gap: 10px;
        font-weight: bold;
font-size: 16px;
    }
    .security-row-title {
        column-gap: 10px;
    }
    .security-row {
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.35);
      border-radius: 2px;
      display: grid;
      align-content: center;
      background-color: white;
    }
    .security-toggle-image {
      background-color: white;
      border: 0;
    }
    .security-row-date , .security-row-title ,  .security-toggle-image {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .security-row-container {
      display: flex;
      justify-content: space-between;
      padding: 10px;
    }
    .security-row-describe {
        max-width: 1300px;
        padding: 0 60px;
    }
    .fade-enter-active, .fade-leave-active {
      transition: height 0.5s ease-in;
    }
    .fade-enter-to, .fade-leave /* .fade-leave-active below version 2.1.8 */ {
      height: 0;
    }
</style>
